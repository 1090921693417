import React, { useState, useEffect } from 'react';
import { BsUpload } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { addService, getServicesMainCats, getSubCatsByMainCatId } from '../../../../services/Apis';
import img_icon from '../../../assets/images/img-icon.png';
import AccessChecker from '../../../components/OtherFunctions/AccessChecker';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AddService = () => {
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [mainCatId, setMainCatId] = useState("");
  const [subCatId, setSubCatId] = useState("");
  const [servicePrice, setServicePrice] = useState(0);
  const [description, setDescription] = useState("");
  const [featured, setFeatured] = useState("");
  const user_id = sessionStorage.getItem("admin_id");

  const [showImg, setShowImg] = useState(img_icon);
  const [serviceImg, setServiceImg] = useState("");

  const [mainCats, setMainCats] = useState([]);
  const [subCats, setSubCats] = useState([]);

  // Fetch main categories
  const fetchMainCategory = async () => {
    const response = await getServicesMainCats();
    if (response.status === 200) {
      setMainCats(response.data);
    } else {
      console.log("Error in Fetching Main Categories.");
    }
  };

  // Fetch subcategories based on the selected main category
  const fetchSubCategory = async (mainCategoryId) => {
    const response = await getSubCatsByMainCatId(mainCategoryId);
    if (response.status === 200) {
      setSubCats(response.data);
    } else {
      console.log("Error in Fetching Sub Categories.");
    }
  };

  useEffect(() => {
    fetchMainCategory();
  }, []);

  // Update subcategories when main category changes
  useEffect(() => {
    if (mainCatId) {
      fetchSubCategory(mainCatId);
      setSubCatId(""); // Reset subcategory selection on main category change
    }
  }, [mainCatId]);

  const selectImg = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (allowedFileTypes.includes(selectedFile.type)) {
        setShowImg(URL.createObjectURL(selectedFile));
        setServiceImg(selectedFile);
      } else {
        alert("Only png, jpg, jpeg Images are Allowed");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("service_img", serviceImg);
    formData.append("title", title);
    formData.append("main_cat_id", mainCatId);
    formData.append("sub_cat_id", subCatId);
    formData.append("featured", featured);
    formData.append("service_price", servicePrice);
    formData.append("description", description);
    formData.append("user_id", user_id);
    formData.append("status", "active");

    if (!serviceImg) {
      alert("Please Select Image");
    } else {
      const config = {
        "Content-Type": "multipart/form-data"
      };
      const response = await addService(formData, config);
      if (response.status === 200) {
        alert("Service Added Successfully");
        navigate("/admin/view-services");
      } else {
        alert("Sorry !! Service submission Unsuccessful");
      }
    }
  };

  return (
    <>
      <AccessChecker accessKey="add_post" />
      <div className='form-card add-service' id="add-post">
        <div className="row">
          <div className="col-md-12 form-head">
            <h1>Add Service</h1>
          </div>
        </div>
        <hr />
        <form className='form-div' onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="form-group">
            <div className="row">
              <div className="col-lg-6">
                <div className="img-upload-wrap">
                  <div className='upld-img-icon' style={{"height":"320px"}}>
                    <label htmlFor="imageInput" style={{ cursor: 'pointer' }}><img src={showImg} alt="Post Image" /></label>
                    <input type="file" id="imageInput" accept="image/*" name="serviceImg" style={{ display: 'none' }} onChange={selectImg} />
                  </div>
                  <label htmlFor="imageInput" style={{ cursor: 'pointer' }}><span className='upload-icon-btn'><BsUpload /></span></label>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="row d-grid h-100">
                  <div className="col-lg-12">
                    <label>Main Category</label>
                    <select onChange={(e) => setMainCatId(e.target.value)} className="form-control" required>
                      <option value="">Select Main Category</option>
                      {mainCats.map((cat, index) => (
                        <option key={index} value={cat._id}>{cat.main_cat_title}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-12">
                    <label>Sub Category</label>
                    <select onChange={(e) => setSubCatId(e.target.value)} className="form-control" required>
                      <option value="">{subCats.length>0? ('Select Sub Category'): ('Select Main Category First')}</option>
                      {subCats.map((cat, index) => (
                        <option key={index} value={cat._id}>{cat.sub_cat_title}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-12">
                    <label>Service Title</label>
                    <input type="text" className="form-control" placeholder="Enter Service Title" onChange={(e) => setTitle(e.target.value)} required />
                  </div>
                  <div className="col-lg-12">
                    <label>Service Charge (Starts From)</label>
                    <input type="number" className="form-control" placeholder="Enter Min Service Charge" onChange={(e) => setServicePrice(e.target.value)} required />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-lg-12">
                <label>Description</label>
                <ReactQuill theme="snow" value={description} onChange={setDescription} style={{ borderRadius: "5px 5px 0 0" }} />
              </div>
            </div>
          </div>
          
          <div className="form-group">
            <div className="row">
              <div className="col-lg-6">
                <label>Featured</label>
                <select className='form-control' onChange={(e) => setFeatured(e.target.value)}>
                  <option>--------- Select Option ---------</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
              <div className="col-lg-6">
                <div className='d-flex justify-content-end pt-2'>
                  <button type="submit" className="btn btn-primary submit-btn">Add Service</button>
                </div>
              </div>
            </div>
          </div>

        </form>
      </div>
    </>
  );
}

export default AddService;