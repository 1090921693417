import React, { useEffect, useState } from 'react';
import "./service.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getRecommendedServices, getUserLikedServices, serviceLikeUnlike } from '../../../services/Apis';
import { BASE_URL } from '../../../services/helper';
import { Link } from "react-router-dom";
import { NextArrow, PreviousArrow } from '../../components/functions/OtherFunctions';

const RecommendedService = () => {
  const settings = {
    dots: false, // Shows dots at the bottom of the slider
    infinite: false, // Disables infinite looping of the slides
    speed: 500, // Speed of the transition (in ms)
    slidesToShow: 4, // Number of slides visible at a time
    slidesToScroll: 1, // Number of slides to scroll at a time
    initialSlide: 0, // Start from the first slide
    prevArrow: <PreviousArrow />, // Custom previous arrow
    nextArrow: <NextArrow />, // Custom next arrow
    responsive: [
      {
        breakpoint: 768, // Below this width, show fewer slides
        settings: {
          slidesToShow: 1, // On mobile, show 1 slide
        },
      },
    ],
  };

  const [loadingService, setLoadingService] = useState(true);
  const [servicesData, setServicesData] = useState([]);

  const fetchRecommendedServices = async () => {
    try {
      const res = await getRecommendedServices("featured=true");
      if (res.status === 200) {
        setServicesData(res.data);
      } else {
        console.log('Error in fetching Services.');
      }
    } catch (error) {
      console.error('Error fetching Services:', error);
    } finally {
      setLoadingService(false);
    }
  };

  useEffect(() => {
    fetchRecommendedServices();
  }, []);

  //<----------------------------------------------- Fetch Services Liked by user ----------------------------------------------->
  const username = sessionStorage.getItem("user_username") || "";

  const [serviceLiked, setServiceLiked] = useState([]);
  const getLikedServices = async () => {
    try {
      const res = await getUserLikedServices(username);
      if (res.status === 200) {
        setServiceLiked(res.data.likedServices);
      } else {
        console.log("Error in Fetching Liked Services.");
      }
    } catch (error) {
      console.error("Error fetching liked Services:", error);
    }
  };

  useEffect(() => {
    if (username) {
      getLikedServices();
    }
  }, [username]);

  //<----------------------------------------------- Handle Like Unlike ----------------------------------------------->
  const handleLike = async (service_id, service_by) => {
    if (username) {
      const data = { service_id, liked_by: username, service_by };
      try {
        const res = await serviceLikeUnlike(data);
        if (res.status === 200) {
          getLikedServices();
        }
      } catch (error) {
        console.error("Error:", error.response.data);
      }
    } else {
      alert("Please Login");
    }
  };

  return (
    <div id='recommanded_service'>
      <h3 className="service_heading view_category_recommandedheading">Recommended for you</h3>
      {loadingService ? (<p>Loading Services....</p>) : (
        <>
          {servicesData.length > 0 ? (
            <div className="horizontal-slider">
              <Slider {...settings}>
                {servicesData.map((data) => (
                  <Link to={`/view-service/${data.userInfo.username}/${data._id}`} key={data._id}>
                    <div className="top_rate_slider">
                      <img
                        src={`${BASE_URL}/uploads/services-img/${data.service_img}`}
                        alt=""
                        className="service_img"
                      />
                      <div className="user_contact">
                        <img
                          src={`${BASE_URL}/uploads/admins-users-img/${data.userInfo.user_img}`}
                          alt=""
                        />
                        <h2>{data.userInfo.fname + " " + data.userInfo.lname}</h2>
                      </div>
                      <p>{data.title.substring(0, 85) + "..."}</p>
                      <div className="price_like">
                        <h2>From ₹{data.service_price}</h2>
                        <div
                          className="like-div"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleLike(data._id, data.userInfo.username);
                          }}
                        >
                          {serviceLiked.length > 0 ? (
                            serviceLiked.some(
                              (liked) => liked.service_id === data._id
                            ) ? (
                              <svg width="20" height="20" viewBox="0 0 20 20" fill="red" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10" cy="10" r="10" fill="#F9F9F9" />
                                <path d="M12.8934 12.7871C11.8224 13.8102 10.729 14.5024 10.4417 14.6767C10.1544 14.5024 9.06099 13.8102 7.99007 12.7871C6.76849 11.62 5.68423 10.1248 5.68411 8.54785C5.68492 7.82452 5.94906 7.13872 6.40703 6.63912C6.86369 6.14095 7.47323 5.86975 8.09901 5.8689C8.90537 5.869 9.60116 6.24374 10.0375 6.87764L10.4417 7.465L10.846 6.87764C11.2823 6.24374 11.978 5.869 12.7844 5.8689C13.4102 5.86975 14.0197 6.14095 14.4764 6.63912C14.9344 7.13878 15.1986 7.82468 15.1993 8.54811C15.1991 10.1249 14.1149 11.6201 12.8934 12.7871Z" stroke="red" stroke-width="0.98151" />
                              </svg>
                            ) : (
                              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10" cy="10" r="10" fill="#F9F9F9" />
                                <path d="M12.8934 12.7871C11.8224 13.8102 10.729 14.5024 10.4417 14.6767C10.1544 14.5024 9.06099 13.8102 7.99007 12.7871C6.76849 11.62 5.68423 10.1248 5.68411 8.54785C5.68492 7.82452 5.94906 7.13872 6.40703 6.63912C6.86369 6.14095 7.47323 5.86975 8.09901 5.8689C8.90537 5.869 9.60116 6.24374 10.0375 6.87764L10.4417 7.465L10.846 6.87764C11.2823 6.24374 11.978 5.869 12.7844 5.8689C13.4102 5.86975 14.0197 6.14095 14.4764 6.63912C14.9344 7.13878 15.1986 7.82468 15.1993 8.54811C15.1991 10.1249 14.1149 11.6201 12.8934 12.7871Z" stroke="#272D3B" stroke-width="0.98151" />
                              </svg>
                            )
                          ) : (
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="10" cy="10" r="10" fill="#F9F9F9" />
                              <path d="M12.8934 12.7871C11.8224 13.8102 10.729 14.5024 10.4417 14.6767C10.1544 14.5024 9.06099 13.8102 7.99007 12.7871C6.76849 11.62 5.68423 10.1248 5.68411 8.54785C5.68492 7.82452 5.94906 7.13872 6.40703 6.63912C6.86369 6.14095 7.47323 5.86975 8.09901 5.8689C8.90537 5.869 9.60116 6.24374 10.0375 6.87764L10.4417 7.465L10.846 6.87764C11.2823 6.24374 11.978 5.869 12.7844 5.8689C13.4102 5.86975 14.0197 6.14095 14.4764 6.63912C14.9344 7.13878 15.1986 7.82468 15.1993 8.54811C15.1991 10.1249 14.1149 11.6201 12.8934 12.7871Z" stroke="#272D3B" stroke-width="0.98151" />
                            </svg>
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </Slider>
            </div>
          ) : (
            <p>No Services to Show</p>
          )}
        </>
      )}
    </div>
  );
};

export default RecommendedService;
