import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "../profile.css";
import { getSingleUser, userSocial } from "../../../../services/Apis";
import { isTokenValid } from "../../../components/functions/OtherFunctions";
import { ToastContainer, toast } from "react-toastify";

const ProfileAbout = ({ username }) => {
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        setIsValid(valid);
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();
  }, []);

  const logged_username = sessionStorage.getItem("user_username");

  const [socialLinks, setSocialLinks] = useState({});
  const [nullSocialLinks, setNullSocialLinks] = useState(false);

  const getUserSocialLinks = async () => {
    try {
      const res = await userSocial(username);
      if (res.status === 200) {
        if (res.data === null) {
          setNullSocialLinks(true);
          setSocialLinks({
            facebook: "",
            instagram: "",
            twitter: "",
            linkedin: "",
            github: "",
            youtube: "",
          });
        } else {
          setSocialLinks(res.data);
        }
      } else {
        console.log("Error in Fetching Posts.");
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  const [userData, setUserdata] = useState({});
  const getUserInfo = async () => {
    try {
      const res = await getSingleUser(username);
      if (res.status === 200) {
        setUserdata(res.data);
      } else {
        console.log("Error in Fetching Posts.");
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  useEffect(() => {
    getUserInfo();
    getUserSocialLinks();
  }, []);

  const noLink = () => {
    if (isValid && username === logged_username) {
      // alert("No Link was added go to edit profile to add links");
      toast.error("No Link was added go to edit profile to add links");
    } else {
      // alert("No Link was added by the User");
      toast.error("No Link was added by the User");
    }
  };

  const formatDate = (dateStr) => {
    const dateObj = new Date(dateStr);

    const options = { year: "numeric", month: "long" };
    const formattedDate = dateObj.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  return (
    <>
      <div className="col-12" id="profile-about">
        <div className="about_flex ">
          <div className="  about-col-1">
            <div>
              <h2 className="">Biography</h2>
              {userData.bio ? (
                <p className="content">{userData.bio}</p>
              ) : (
                <p className="content">------ No Bio Added ------</p>
              )}
            </div>

            <div>
              <h2 className="">Skills</h2>
              {userData.skills ? (
                <p className="content">{userData.skills}</p>
              ) : (
                <p className="content">------ No Skills Added ------</p>
              )}{" "}
            </div>
            <div></div>

            {/* <div className="mt-1">
          <div className='d-flex justify-content-between'><h5 className='content-head'>Intro</h5>{isValid && (username === logged_username)?( <NavLink className="edit-btn" to="/edit-profile"><i class="fa-regular fa-pen-to-square"></i> Edit</NavLink>):('')}</div>
            {userData.intro?(<p className='content'>{userData.intro}</p>):(<p className='content'>------ No Intro Added ------</p>)}
          </div>
          <div className="mt-3">
            <div className='d-flex justify-content-between'><h5 className='content-head'>Bio</h5></div>
            {userData.bio?(<p className='content'>{userData.bio}</p>):(<p className='content'>------ No Bio Added ------</p>)}
          </div>
          <div className="mt-3">
            <div className='d-flex justify-content-between'><h5 className='content-head'>Skills</h5></div>
            {userData.skills?(<p className='content'>{userData.skills}</p>):(<p className='content'>------ No Skills Added ------</p>)}
          </div> */}
          </div>
          <div className=" about-col-2" style={{ marginLeft: "15px" }}>
            <div className="row membership">
              <div className="col-12">
                <p style={{ marginBottom: "5px" }} >
                  <svg
                    width="18"
                    height="22"
                    viewBox="0 0 18 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.99972 0.928574C11.2162 0.928574 13.3418 1.80906 14.9091 3.37632C16.4764 4.94359 17.3569 7.06926 17.3569 9.28572C17.3569 12.8171 14.8754 16.6657 9.97686 20.8726C9.70448 21.1066 9.3572 21.2351 8.99812 21.2348C8.63903 21.2344 8.29198 21.1053 8.02001 20.8709L7.69601 20.5897C3.01429 16.4926 0.642578 12.7383 0.642578 9.28572C0.642578 7.06926 1.52306 4.94359 3.09033 3.37632C4.6576 1.80906 6.78327 0.928574 8.99972 0.928574ZM8.99972 2.21429C7.12426 2.21429 5.32562 2.95931 3.99947 4.28546C2.67332 5.61161 1.92829 7.41026 1.92829 9.28572C1.92829 12.2874 4.11229 15.746 8.54029 19.6203L8.86001 19.8971C8.89888 19.9306 8.94845 19.949 8.99972 19.949C9.05099 19.949 9.10056 19.9306 9.13944 19.8971C13.7817 15.9097 16.0712 12.3586 16.0712 9.28572C16.0712 8.35708 15.8882 7.43754 15.5329 6.5796C15.1775 5.72165 14.6566 4.9421 14 4.28546C13.3433 3.62882 12.5638 3.10794 11.7058 2.75257C10.8479 2.3972 9.92836 2.21429 8.99972 2.21429ZM8.99972 6.07143C9.8522 6.07143 10.6698 6.41008 11.2726 7.01287C11.8754 7.61567 12.214 8.43323 12.214 9.28572C12.214 10.1382 11.8754 10.9558 11.2726 11.5586C10.6698 12.1614 9.8522 12.5 8.99972 12.5C8.14724 12.5 7.32967 12.1614 6.72688 11.5586C6.12408 10.9558 5.78544 10.1382 5.78544 9.28572C5.78544 8.43323 6.12408 7.61567 6.72688 7.01287C7.32967 6.41008 8.14724 6.07143 8.99972 6.07143ZM8.99972 7.35714C8.48823 7.35714 7.99769 7.56033 7.63602 7.92201C7.27434 8.28369 7.07115 8.77423 7.07115 9.28572C7.07115 9.7972 7.27434 10.2877 7.63602 10.6494C7.99769 11.0111 8.48823 11.2143 8.99972 11.2143C9.51121 11.2143 10.0017 11.0111 10.3634 10.6494C10.7251 10.2877 10.9283 9.7972 10.9283 9.28572C10.9283 8.77423 10.7251 8.28369 10.3634 7.92201C10.0017 7.56033 9.51121 7.35714 8.99972 7.35714Z"
                      fill="#545454"
                      fill-opacity="0.5"
                    />
                  </svg>
                  &nbsp;{" "}
                  {userData.location
                    ? userData.location
                    : "---- No Location Added ----"}
                </p>
              </div>
              <div className="col-12">
                <p style={{ marginTop: "5px" }}>
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_99_434)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.50411 6.69955C7.50411 5.84667 7.84292 5.02872 8.44599 4.42565C9.04907 3.82257 9.86702 3.48376 10.7199 3.48376C11.5728 3.48376 12.3907 3.82257 12.9938 4.42565C13.5969 5.02872 13.9357 5.84667 13.9357 6.69955C13.9357 7.55243 13.5969 8.37038 12.9938 8.97346C12.3907 9.57654 11.5728 9.91534 10.7199 9.91534C9.86702 9.91534 9.04907 9.57654 8.44599 8.97346C7.84292 8.37038 7.50411 7.55243 7.50411 6.69955ZM7.50411 11.5232C6.43801 11.5232 5.41558 11.9467 4.66173 12.7006C3.90788 13.4544 3.48438 14.4769 3.48438 15.543C3.48438 16.1826 3.73848 16.7961 4.19079 17.2484C4.6431 17.7007 5.25656 17.9548 5.89622 17.9548H15.5436C16.1832 17.9548 16.7967 17.7007 17.249 17.2484C17.7013 16.7961 17.9554 16.1826 17.9554 15.543C17.9554 14.4769 17.5319 13.4544 16.7781 12.7006C16.0242 11.9467 15.0018 11.5232 13.9357 11.5232H7.50411Z"
                        fill="#545454"
                        fill-opacity="0.5"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_99_434">
                        <rect width="20.3667" height="20.3667" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  &nbsp; Member Since {formatDate(userData.reg_date)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr class="mt-4 mb-4"></hr>
        <div className="social_section">
          <h2 style={{ color: "black", fontSize:"18px" }}>Your Social Links</h2>
          {nullSocialLinks ? (
            <>
              <div className="d-flex">
                <div className="social-icon">
                  <Link to="" onClick={noLink}>
                    <i
                      class="fa-brands fa-facebook"
                      style={socialLinks.facebook ? { color: "#4267B2" } : {}}
                    ></i>
                  </Link>
                </div>
                <div className="social-icon">
                  <Link to="" onClick={noLink}>
                    <i
                      class="fa-brands fa-instagram"
                      style={socialLinks.instagram ? { color: "#e95950" } : {}}
                    ></i>
                  </Link>
                </div>
                <div className="social-icon">
                  <Link to="" onClick={noLink}>
                    <i
                      class="fa-brands fa-twitter"
                      style={socialLinks.twitter ? { color: "#1DA1F2" } : {}}
                    ></i>
                  </Link>
                </div>
                <div className="social-icon">
                  <Link to="" onClick={noLink}>
                    <i
                      class="fa-brands fa-linkedin"
                      style={socialLinks.linkedin ? { color: "#0077b5" } : {}}
                    ></i>
                  </Link>
                </div>
                <div className="social-icon">
                  <Link to="" onClick={noLink}>
                    <i
                      class="fa-brands fa-github"
                      style={socialLinks.github ? { color: "#333" } : {}}
                    ></i>
                  </Link>
                </div>
                <div className="social-icon">
                  <Link to="" onClick={noLink}>
                    <i
                      class="fa-brands fa-youtube"
                      style={socialLinks.youtube ? { color: "#FF0000" } : {}}
                    ></i>
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex">
                <div className="social-icon">
                  <a href={socialLinks.facebook} target="_blank">
                    <i
                      class="fa-brands fa-facebook"
                      style={socialLinks.facebook ? { color: "#4267B2" } : {}}
                    ></i>
                  </a>
                </div>
                <div className="social-icon">
                  <a href={socialLinks.instagram} target="_blank">
                    <i
                      class="fa-brands fa-instagram"
                      style={socialLinks.instagram ? { color: "#e95950" } : {}}
                    ></i>
                  </a>
                </div>
                <div className="social-icon">
                  <a href={socialLinks.twitter} target="_blank">
                    <i
                      class="fa-brands fa-twitter"
                      style={socialLinks.twitter ? { color: "#1DA1F2" } : {}}
                    ></i>
                  </a>
                </div>
                <div className="social-icon">
                  <a href={socialLinks.linkedin} target="_blank">
                    <i
                      class="fa-brands fa-linkedin"
                      style={socialLinks.linkedin ? { color: "#0077b5" } : {}}
                    ></i>
                  </a>
                </div>
                <div className="social-icon">
                  <a href={socialLinks.github} target="_blank">
                    <i
                      class="fa-brands fa-github"
                      style={socialLinks.github ? { color: "#333" } : {}}
                    ></i>
                  </a>
                </div>
                <div className="social-icon">
                  <a href={socialLinks.youtube} target="_blank">
                    <i
                      class="fa-brands fa-youtube"
                      style={socialLinks.youtube ? { color: "#FF0000" } : {}}
                    ></i>
                  </a>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={1000} />
    </>
  );
};

export default ProfileAbout;
