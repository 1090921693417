import React, { useState, useEffect } from 'react';
import { getUserPostedJobs } from '../../../../services/Apis';
import JobsPosted from './JobsPosted';
import JobsApply from './JobsApply';
import { Link } from 'react-router-dom';
import CompanyLogo from "../../../../../public/assets/images/company-logo.png";
import DeleteJobComponent from './DeleteJobComponent';

const ProfileJobs = ({username}) => {
  const [userJobs, setUserJobs] = useState([])
  const getJobsByUser = async () => {
    try {
      const res = await getUserPostedJobs(username);
      if (res.status === 200) {
        setUserJobs(res.data);
      } else {
        console.log('Error in Fetching Posts.');
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    getJobsByUser();
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Close the dropdown if clicked outside
  const handleClickOutside = (event) => {
    if (!event.target.closest(".dropdown")) {
      setIsOpen(false);
    }
  };

  // Add event listener for clicks outside the dropdown
  React.useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const checkUser = sessionStorage.getItem('user_username');

  return (
    <>
    <div className="container" id="profile-jobs-page">
      <div className="job-main ">
      <div class="">
        <div class="view-job-main ">
          {/* {userJobs.length<=0?(<>
          <div className="col-lg-8 text-center text">
            No Jobs Posted
          </div>
          </>):(<>
          <div className="col-lg-8">
          <JobsPosted username={username} checkUser={checkUser}/>
          </div>
          <div class="col-lg-4">
          {username === checkUser?( <JobsApply />):("")}
          </div>
          </>)} */}



          <Link to="/post-job" className='postjob_box' >
        <div className=''>
          <span>
          <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26.4396 17.5L28.2771 15.6625C28.9187 15.0208 29.7354 14.6708 30.625 14.5833V13.125L21.875 4.375H7.29167C5.67292 4.375 4.375 5.67292 4.375 7.29167V27.7083C4.375 28.4819 4.68229 29.2237 5.22927 29.7707C5.77625 30.3177 6.51812 30.625 7.29167 30.625H16.0417V27.8979L16.2312 27.7083H7.29167V7.29167H17.5V17.5H26.4396ZM20.4167 6.5625L28.4375 14.5833H20.4167V6.5625ZM27.8979 20.1688L30.8729 23.1438L21.9333 32.0833H18.9583V29.1083L27.8979 20.1688ZM33.3229 20.6938L31.8937 22.1229L28.9187 19.1479L30.3479 17.7188C30.625 17.4271 31.1062 17.4271 31.3979 17.7188L33.3229 19.6438C33.6146 19.9354 33.6146 20.4167 33.3229 20.6938Z" fill="#272D3B"/>
</svg>
<h2>Post Job</h2>
<p>Click to post the new job</p>

          </span>
        </div>
          
          </Link>


<div className='row justify-content-center gap-3 ' style={{marginTop:"30px"}}>
<div className='col-lg-3 jobCard'>
          <div className="text_title">
                          <div className="d-flex align-items-center gap-2">
                            <img
                              src={CompanyLogo}
                              alt=""
                              width="30px"
                              height="30px"
                            />
                            <h2>
                              Yoeavnu Tech-Company
                              <span>
                                <svg
                                  width="19"
                                  height="18"
                                  viewBox="0 0 23 22"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.1622 17.1489L18.0242 21.2905L16.2032 13.4847L22.2658 8.23269L14.2823 7.55537L11.1622 0.19368L8.04208 7.55537L0.0585938 8.23269L6.12116 13.4847L4.30017 21.2905L11.1622 17.1489Z"
                                    fill="black"
                                  />
                                </svg>
                              </span>
                            </h2>
                          </div>
                          <div>
                          <div className="dropdown" style={{ position: "relative", display: "inline-block" }}>
      <button className="dropdown-btn" onClick={toggleDropdown} >
        <svg
          width="4"
          height="14"
          viewBox="0 0 4 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.5 12C3.5 12.3978 3.34196 12.7794 3.06066 13.0607C2.77936 13.342 2.39782 13.5 2 13.5C1.60218 13.5 1.22064 13.342 0.93934 13.0607C0.658035 12.7794 0.5 12.3978 0.5 12C0.5 11.6022 0.658035 11.2206 0.93934 10.9393C1.22064 10.658 1.60218 10.5 2 10.5C2.39782 10.5 2.77936 10.658 3.06066 10.9393C3.34196 11.2206 3.5 11.6022 3.5 12ZM3.5 7C3.5 7.39782 3.34196 7.77936 3.06066 8.06066C2.77936 8.34196 2.39782 8.5 2 8.5C1.60218 8.5 1.22064 8.34196 0.93934 8.06066C0.658035 7.77936 0.5 7.39782 0.5 7C0.5 6.60218 0.658035 6.22064 0.93934 5.93934C1.22064 5.65804 1.60218 5.5 2 5.5C2.39782 5.5 2.77936 5.65804 3.06066 5.93934C3.34196 6.22064 3.5 6.60218 3.5 7ZM3.5 2C3.5 2.39782 3.34196 2.77936 3.06066 3.06066C2.77936 3.34196 2.39782 3.5 2 3.5C1.60218 3.5 1.22064 3.34196 0.93934 3.06066C0.658035 2.77936 0.5 2.39782 0.5 2C0.5 1.60218 0.658035 1.22064 0.93934 0.93934C1.22064 0.658035 1.60218 0.5 2 0.5C2.39782 0.5 2.77936 0.658035 3.06066 0.93934C3.34196 1.22064 3.5 1.60218 3.5 2Z"
            fill="black"
          />
        </svg>
      </button>

      {/* Dropdown content */}
      <div
        className={`dropdown-content edit_delete_dropown ${isOpen ? "show" : ""}`}
        style={{
          display: isOpen ? "block" : "none",
        
        }}
      >
       <div>
        <Link to="/job-edit" >
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.7059 2.87997L13.3529 5.47712M8.94118 15H16M1.88235 11.5371L1 15L4.52941 14.1343L14.7524 4.10409C15.0832 3.7794 15.269 3.33908 15.269 2.87997C15.269 2.42085 15.0832 1.98054 14.7524 1.65584L14.6006 1.50694C14.2697 1.18235 13.8209 1 13.3529 1C12.885 1 12.4362 1.18235 12.1053 1.50694L1.88235 11.5371Z" stroke="#272D3B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  Edit Job
        </Link>
       </div>
        <div>
         <DeleteJobComponent />
        </div>
       
      </div>
  </div>
                          </div>
                        </div>

                        <div className="job_details">
                          <h2>
                            UI/UX Designer
                            <span>Remotely</span>
                          </h2>
                          <p className="loction">
                            {" "}
                            <svg
                              width="11"
                              height="14"
                              viewBox="0 0 11 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M5.5 12.9993L5.97325 12.4674C6.51025 11.8544 6.9935 11.2712 7.423 10.7176L7.77775 10.2508C9.25925 8.26039 10 6.68119 10 5.51318C10 3.02057 7.9855 1 5.5 1C3.0145 1 1 3.02057 1 5.51318C1 6.68119 1.74075 8.26064 3.22225 10.2515L3.577 10.7183C4.19016 11.5018 4.83158 12.2621 5.5 12.9993Z"
                                stroke="#545454"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M5.5 7.375C6.53553 7.375 7.375 6.53553 7.375 5.5C7.375 4.46447 6.53553 3.625 5.5 3.625C4.46447 3.625 3.625 4.46447 3.625 5.5C3.625 6.53553 4.46447 7.375 5.5 7.375Z"
                                stroke="#545454"
                                stroke-width="1.4"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Kolkata
                          </p>

                          <div className="dflex_salary">
                            <div className="salary">
                              <span>Rs 40,000 - Rs 70,000 a month</span>
                            </div>
                            <div className="job_type">
                              <span>Full Time</span>
                            </div>
                          </div>
                         
                        </div>
          </div>
          <div className='col-lg-3 jobCard'>
          <div className="text_title">
                          <div className="d-flex align-items-center gap-2">
                            <img
                              src={CompanyLogo}
                              alt=""
                              width="30px"
                              height="30px"
                            />
                            <h2>
                              Yoeavnu Tech-Company
                              <span>
                                <svg
                                  width="19"
                                  height="18"
                                  viewBox="0 0 23 22"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.1622 17.1489L18.0242 21.2905L16.2032 13.4847L22.2658 8.23269L14.2823 7.55537L11.1622 0.19368L8.04208 7.55537L0.0585938 8.23269L6.12116 13.4847L4.30017 21.2905L11.1622 17.1489Z"
                                    fill="black"
                                  />
                                </svg>
                              </span>
                            </h2>
                          </div>
                          <div>
                            <button>
                              <svg
                                width="4"
                                height="14"
                                viewBox="0 0 4 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.5 12C3.5 12.3978 3.34196 12.7794 3.06066 13.0607C2.77936 13.342 2.39782 13.5 2 13.5C1.60218 13.5 1.22064 13.342 0.93934 13.0607C0.658035 12.7794 0.5 12.3978 0.5 12C0.5 11.6022 0.658035 11.2206 0.93934 10.9393C1.22064 10.658 1.60218 10.5 2 10.5C2.39782 10.5 2.77936 10.658 3.06066 10.9393C3.34196 11.2206 3.5 11.6022 3.5 12ZM3.5 7C3.5 7.39782 3.34196 7.77936 3.06066 8.06066C2.77936 8.34196 2.39782 8.5 2 8.5C1.60218 8.5 1.22064 8.34196 0.93934 8.06066C0.658035 7.77936 0.5 7.39782 0.5 7C0.5 6.60218 0.658035 6.22064 0.93934 5.93934C1.22064 5.65804 1.60218 5.5 2 5.5C2.39782 5.5 2.77936 5.65804 3.06066 5.93934C3.34196 6.22064 3.5 6.60218 3.5 7ZM3.5 2C3.5 2.39782 3.34196 2.77936 3.06066 3.06066C2.77936 3.34196 2.39782 3.5 2 3.5C1.60218 3.5 1.22064 3.34196 0.93934 3.06066C0.658035 2.77936 0.5 2.39782 0.5 2C0.5 1.60218 0.658035 1.22064 0.93934 0.93934C1.22064 0.658035 1.60218 0.5 2 0.5C2.39782 0.5 2.77936 0.658035 3.06066 0.93934C3.34196 1.22064 3.5 1.60218 3.5 2Z"
                                  fill="black"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>

                        <div className="job_details">
                          <h2>
                            UI/UX Designer
                            <span>Remotely</span>
                          </h2>
                          <p className="loction">
                            {" "}
                            <svg
                              width="11"
                              height="14"
                              viewBox="0 0 11 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M5.5 12.9993L5.97325 12.4674C6.51025 11.8544 6.9935 11.2712 7.423 10.7176L7.77775 10.2508C9.25925 8.26039 10 6.68119 10 5.51318C10 3.02057 7.9855 1 5.5 1C3.0145 1 1 3.02057 1 5.51318C1 6.68119 1.74075 8.26064 3.22225 10.2515L3.577 10.7183C4.19016 11.5018 4.83158 12.2621 5.5 12.9993Z"
                                stroke="#545454"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M5.5 7.375C6.53553 7.375 7.375 6.53553 7.375 5.5C7.375 4.46447 6.53553 3.625 5.5 3.625C4.46447 3.625 3.625 4.46447 3.625 5.5C3.625 6.53553 4.46447 7.375 5.5 7.375Z"
                                stroke="#545454"
                                stroke-width="1.4"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Kolkata
                          </p>

                          <div className="dflex_salary">
                            <div className="salary">
                              <span>Rs 40,000 - Rs 70,000 a month</span>
                            </div>
                            <div className="job_type">
                              <span>Full Time</span>
                            </div>
                          </div>
                         
                        </div>
          </div>
          <div className='col-lg-3 jobCard'>
          <div className="text_title">
                          <div className="d-flex align-items-center gap-2">
                            <img
                              src={CompanyLogo}
                              alt=""
                              width="30px"
                              height="30px"
                            />
                            <h2>
                              Yoeavnu Tech-Company
                              <span>
                                <svg
                                  width="19"
                                  height="18"
                                  viewBox="0 0 23 22"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.1622 17.1489L18.0242 21.2905L16.2032 13.4847L22.2658 8.23269L14.2823 7.55537L11.1622 0.19368L8.04208 7.55537L0.0585938 8.23269L6.12116 13.4847L4.30017 21.2905L11.1622 17.1489Z"
                                    fill="black"
                                  />
                                </svg>
                              </span>
                            </h2>
                          </div>
                          <div>
                            <button>
                              <svg
                                width="4"
                                height="14"
                                viewBox="0 0 4 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.5 12C3.5 12.3978 3.34196 12.7794 3.06066 13.0607C2.77936 13.342 2.39782 13.5 2 13.5C1.60218 13.5 1.22064 13.342 0.93934 13.0607C0.658035 12.7794 0.5 12.3978 0.5 12C0.5 11.6022 0.658035 11.2206 0.93934 10.9393C1.22064 10.658 1.60218 10.5 2 10.5C2.39782 10.5 2.77936 10.658 3.06066 10.9393C3.34196 11.2206 3.5 11.6022 3.5 12ZM3.5 7C3.5 7.39782 3.34196 7.77936 3.06066 8.06066C2.77936 8.34196 2.39782 8.5 2 8.5C1.60218 8.5 1.22064 8.34196 0.93934 8.06066C0.658035 7.77936 0.5 7.39782 0.5 7C0.5 6.60218 0.658035 6.22064 0.93934 5.93934C1.22064 5.65804 1.60218 5.5 2 5.5C2.39782 5.5 2.77936 5.65804 3.06066 5.93934C3.34196 6.22064 3.5 6.60218 3.5 7ZM3.5 2C3.5 2.39782 3.34196 2.77936 3.06066 3.06066C2.77936 3.34196 2.39782 3.5 2 3.5C1.60218 3.5 1.22064 3.34196 0.93934 3.06066C0.658035 2.77936 0.5 2.39782 0.5 2C0.5 1.60218 0.658035 1.22064 0.93934 0.93934C1.22064 0.658035 1.60218 0.5 2 0.5C2.39782 0.5 2.77936 0.658035 3.06066 0.93934C3.34196 1.22064 3.5 1.60218 3.5 2Z"
                                  fill="black"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>

                        <div className="job_details">
                          <h2>
                            UI/UX Designer
                            <span>Remotely</span>
                          </h2>
                          <p className="loction">
                            {" "}
                            <svg
                              width="11"
                              height="14"
                              viewBox="0 0 11 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M5.5 12.9993L5.97325 12.4674C6.51025 11.8544 6.9935 11.2712 7.423 10.7176L7.77775 10.2508C9.25925 8.26039 10 6.68119 10 5.51318C10 3.02057 7.9855 1 5.5 1C3.0145 1 1 3.02057 1 5.51318C1 6.68119 1.74075 8.26064 3.22225 10.2515L3.577 10.7183C4.19016 11.5018 4.83158 12.2621 5.5 12.9993Z"
                                stroke="#545454"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M5.5 7.375C6.53553 7.375 7.375 6.53553 7.375 5.5C7.375 4.46447 6.53553 3.625 5.5 3.625C4.46447 3.625 3.625 4.46447 3.625 5.5C3.625 6.53553 4.46447 7.375 5.5 7.375Z"
                                stroke="#545454"
                                stroke-width="1.4"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Kolkata
                          </p>

                          <div className="dflex_salary">
                            <div className="salary">
                              <span>Rs 40,000 - Rs 70,000 a month</span>
                            </div>
                            <div className="job_type">
                              <span>Full Time</span>
                            </div>
                          </div>
                         
                        </div>
          </div>
</div>
          
        </div>
      </div>
      </div>
    </div>
    {/* </>)} */}
    </>
  )
}

export default ProfileJobs