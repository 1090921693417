import React, { useState, useEffect, useContext } from "react";
import HeaderTop from "../../../components/header/large-screen/HeaderTop";
import HomeSidebar from "../../home/large-view/HomeSidebar";
import { commonSidebar } from "../../../components/context/ContextProvider";
import TabService from "../TabService";
import "./Category.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import SmallHeader from "../../../components/header/small-screen/SmallHeader";
import SmallFooter from "../../../components/footer/SmallFooter";
import { getMainAndSubCatsByMainCat } from "../../../../services/Apis";
import { BASE_URL } from "../../../../services/helper";
import RecommendedService from "../RecommendedService";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NextArrow, PreviousArrow } from "../../../components/functions/OtherFunctions";

const ServicesMainCategory = () => {
  const navigate = useNavigate();
  const { showMenu, setShowMenu } = useContext(commonSidebar);
  const checkAndNavigate = (navigateTo) => {
    if (isValid) {
      navigate(navigateTo);
    } else {
      toast.error("Please Login First", {
        onClose: () => navigate("/login")
      });
    }
  };

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 700);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 700);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: false, // Shows dots at the bottom of the slider
    infinite: false, // Enables infinite looping of the slides
    speed: 500, // Speed of the transition (in ms)
    slidesToShow: 4, // Number of slides visible at a time
    slidesToScroll: 1, // Number of slides to scroll at a time
    prevArrow: <PreviousArrow />, // Custom previous arrow
    nextArrow: <NextArrow />, // Custom next arrow
    responsive: [
      {
        breakpoint: 768, // Below this width, show fewer slides
        settings: {
          slidesToShow: 4, // On mobile, show 1 slide
        },
      },
      {
        breakpoint: 350, // Below this width, show fewer slides
        settings: {
          slidesToShow: 3, // On mobile, show 1 slide
        },
      },
    ],
  };

  const { main_cat } = useParams();

  const [mainCat, setMainCat] = useState({});
  const [loadingSubCats, setLoadingSubCats] = useState(true);
  const [subCats, setSubCats] = useState([]);

  const fetchMainSubCats = async () => {
    try {
      const res = await getMainAndSubCatsByMainCat(main_cat);
      if (res.status === 200) {
        setMainCat(res.data.mainCategory);
        setSubCats(res.data.subCategories);
      } else {
        console.log('Error in fetching categories.');
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    } finally {
      setLoadingSubCats(false);
    }
  };

  useEffect(() => {
    fetchMainSubCats();
  }, [main_cat]);

  return (
    <div>
      {isSmallScreen ? <SmallHeader /> : <HeaderTop />}
      <HomeSidebar showMenu={showMenu} checkAndNavigate={checkAndNavigate} />

      <TabService />
      <div id="category_main" style={showMenu ? { filter: "blur(2px)" } : {}}>
        <div className="banner" style={{ "backgroundImage": `url(${BASE_URL}/uploads/services-cat-img/${mainCat.banner_img})` }}>
          <div className="banner_text">
            <h2>{mainCat.main_cat_title}</h2>
            <p>{mainCat.main_cat_caption}</p>
          </div>
        </div>
        <h3 className="service_heading">Explore {mainCat.main_cat_title}</h3>
        <div className="category_section">
          {loadingSubCats ? (<p></p>) : (<>
            {subCats.length > 0 ? (<>
              <Slider {...settings}>
                {subCats.map((subCat) => (
                  <Link to={`/service/category/${main_cat}/${subCat.sub_cat}`} key={subCat._id}>
                    <img src={`${BASE_URL}/uploads/services-cat-img/${subCat.sub_cat_img}`} alt={subCat.sub_cat_title} />
                    <h2>{subCat.sub_cat_title}</h2>
                  </Link>
                ))}
              </Slider>
            </>) : <p>No Sub Categories</p>}
          </>)}
        </div>

        <RecommendedService />
      </div>

      {isSmallScreen ? <SmallFooter /> : ""}
    </div>
  );
}

export default ServicesMainCategory