import React, { useState, useContext, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { postFeaturedKey, postTagsKey } from '../../context/ContextProvider';
import { featuredTags } from '../../../../services/Apis';
import { CustomNextArrow, CustomPrevArrow } from '../../functions/CustomArrows';
import { isTokenValid } from '../../functions/OtherFunctions';
import { ToastContainer, toast } from "react-toastify";

const HeaderBottom = () => {

  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        setIsValid(valid);
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();
  }, []);

  const settings = {
    className: "slider variable-width",
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    variableWidth: true,
    initialSlide: 0
  };

  const [featureTags, setFeatureTags] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchFeaturedTags = async () => {
    try {
      const res = await featuredTags();
      if (res.status === 200) {
        setFeatureTags(res.data);
      } else {
        console.log("Error in Fetching Featured Tags");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchFeaturedTags();
  }, [])

  const { featuredKey, setFeaturedKey } = useContext(postFeaturedKey);

  const { tagsKey, setTagsKey } = useContext(postTagsKey);

  const handleTagClick = (tag) => {
    if (tagsKey === tag) {
      setTagsKey("");
    } else {
      setTagsKey(tag);
    }
  };

  const [isFollowing, setIsFollowing] = useState(false);

  // Toggle function to change state when button is clicked
  const handleFollowClick = () => {
    if(isValid){
      if(isFollowing){
        setIsFollowing(false);
        setFeaturedKey("all");
      }
      else{
        setIsFollowing(true);
        setFeaturedKey("following");
      }
    }else {
      toast.error("Please Login First");
    }

  };
  //Note :
  // featuredKey("all") means all Posts
  // featuredKey("following") means get post for people i isFollowing
  // featuredKey("latest") means get Latest Posts
  // featuredKey("popular") means get most liked posts

  return (
    <>
      <div className="container-fluid" id="header-bottom">
        <div className="row">
          <div className="col-auto d-flex left-part">

            <div className="drop-wrapper">

            </div>
          </div>
          <div className="col p-0 right-part">
            <button className='follow_btn' onClick={handleFollowClick}>
              {isFollowing ? (
                // If following, show this SVG
                <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="14.5" cy="14.5" r="14.5" fill="#ECECEC" />
                  <path d="M22.6879 10.3899C22.6193 10.3192 22.5374 10.2627 22.447 10.2237C22.3565 10.1846 22.2593 10.1638 22.1608 10.1623C22.0623 10.1609 21.9645 10.1789 21.8729 10.2152C21.7813 10.2516 21.6979 10.3056 21.6272 10.3742L19.5704 12.371L19.0376 11.3779C18.9921 11.2893 18.9294 11.2107 18.8531 11.1466C18.7769 11.0826 18.6886 11.0343 18.5935 11.0048C18.4984 10.9752 18.3984 10.9648 18.2993 10.9743C18.2001 10.9838 18.1039 11.0129 18.0161 11.06C17.9284 11.107 17.8509 11.1711 17.7881 11.2485C17.7254 11.3258 17.6787 11.4149 17.6508 11.5105C17.6228 11.6061 17.6142 11.7063 17.6254 11.8052C17.6366 11.9042 17.6674 11.9999 17.716 12.0868L18.7188 13.9562C18.8134 14.1332 18.974 14.2631 19.1628 14.3201L19.2645 14.3429C19.3792 14.3607 19.4964 14.3517 19.607 14.3165C19.7176 14.2812 19.8186 14.2208 19.9019 14.14L22.6718 11.4505C22.7425 11.3819 22.799 11.3001 22.8381 11.2096C22.8772 11.1192 22.898 11.022 22.8995 10.9235C22.901 10.825 22.8831 10.7272 22.8468 10.6357C22.8105 10.5441 22.7565 10.4606 22.6879 10.3899Z" fill="#272D3B" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8889 10.1111C10.8889 9.28599 11.2167 8.49467 11.8001 7.91122C12.3836 7.32778 13.1749 7 14 7C14.8251 7 15.6164 7.32778 16.1999 7.91122C16.7833 8.49467 17.1111 9.28599 17.1111 10.1111C17.1111 10.9362 16.7833 11.7276 16.1999 12.311C15.6164 12.8944 14.8251 13.2222 14 13.2222C13.1749 13.2222 12.3836 12.8944 11.8001 12.311C11.2167 11.7276 10.8889 10.9362 10.8889 10.1111ZM10.8889 14.7778C9.85749 14.7778 8.86834 15.1875 8.13903 15.9168C7.40972 16.6461 7 17.6353 7 18.6667C7 19.2855 7.24583 19.879 7.68342 20.3166C8.121 20.7542 8.7145 21 9.33333 21H18.6667C19.2855 21 19.879 20.7542 20.3166 20.3166C20.7542 19.879 21 19.2855 21 18.6667C21 17.6353 20.5903 16.6461 19.861 15.9168C19.1317 15.1875 18.1425 14.7778 17.1111 14.7778H10.8889Z" fill="#272D3B" />
                </svg>


              ) : (
                // If not following, show this SVG
                <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="14.8171" cy="14.5" r="14.5" fill="#ECECEC" />
                  <g clip-path="url(#clip0_15_92)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.917 10.875C11.917 10.1059 12.2225 9.36825 12.7664 8.8244C13.3102 8.28054 14.0479 7.97501 14.817 7.97501C15.5861 7.97501 16.3237 8.28054 16.8676 8.8244C17.4115 9.36825 17.717 10.1059 17.717 10.875C17.717 11.6441 17.4115 12.3818 16.8676 12.9256C16.3237 13.4695 15.5861 13.775 14.817 13.775C14.0479 13.775 13.3102 13.4695 12.7664 12.9256C12.2225 12.3818 11.917 11.6441 11.917 10.875ZM11.917 15.225C10.9556 15.225 10.0335 15.6069 9.35373 16.2867C8.67391 16.9666 8.29199 17.8886 8.29199 18.85C8.29199 19.4269 8.52114 19.9801 8.92904 20.388C9.33693 20.7959 9.89015 21.025 10.467 21.025H19.167C19.7438 21.025 20.2971 20.7959 20.705 20.388C21.1128 19.9801 21.342 19.4269 21.342 18.85C21.342 17.8886 20.9601 16.9666 20.2803 16.2867C19.6004 15.6069 18.6784 15.225 17.717 15.225H11.917Z" fill="#272D3B" />
                  </g>
                  <defs>
                    <clipPath id="clip0_15_92">
                      <rect width="18.3667" height="18.3667" fill="white" transform="translate(5.15039 4.83334)" />
                    </clipPath>
                  </defs>
                </svg>
              )}
            </button>
            <div className="nav-wrapper">
              {loading ? (
                <div>Loading Tags.....</div>
              ) : featureTags.length > 0 ? (
                <Slider {...settings}>
                  <div
                    className={`link-btn ${tagsKey === "" ? "active-link-btn" : ""}`}
                    onClick={() => setTagsKey("")}
                  >
                    <a>All</a>
                  </div>
                  {featureTags.map((tag, index) => (
                    <div
                      className={`link-btn ${tagsKey === tag.tag ? "active-link-btn" : ""}`}
                      key={index}
                      onClick={() => handleTagClick(tag.tag)}
                    >
                      <a>{tag.tag}</a>
                    </div>
                  ))}
                </Slider>
              ) : (
                <div>---- No Featured Tags to Show ---</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={1000} />
    </>
  );
}

export default HeaderBottom;