import React, { useState, useEffect, useContext, useRef } from 'react';
import HeaderTop from '../../components/header/large-screen/HeaderTop';
import Footer from '../../components/footer/Footer';
import './profile.css';
import { Link, NavLink, useParams, useNavigate } from 'react-router-dom';
import { createChat, getSingleUser, getUserFollowers, userCoverImgUpdate, userFollowUnfollow } from '../../../services/Apis';
import { BASE_URL } from '../../../services/helper';
import ProfileWorks from './view-profile/ProfileWorks';
import ProfileProjects from './view-profile/ProfileProjects';
import ProfileCollections from './view-profile/ProfileCollections';
import ProfileAbout from './view-profile/ProfileAbout';
import ProfileJobs from './view-profile/ProfileJobs';
import SmallFooter from '../../components/footer/SmallFooter';
import Sidebar from '../../components/common/Sidebar';
import { isTokenValid } from '../../components/functions/OtherFunctions';
import { ToastContainer, toast } from 'react-toastify';
import HomeSidebar from '../home/large-view/HomeSidebar';
import { headerMenu, mainTheme } from '../../components/context/ContextProvider';

const Profile = () => {

  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        setIsValid(valid);
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();
  }, []);

  const navigate = useNavigate();

  const [showOpt, setShowOpt] = useState(false);
  const showOptions = () => {
    setShowOpt(!showOpt);
  };

  const [showNav, setShowNav] = useState("works");
  const switchNav = (option) => {
    setShowNav(option);
  };

  const { username } = useParams();
  const [userData, setUserData] = useState({});

  const userProfileGet = async () => {
    try {
      const res = await getSingleUser(username);
      if (res.status === 200) {
        setUserData(res.data);
      } else {
        console.log("Error in Fetching User Details.");
        handleUserError();
      }
    } catch (error) {
      console.error("Error in Fetching User Details:", error);
      handleUserError();
    }
  };

  const handleUserError = () => {
    navigate("*");
  };

  useEffect(() => {
    const fetchData = async () => {
      await userProfileGet();
    };

    fetchData();
  }, [username]);

  useEffect(() => {
    if (!userData) {
      handleUserError();
    }
  }, [userData]);


  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 700);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const logOutBtn = () => {
    if (isValid) {
      if (window.confirm("Are you sure you want to log Out?")) {
        sessionStorage.clear();
        navigate("/");
      }
    }
  }

  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const [coverImg, setCoverImg] = useState('');
  const [showImg, setShowImg] = useState('');
  const [imgSelected, setImgSelected] = useState(false);

  const handleSelectImg = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (allowedFileTypes.includes(selectedFile.type)) {
        setShowImg(URL.createObjectURL(selectedFile));
        setCoverImg(selectedFile);
        setImgSelected(true);
      } else {
        toast.error("Only png, jpg, jpeg Images are Allowed");
      }
    }
  }

  const user_id = sessionStorage.getItem("user_id");
  const logged_username = sessionStorage.getItem("user_username");

  const handleSaveImg = async () => {
    if (user_id === "") { toast.error("Sorry some data is Missing") }
    else {
      const data = new FormData();
      data.append("id", user_id)
      data.append("cover_img", coverImg)
      const config = {
        "Content-Type": "multipart/form-data"
      }
      const res = await userCoverImgUpdate(data, config);
      if (res.status === 200) {
        userProfileGet();
        toast.success("Successfully Updated Profile");
        setImgSelected(false);
      }
      else {
        toast.error("Sorry Unable to Updated Profile");
      }
    }
  }

  const openChat = async () => {
    if (isValid === true) {
      const data = { sender: logged_username, receiver: username };
      try {
        const config = { 'Content-Type': 'application/json', };
        const res = await createChat(data, config);
        if (res.status === 200) {
          navigate("/message");
        }
      } catch (error) {
        console.error('Error:', error.response.data);
      }
    }
    else {
      toast.error('Please Login First', {
        onClose: () => navigate('/login')
      });
    }
  }

  const [followers, setFollowers] = useState([]);
  const fetchFollowers = async () => {
    try {
      const res = await getUserFollowers(username);
      if (res.status === 200) {
        setFollowers(res.data.followers);
      } else {
        console.log('Error in Fetching Followers.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleFollow = async () => {
    if (isValid === true) {
      const data = { user_id: userData._id, follower_id: user_id };
      try {
        const config = { 'Content-Type': 'application/json', };
        const res = await userFollowUnfollow(data, config);
        if (res.status === 200) {
          fetchFollowers();
        }
      } catch (error) {
        console.error('Error:', error.response.data);
      }
    } else {
      // alert("Please Login First");
      toast.error("Please Login First");
    }
  };
  const checkAndNavigate = (navigateTo) => {
    if (isValid) { navigate(navigateTo); }
    else {
      // alert("Please Login First");
      // navigate("/login");
      toast.error('Please Login First', {
        onClose: () => navigate('/login')
      });
    }
  }
  const { showMenu, setShowMenu } = useContext(headerMenu);
  const { pageTheme, setPageTheme } = useContext(mainTheme);
  return (
    <>
      {isValid && !isSmallScreen ? (<HomeSidebar showMenu={showMenu} checkAndNavigate={checkAndNavigate} />) : ('')}
      {userData ? (
        <>
          {isSmallScreen ? ("") : (<HeaderTop />)}


          <div className="" id="profile-page" style={isValid ? ({}) : ({ "marginTop": "60px" })}>




            <div className="large-profile-top">
              <div className="profile-banner">
                {showImg ? (<img src={showImg} />) : (<>
                  {userData.cover_img ? (<img src={`${BASE_URL}/uploads/users-cover-img/${userData.cover_img}`} />
                  ) : (
                    pageTheme === "dark_th" ? (<img src="assets/images/profile-page/cover-img-dark.jpg" />
                    ) : (
                      <img src="assets/images/profile-page/cover-img-light.jpg" />))}
                </>)}
              </div>
              <div className="profile-head">
                <div className="left-head col-2">
                  <Link to={`${BASE_URL}/uploads/admins-users-img/${userData.user_img}`}><img src={`${BASE_URL}/uploads/admins-users-img/${userData.user_img}`} width="120px" height="120px" className='rounded-circle' /></Link>


                </div>
                <div className="col-4">
                  <div className="">
                    <div className='user-options'>
                      {isValid && (logged_username === username) ? (
                        <>
                          {showImg && imgSelected ? (<>
                            <div className='edit-profile-btn cancel'>
                              <button type='button' onClick={() => setShowImg('')} className=''> Cancel</button>
                            </div>
                            <div className='edit-profile-btn save' >
                              <button type='button' onClick={handleSaveImg} className=''> Save</button>
                            </div>
                          </>) : (<>
                            <div className='edit-profile-btn cover_btn'>
                              <input type="file" style={{ "display": "none" }} onChange={handleSelectImg} ref={fileInputRef} />
                              <button type='button' onClick={handleButtonClick}><svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.778 18H12.222C15.343 18 16.904 18 18.025 17.265C18.5088 16.9482 18.9254 16.5391 19.251 16.061C20 14.961 20 13.428 20 10.364C20 7.30005 20 5.76705 19.251 4.66705C18.9254 4.18904 18.5088 3.77991 18.025 3.46305C17.305 2.99005 16.403 2.82105 15.022 2.76105C14.363 2.76105 13.796 2.27105 13.667 1.63605C13.5684 1.17092 13.3123 0.754083 12.9418 0.455997C12.5714 0.157912 12.1095 -0.00314481 11.634 4.65308e-05H8.366C7.378 4.65308e-05 6.527 0.685047 6.333 1.63605C6.204 2.27105 5.637 2.76105 4.978 2.76105C3.598 2.82105 2.696 2.99105 1.975 3.46305C1.49154 3.78001 1.07527 4.18914 0.75 4.66705C0 5.76705 0 7.29905 0 10.364C0 13.429 5.96046e-08 14.96 0.749 16.061C1.073 16.537 1.489 16.946 1.975 17.265C3.096 18 4.657 18 7.778 18ZM10 6.27305C7.699 6.27305 5.833 8.10405 5.833 10.363C5.833 12.622 7.7 14.456 10 14.456C12.3 14.456 14.167 12.624 14.167 10.365C14.167 8.10605 12.3 6.27305 10 6.27305ZM10 7.90905C8.62 7.90905 7.5 9.00805 7.5 10.364C7.5 11.719 8.62 12.818 10 12.818C11.38 12.818 12.5 11.719 12.5 10.364C12.5 9.00905 11.38 7.90905 10 7.90905ZM14.722 7.09105C14.722 6.63905 15.095 6.27305 15.556 6.27305H16.666C17.126 6.27305 17.5 6.63905 17.5 7.09105C17.4979 7.30994 17.409 7.51905 17.2528 7.67243C17.0966 7.82581 16.8859 7.91091 16.667 7.90905H15.556C15.4475 7.9101 15.3399 7.88978 15.2393 7.84924C15.1387 7.80871 15.047 7.74874 14.9696 7.67278C14.8921 7.59683 14.8304 7.50636 14.7879 7.40654C14.7454 7.30673 14.723 7.19952 14.722 7.09105Z" fill="#272D3B" />
                              </svg>
                              </button>
                            </div>
                          </>)}
                        </>
                      ) : (
                        <div className='edit-profile-btn'><Link to='/signup'>Get In Touch</Link>
                        </div>)}
                      {isValid && (logged_username === username) ? (<>
                        {/* <div><button className='options-btn' onClick={showOptions}><i class="fa-solid fa-ellipsis"></i></button></div>
                          <div className={showOpt ? 'dropdown-opt' : 'dropdown-opt d-none'}>
                            <Link style={{ "textDecoration": "none" }} to="/edit-profile"><p>Edit your account settings</p></Link>
                            <p>Edit work preferences</p>
                          </div> */}
                        <div><button className='options-btn' onClick={() => navigate("/edit-profile")}>Edit Profile</button></div>
                        <div><button className='options-btn_logout' onClick={logOutBtn}><i class="fa-solid fa-power-off" style={{ "color": "red", "fontSize": "22px" }}></i></button></div>
                      </>) : ('')}
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className='user-title'>{userData.fname + " " + userData.lname}
              <span>
                @{userData.username}
              </span>
              <p>Actor | Offical Account</p>




              {/* <------------------------------------- SMALL PROFILE TOP START ------------------------------------->*/}
              {/* <div className="small-profile-top">
                <div className="profile-banner">
                  {showImg ? (<img src={showImg} />) : (<>
                    {userData.cover_img ? (<img src={`${BASE_URL}/uploads/users-cover-img/${userData.cover_img}`} />
                    ) : (
                      pageTheme === "dark_th" ? (<img src="assets/images/profile-page/cover-img-dark.jpg" />
                      ) : (
                        <img src="assets/images/profile-page/cover-img-light.jpg" />))}
                  </>)}
                </div>
                <div className="profile-head">
                  <div className="left-head col-2">
                    <Link to={`${BASE_URL}/uploads/admins-users-img/${userData.user_img}`}><img src={`${BASE_URL}/uploads/admins-users-img/${userData.user_img}`} width="120px" height="120px" className='rounded-circle' /></Link>
                  </div>
                  <div className="right-head col-6">
                    <div className="row">
                      <div className="col-12"><div className='user-title'>{userData.fname + " " + userData.lname}</div></div>
                      <div className="col-12"><div className='user-username'>{userData.username}</div></div>
                    </div>
                  </div>
                  <div className="col-4 right2-head">
                    <div className='user-options'>
                      {isValid && (logged_username === username) ? (
                        <>
                         
                           {showImg && imgSelected ? (<>
                              <div className='edit-profile-btn'>
                                <button type='button' onClick={() => setShowImg('')}><i class="fa-solid fa-xmark" style={{"color":"red"}}></i>&nbsp; Cancel</button>
                              </div><div className='edit-profile-btn'>
                                <button type='button' onClick={handleSaveImg}><i class="fa-solid fa-check" style={{"color":"green"}}></i>&nbsp; Save</button>
                              </div>
                            </>) : (<>
                              <div className='edit-profile-btn'>
                                <input type="file" style={{ "display": "none" }} onChange={handleSelectImg} ref={fileInputRef} />
                                <button type='button' onClick={handleButtonClick}><i class="fa-solid fa-camera"></i>&nbsp; Edit Cover Photo</button>
                              </div>
                            </>)}
                        </>
                      ) : (
                        <>
                        <div className='edit-profile-btn'><button onClick={()=> openChat()}>Get In Touch</button></div>
                        <div className='edit-profile-btn'>
                        <button onClick={()=> handleFollow()}>
                        {followers.length > 0 ?
                          (followers.some((follow) => follow.follower_id === user_id) ?
                            (<><i className="fa-solid fa-check"></i> &nbsp;Following</>) :
                            (<><i className="fa-solid fa-plus"></i>  &nbsp;Follow</>)) :
                          (<><i className="fa-solid fa-plus"></i>  &nbsp;Follow</>)
                        }
                        </button></div>
                        </>
                      )}
                      {isValid && (logged_username === username) ? (<>
                       
                        <div><button className='options-btn' onClick={()=>navigate("/edit-profile")}><i class="fa-solid fa-gear" style={{ "fontSize": "21px" }}></i></button></div>
                        <div><button className='options-btn' onClick={logOutBtn}><i class="fa-solid fa-power-off" style={{ "color": "red", "fontSize": "21px" }}></i></button></div>
                      </>) : ('')}
                    </div>
                  </div>
                </div>
              </div> */}

            </div>
            <hr className='mt-4 mb-4 hr_line' />
            <div className='bottom_tabs'>


              <div className="row nav-tab" style={{ "padding": "0 75px", "paddingBottom": "30px" }}>
                <div className="profile-bottom-nav">
                  <div className="nav-wrap">
                    <ul class="nav nav-pills">
                      <li class="nav-item">
                        <a class={showNav == "works" ? "nav-link active" : "nav-link"} onClick={() => switchNav("works")}>Works</a>
                      </li>
                      <li class="nav-item">
                        <a class={showNav == "jobs" ? "nav-link active" : "nav-link"} onClick={() => switchNav("jobs")}>Job</a>
                      </li>
                      <li class="nav-item">
                        <a class={showNav == "shop" ? "nav-link active" : "nav-link"} onClick={() => switchNav("shop")}>Shop</a>
                      </li>
                      <li class="nav-item">
                        <a class={showNav == "collections" ? "nav-link active" : "nav-link"} onClick={() => switchNav("collections")}>Collection</a>
                      </li>
                      <li class="nav-item">
                        <a class={showNav == "about" ? "nav-link active" : "nav-link"} onClick={() => switchNav("about")}>About</a>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
              <div className="row content-tab" style={{ "padding": "0 75px 27px 75px" }}>
                <div className="profile-bottom-content">
                  {showNav === "works" ? <ProfileWorks username={username} /> : ''}
                  {showNav === "projects" ? <ProfileProjects username={username} /> : ''}
                  {showNav === "collections" ? <ProfileCollections username={username} /> : ''}
                  {showNav === "jobs" ? <ProfileJobs username={username} /> : ''}
                  {showNav === "about" ? <ProfileAbout username={username} /> : ''}
                </div>
              </div>
            </div>
            <ToastContainer position="top-center" autoClose={1000} />
          </div>
          {isSmallScreen ? (<SmallFooter />) : ("")}
        </>
      ) : ("")
      }
    </>
  )
}

export default Profile