import React, { useEffect, useState, useContext } from 'react';
import './header.css';
import Slider from 'react-slick';

import { NavLink, useNavigate } from 'react-router-dom';
import { getSingleUser } from '.././../../../services/Apis';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderTopRight from './HeaderTopRight';
import light_logo from '../../../assets/images/yoeavnu_logo.png';
import dark_logo from '../../../assets/images/dark_logo.png';
import { mainTheme, postSearchKey } from '../../context/ContextProvider';
import { isTokenValid } from '../../functions/OtherFunctions';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { headerMenu, postFeaturedKey, postTagsKey } from '../../context/ContextProvider';
import { TbTriangleInvertedFilled, TbTriangleFilled } from "react-icons/tb";
import { featuredTags } from '../../../../services/Apis';
import { CustomNextArrow, CustomPrevArrow } from '../../functions/CustomArrows';
const HeaderTop = () => {
    const navigate = useNavigate();
    const username = sessionStorage.getItem("user_username");

    const [isValid, setIsValid] = useState(false);
    useEffect(() => {
        const checkTokenValidity = async () => {
            try {
                const valid = await isTokenValid();
                setIsValid(valid);
            } catch (error) {
                console.error("Error checking token validity:", error);
            }
        };
        checkTokenValidity();
    }, []);

    // const [userId, setUserId] = useState("");
    const [userData, setUserData] = useState({});
    const { searchKey, setSearchKey } = useContext(postSearchKey);

    const userProfileGet = async () => {
        const res = await getSingleUser(username);
        if (res.status == 200) {
            setUserData(res.data);
        }
        else {
            console.log("Error in Fetching User Details.");
        }
    }

    useEffect(() => {
        if(username){
        userProfileGet();
    }
    }, [])

    const [showProfile, setShowProfile] = useState(false);
    const profileClick = () => {
        setShowProfile(!showProfile);
    }
    const userLogout = () => {
        sessionStorage.clear();
        navigate("/");
        window.location.reload();
    }

    const { pageTheme, setPageTheme } = useContext(mainTheme);


    
  const [showDrop, setShowDrop] = useState(false);
  const { showMenu, setShowMenu } = useContext(headerMenu);

  const displayDrop = () => {
    setShowDrop(!showDrop);
  }

  const settings = {
    className: "slider variable-width",
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    variableWidth: true,
    initialSlide: 0
  };

  const [featureTags, setFeatureTags] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchFeaturedTags = async () => {
    try {
      const res = await featuredTags();
      if (res.status === 200) {
        setFeatureTags(res.data);
      } else {
        console.log("Error in Fetching Featured Tags");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchFeaturedTags();
  }, [])

  const { featuredKey, setFeaturedKey } = useContext(postFeaturedKey);

  const { tagsKey, setTagsKey } = useContext(postTagsKey);

  const handleTagClick = (tag) => {
    if (tagsKey === tag) {
      setTagsKey("");
    } else {
      setTagsKey(tag);
    }
  };
    return (
        <>
            {/* // bg-primary */}
            <div  id="header-top">
                <div className="container-fluid">
            
                <div className="row h-100">
                    <div className="col-3 d-flex  align-items-center  left-part">
                    <div className="menu-icon">
              {showMenu ? (
                <i className="fa-solid fa-xmark" style={{fontSize:"22px"}} onClick={() => setShowMenu(!showMenu)}></i>
              ) : (
                <i className="fa-solid fa-bars" style={{fontSize:"22px"}} onClick={() => setShowMenu(!showMenu)}></i>
              )}
            </div>
                     <NavLink to="/">
                            <div className='logo-div'>
                                {pageTheme == "blue_th" || pageTheme == "dark_th" ? (<img src={dark_logo} alt="Company Logo" width="150px" />) : (<img src={light_logo} alt="Company Logo" width="150px" />)}
                               </div>
                        </NavLink>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-center mid-part">
                        <form class="d-flex search-field">
                         
                            <input class="form-control me-2 search-bar" type="search" placeholder="Search by username" aria-label="Search" onChange={(e) => setSearchKey(e.target.value)} />
                            <i class="fa-solid fa-magnifying-glass"></i>
                        </form>
                    </div>
                    <div className="col-3 d-flex align-items-center justify-content-end right-part">
                        {!isValid ? (
                            <>
                                <NavLink to="/login">
                                    <div className='login-btn'>
                                        <button>Login</button>
                                    </div>
                                </NavLink>
                                <NavLink to="/signup">
                                    <div className='signup-btn'>
                                        <button>Sign up</button>
                                    </div>
                                </NavLink>
                            </>
                        ) : (<HeaderTopRight username={userData.username} user_img={userData.user_img} fname={userData.fname} lname={userData.lname} profileClick={profileClick} userLogout={userLogout} showProfile={showProfile} isValid={isValid}/>)}
                    </div>
                </div>
                        
                </div>
            </div>
      
        </>
    )
}

export default HeaderTop