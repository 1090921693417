import { BASE_URL } from '../../../services/helper';
import axios from 'axios';
// For Encryption and Decryption of Password
import CryptoJS from 'crypto-js';

const ENCRYPTION_KEY = "84ee106cf2019ad8a3f445644e590134becb7c6daa61783fc2fea649ee8e1b51";

function decrypt(text) {
    const decrypted = CryptoJS.AES.decrypt(text, ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);
    return decrypted;
}

const isTokenValid = async () => {
    const user_token = sessionStorage.getItem('user_token');

    if (user_token) {
        try {
            const res = await axios.get(`${BASE_URL}/user-token-validation`, {
                headers: {
                    Authorization: `Bearer ${user_token}`
                }
            });
            return res.status === 200; // Return true if response status is 200
        } catch (error) {
            sessionStorage.clear();
            return false;
        }
    }
    else {
        sessionStorage.clear();
        return false;
    }
};

function isValidUsername(username) {
    if (username.length < 3 || username.length > 30) {
        return false;
    }
    const alphabetRegex = /[a-zA-Z]/;
    if (!alphabetRegex.test(username)) {
        return false;
    }
    const regex = /^[a-zA-Z0-9._]+$/;
    if (!regex.test(username)) {
        return false;
    }

    return true;
}

//This Converts date time to 22 May, 2025
function formatDate(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        console.error('Invalid date format:', dateString);
        return 'Invalid date';
    }

    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    return `${day} ${month}, ${year}`;
}

//This Converts date time to dd/mm/yyyy
function formatDate2(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

//This Converts date time to Nov 2024
function formatDate3(dateString) {
    const date = new Date(dateString);
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return `${month} ${year}`;
}

// Custom arrow components for Slick Slider Used in Services Page
//Start
const PreviousArrow = (props) => {
    const { className, onClick } = props;
    return (
        <button className={`${className} service custom-prev`} onClick={onClick}>
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" >
                <circle cx="12.0741" cy="12.2356" r="12.0741" fill="#D6D7DA" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.8303 11.9709L12.7148 7.8241L13.7514 8.79506L10.3523 12.4236L13.9809 15.8227L13.0099 16.8592L8.86308 12.9747C8.72566 12.8459 8.645 12.6678 8.63885 12.4796C8.63271 12.2914 8.70157 12.1084 8.8303 11.9709Z" fill="white" />
            </svg>
            {/* Left Arrow Unicode */}
        </button>
    );
};

const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
        <button className={`${className} service custom-next`} onClick={onClick}>
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" >
                <circle cx="12.3241" cy="12.3146" r="12.0741" transform="rotate(178.847 12.3241 12.3146)" fill="#272D3B" fill-opacity="0.19" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.571 12.514L11.7707 16.7381L10.7149 15.7882L14.0402 12.092L10.344 8.76665L11.2939 7.71079L15.5181 11.5111C15.6581 11.6371 15.7423 11.8135 15.7522 12.0016C15.7621 12.1896 15.697 12.3739 15.571 12.514Z" fill="white" />
            </svg>
        </button>
    );
};
//Ends

export { isTokenValid, isValidUsername, formatDate, formatDate2, formatDate3, decrypt, PreviousArrow, NextArrow };