import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAllServicesCats } from '../../../services/Apis';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TabService = () => {
  const [activeTab, setActiveTab] = useState(null);
  const [loadingTabs, setLoadingTabs] = useState(true);
  const [tabs, setTabs] = useState([]);

  const fetchAllCategories = async () => {
    try {
      const res = await getAllServicesCats();
      if (res.status === 200) {
        setTabs(res.data);
      } else {
        console.log('Error in fetching categories.');
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    } finally {
      setLoadingTabs(false);
    }
  };

  useEffect(() => {
    fetchAllCategories();
  }, []);

  const handleMouseEnter = (tabId) => {
    setActiveTab(tabId);
  };

  const handleMouseLeave = () => {
    setActiveTab(null);
  };

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 700);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 700);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    className: "slider variable-width",
    variableWidth: true,
    initialSlide: -1,
    swipeToSlide: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: true, // Enables variable width to handle the last slide better
        },
      },
    ],
  };

  return (
    <div className="service horizontal-tabs">
      <div className="tab-buttons" onMouseLeave={handleMouseLeave}>
        {loadingTabs ? (
          <div>Loading Categories.....</div>
        ) : (
          <>
            {isSmallScreen ? (
              <Slider {...settings}>
                {tabs.length > 0 ? (
                  tabs.map((tab, index) => (
                    <div key={index++} onMouseEnter={() => handleMouseEnter(tab._id)} className="tab-item">
                      <Link to={`/service/category/${tab.main_cat}`}>
                        <div className='tab-btn'>
                          {tab.main_cat_title}
                        </div>
                      </Link>
                    </div>
                  ))
                ) : (
                  <div>--- No Tags to Show ---</div>
                )}
              </Slider>
            ) : (<>
              {tabs.length > 0 ? (
                tabs.map((tab, index) => (
                  <div key={index++} onMouseEnter={() => handleMouseEnter(tab._id)} className="tab-item">
                    <Link to={`/service/category/${tab.main_cat}`}>
                      <div className='tab-btn'>
                        {tab.main_cat_title}
                      </div>
                    </Link>
                  </div>
                ))
              ) : (
                <div>--- No Tags to Show ---</div>
              )}
            </>)}
          </>
        )}
      </div>

      <div className="tab-content-wrapper" onMouseLeave={handleMouseLeave}>
        {tabs.map((tab) => (
          // Check if the category has subcategories before rendering
          tab.subcategories && tab.subcategories.length > 0 ? (
            <div
              key={tab._id}
              onMouseEnter={() => handleMouseEnter(tab._id)}
              className={`tab-content ${activeTab === tab._id ? "active" : ""}`}
            >
              {activeTab === tab._id && (
                <>
                  {tab.subcategories.map((subcat) => (
                    <Link
                      to={`/service/category/${tab.main_cat}/${subcat.sub_cat}`}
                      key={subcat._id}
                      className="subcategory-link"
                    >
                      {subcat.sub_cat_title}
                    </Link>
                  ))}
                </>
              )}
            </div>
          ) : null // Render nothing if there are no subcategories
        ))}
      </div>

    </div>
  );
};

export default TabService;