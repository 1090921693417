import React, { useEffect, useState, useContext } from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import HomeSidebar from "../home/large-view/HomeSidebar";
import HeaderTop from "../../components/header/large-screen/HeaderTop";
import { commonSidebar } from "../../components/context/ContextProvider";
import SmallHeader from "../../components/header/small-screen/SmallHeader";
import SmallFooter from "../../components/footer/SmallFooter";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { addService, getServicesMainCats, getSubCatsByMainCatId } from "../../../services/Apis";
import { useNavigate } from "react-router-dom";
import { isTokenValid } from "../../components/functions/OtherFunctions";

const ServicePost = () => {

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 700);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 700);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        if (!valid) {
          navigate("/");
        }
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();
  }, []);

  const { showMenu, setShowMenu } = useContext(commonSidebar);

  // Dropdown states and methods
  const [isMainDropdownOpen, setIsMainDropdownOpen] = useState(false);
  const toggleMainDropdown = () => setIsMainDropdownOpen(!isMainDropdownOpen);
  const [selectMainCat, setSelectMainCat] = useState("Select Main Category");
  const [isSubDropdownOpen, setIsSubDropdownOpen] = useState(false);
  const toggleSubDropdown = () => setIsSubDropdownOpen(!isSubDropdownOpen);
  const [selectSubCat, setSelectSubCat] = useState("Select Sub Category");

  const [title, setTitle] = useState("");
  const [mainCatId, setMainCatId] = useState("");
  const [subCatId, setSubCatId] = useState("");
  const [servicePrice, setServicePrice] = useState(0);
  const [description, setDescription] = useState("");
  const user_id = sessionStorage.getItem("user_id");

  const [showImg, setShowImg] = useState(null);
  const [serviceImg, setServiceImg] = useState(null);

  const [mainCats, setMainCats] = useState([]);
  const [subCats, setSubCats] = useState([]);

  // Fetch main categories
  const fetchMainCategory = async () => {
    const response = await getServicesMainCats();
    if (response.status === 200) {
      setMainCats(response.data);
    } else {
      console.log("Error in Fetching Main Categories.");
    }
  };

  // Fetch subcategories based on the selected main category
  const fetchSubCategory = async (mainCategoryId) => {
    const response = await getSubCatsByMainCatId(mainCategoryId);
    if (response.status === 200) {
      setSubCats(response.data);
    } else {
      console.log("Error in Fetching Sub Categories.");
    }
  };

  useEffect(() => {
    fetchMainCategory();
  }, []);

  // Update subcategories when main category changes
  useEffect(() => {
    if (mainCatId) {
      fetchSubCategory(mainCatId);
      setSubCatId(""); // Reset subcategory selection on main category change
    }
  }, [mainCatId]);

  const selectImg = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (allowedFileTypes.includes(selectedFile.type)) {
        setShowImg(URL.createObjectURL(selectedFile));
        setServiceImg(selectedFile);
      } else {
        alert("Only png, jpg, jpeg Images are Allowed");
      }
    }
  };

  const handleCancel = () => {
    setServiceImg(null);
    setShowImg(null)
    setSelectMainCat("Select Main Category");
    setSelectSubCat("Select Main Category")
    setMainCatId("");
    setSubCatId("");
    setTitle("");
    setDescription("");
    setServicePrice("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("service_img", serviceImg);
    formData.append("title", title);
    formData.append("main_cat_id", mainCatId);
    formData.append("sub_cat_id", subCatId);
    formData.append("service_price", servicePrice);
    formData.append("description", description);
    formData.append("user_id", user_id);
    formData.append("status", "active");

    if (!serviceImg) {
      alert("Please Select Image");
    } else {
      const config = {
        "Content-Type": "multipart/form-data"
      };
      const response = await addService(formData, config);
      if (response.status === 200) {
        handleCancel();
        alert("Service Added Successfully");
        navigate("/admin/view-services");
      } else {
        alert("Sorry !! Service submission Unsuccessful");
      }
    }
  };

  return (
    <>
      {isSmallScreen ? <SmallHeader /> : <HeaderTop />}

      {/* <HomeSidebar showMenu={showMenu} checkAndNavigate={checkAndNavigate} /> */}
      <HomeSidebar showMenu={showMenu} />
      <div
        className="service-form-container"
        style={showMenu ? { filter: "blur(2px)" } : {}}
      >
        <h2 className="form-title">Create the service</h2>
        <div className="service-form">
          {/* Image Upload Section */}
          <div className="upload-container">
            <label htmlFor="imageUpload" className="upload-label">
              {showImg ? (
                <img
                  src={showImg}
                  alt="Upload Preview"
                  className="upload-preview"
                />
              ) : (
                <div className="upload-placeholder">
                  <FaCloudUploadAlt size={50} />
                  <span>Upload Image</span>
                </div>
              )}
            </label>
            <input
              id="imageUpload"
              type="file"
              accept="image/*"
              onChange={selectImg}
              className="upload-input"
            />
          </div>

          {/* Form Fields */}
          <div className="form-fields">
            {/* Main Category Dropdown */}
            <div className="dropdown-container">
              <button className="menu_btn_setting" onClick={toggleMainDropdown}>
                {selectMainCat}
              </button>
              {isMainDropdownOpen && (
                <ul className="dropdown-menu show">
                  {mainCats.map((cat, index) => (
                    <li key={index} onClick={() => { setMainCatId(cat._id); setSelectMainCat(cat.main_cat_title); setIsMainDropdownOpen(false); }} className="dropdown-item" >
                      {cat.main_cat_title}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {/* Sub Category Dropdown */}
            <div className="dropdown-container">
              <button className="menu_btn_setting" onClick={toggleSubDropdown}>
                {mainCatId ? selectSubCat : "Select Main Catgeory to View Sub Categories"}
              </button>
              {isSubDropdownOpen && mainCatId && (
                <ul className="dropdown-menu show">
                  {subCats.map((cat, index) => (
                    <li key={index} onClick={() => { setSubCatId(cat._id); setSelectSubCat(cat.sub_cat_title); setIsSubDropdownOpen(false); }} className="dropdown-item" >
                      {cat.sub_cat_title}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {/* Title Input */}
            <label htmlFor="Title">
              Title <span style={{ color: "red", fontSize: "15px" }}>*</span>
            </label>
            <input type="text" className="form-input" value={title} onChange={(e) => setTitle(e.target.value)} />

            {/* Price Input */}
            <label htmlFor="Title">
              Price <span style={{ color: "red", fontSize: "15px" }}>*</span>
            </label>
            <input type="number" className="form-input" value={servicePrice} onChange={(e) => setServicePrice(e.target.value)} />

            {/* Description Textarea */}
            <label class="form-label" style={{ marginTop: "10px" }}>
              Add your Job Description{" "}
              <span style={{ color: "red", fontSize: "15px" }}>*</span>
            </label>
            <ReactQuill theme="snow" value={description} onChange={setDescription} style={{ borderRadius: "5px 5px 0 0" }} />

            {/* Buttons */}
            <div className="button-group">
              <button className="cancel-button" onClick={handleCancel}>
                Cancel
              </button>
              <button className="submit-button" onClick={handleSubmit}>
                Post Service
              </button>
            </div>

          </div>
        </div>
      </div>
      {isSmallScreen ? <SmallFooter /> : ""}
    </>
  );
};

export default ServicePost;
