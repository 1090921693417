import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LuLayoutDashboard } from "react-icons/lu";
import { BsPerson, BsPlusCircle, BsListUl, BsPostcardHeart } from "react-icons/bs";
import { FaRegBell } from "react-icons/fa";
import { IoChatbubblesOutline, IoMailOutline } from "react-icons/io5";
import { PiSuitcaseSimple } from "react-icons/pi";
import { RiAdminLine } from "react-icons/ri";
import { MdOutlineSettingsApplications } from "react-icons/md";
import dark_logo from '../../assets/images/logo/dark_logo.png';
import light_logo from '../../assets/images/logo/light_logo.jpg';
import { isSideNavOpen, pageTheme } from '../context/ContextProvider';
import { GrUserAdmin } from "react-icons/gr";
import { getSingleAdmin, getSingleAdminAccess, getSingleUser } from '../../../services/Apis';
import { LiaHandsHelpingSolid } from "react-icons/lia";
import './common.css';

const LeftNav = () => {
    const { theme, setTheme } = useContext(pageTheme);

    const [userType, setUserType] = useState('');
    const admin_id = sessionStorage.getItem("admin_id");

    const singleAdminData = async () => {
        const response = await getSingleAdmin(admin_id);
        if (response.status === 200) {
            setUserType(response.data.user_type);
        }
        else {
            console.log("Error in Fetching Admin Details.");
        }
    }

    const [adminAccess, setAdminAccess] = useState({});

    const singleAdminAccess = async()=>{
        const response = await getSingleAdminAccess(admin_id);
        if(response.status===200){
          setAdminAccess(response.data);
        }
        else{
          console.log("Error in Fetching Admin Details.");
        }
      }

    useEffect(()=>{
        singleAdminData();
        if(userType === "minor_admin"){
            singleAdminAccess();
        }
    })

    const {sideNav, setSideNav} = useContext(isSideNavOpen);
    
    return (
        <>
            <nav id="sidebar"  className={sideNav?('active'):('sidebar-on')}>
                <div className="sidebar-header">
                    <Link to="/admin">{theme === "dark-th" ? (<img src={dark_logo} alt="logo" width="100%" className='logo'/>) : (<img src={light_logo} alt="logo" width="100%" className='logo'/>)}</Link>
                    <button onClick={()=>setSideNav(!sideNav)} style={{"display":"none"}}><i className="fa-solid fa-xmark"></i></button>
                </div>
                <ul className="list-unstyled components">
                    {/* <------------------------------------  Dashboard  ------------------------------------> */}
                    <li>
                        <Link onClick={()=>setSideNav(!sideNav)} to="/admin"><LuLayoutDashboard className='sidebar-icon' />&nbsp;Dashboard</Link>
                    </li>
                    {/* <------------------------------------  Admins ------------------------------------>*/}
                    <li>
                        {userType === "super_admin" || adminAccess.add_admin || adminAccess.view_admins || adminAccess.edit_admin || adminAccess.delete_admin || adminAccess.change_admin_access?(<a href="#admins" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                            <RiAdminLine className='sidebar-icon' />&nbsp;Admins
                        </a>):('')}
                        <ul className="collapse list-unstyled" id="admins">
                            <li>
                                {userType === "super_admin" || adminAccess.add_admin?(<Link onClick={()=>setSideNav(!sideNav)} to='/admin/add-admin'><BsPlusCircle className='sidebar-icon' />&nbsp;Add Admin</Link>):('')}
                            </li>
                            <li>
                                {userType === "super_admin" || adminAccess.view_admins || adminAccess.edit_admin || adminAccess.delete_admin?(<Link onClick={()=>setSideNav(!sideNav)} to='/admin/view-admins'><BsListUl className='sidebar-icon' />&nbsp;View Admins</Link>):('')}
                            </li>
                            <li>
                                {userType === "super_admin" || adminAccess.change_admin_access?(<Link onClick={()=>setSideNav(!sideNav)} to='/admin/minor-admins'><GrUserAdmin className='sidebar-icon' />&nbsp;Admin Access</Link>):('')}
                            </li>
                        </ul>
                    </li>
                    {/* <------------------------------------  Users ------------------------------------>*/}
                    <li>
                        {userType === "super_admin" || adminAccess.add_user || adminAccess.view_users || adminAccess.edit_user || adminAccess.delete_user?(<a href="#users" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">
                            <BsPerson className='sidebar-icon' />&nbsp;Users
                        </a>):('')}
                        <ul className="collapse list-unstyled" id="users">
                            <li>
                                {userType === "super_admin" || adminAccess.add_user?(<Link  to='/admin/add-user' onClick={()=>setSideNav(!sideNav)}><BsPlusCircle className='sidebar-icon' />&nbsp;Add User</Link>):('')}
                            </li>
                            <li>
                                {userType === "super_admin" || adminAccess.view_users || adminAccess.edit_user || adminAccess.delete_user?(<Link onClick={()=>setSideNav(!sideNav)} to='/admin/view-user'><BsListUl className='sidebar-icon' />&nbsp;View User</Link>):('')}
                            </li>
                        </ul>
                    </li>
                    {/* <------------------------------------  Posts ------------------------------------>*/}
                    <li>
                        {userType === "super_admin" || adminAccess.add_post || adminAccess.view_posts || adminAccess.edit_post || adminAccess.delete_post || adminAccess.change_post_settings?(<a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><BsPostcardHeart className='sidebar-icon' />
                            &nbsp;Posts</a>):('')}
                        <ul className="collapse list-unstyled" id="homeSubmenu">
                            <li>
                                {userType === "super_admin" || adminAccess.add_post?(<Link onClick={()=>setSideNav(!sideNav)} to='/admin/add-post'><BsPlusCircle className='sidebar-icon' />&nbsp;Add Post</Link>):('')}
                            </li>
                            <li>
                                {userType === "super_admin" || adminAccess.view_posts || adminAccess.edit_post || adminAccess.delete_post?(<Link onClick={()=>setSideNav(!sideNav)} to='/admin/view-posts'><BsListUl className='sidebar-icon' />&nbsp;View Posts</Link>):('')}
                            </li>
                            <li>
                                {userType === "super_admin" || adminAccess.change_post_settings?(<Link onClick={()=>setSideNav(!sideNav)} to='/admin/post-settings'><MdOutlineSettingsApplications className='sidebar-icon' />&nbsp;Post Settings</Link>):('')}
                            </li>
                        </ul>
                    </li>
                    {/* <------------------------------------  Jobs ------------------------------------>*/}
                    <li>
                        {userType === "super_admin" || adminAccess.add_job || adminAccess.view_jobs || adminAccess.edit_job || adminAccess.delete_job?(<a href="#jobs" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><PiSuitcaseSimple className='sidebar-icon' />
                            &nbsp;Jobs</a>):('')}
                        <ul className="collapse list-unstyled" id="jobs">
                            <li>
                                {userType === "super_admin" || adminAccess.add_job?(<Link onClick={()=>setSideNav(!sideNav)} to='/admin/add-job'><BsPlusCircle className='sidebar-icon' />&nbsp;Add Jobs</Link>):('')}
                            </li>
                            <li>
                                {userType === "super_admin" || adminAccess.view_jobs || adminAccess.edit_job || adminAccess.delete_job ?(<Link onClick={()=>setSideNav(!sideNav)} to='/admin/view-jobs'><BsListUl className='sidebar-icon' />&nbsp;View Jobs</Link>):('')}
                            </li>
                        </ul>
                    </li>
                    {/* <------------------------------------  Services ------------------------------------>*/}
                    <li>
                        {userType === "super_admin" || adminAccess.add_job || adminAccess.view_jobs || adminAccess.edit_job || adminAccess.delete_job?(<a href="#services" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><LiaHandsHelpingSolid className='sidebar-icon' />
                            &nbsp;Services</a>):('')}
                        <ul className="collapse list-unstyled" id="services">
                            <li>
                                {userType === "super_admin" || adminAccess.add_job?(<Link onClick={()=>setSideNav(!sideNav)} to='/admin/add-service'><BsPlusCircle className='sidebar-icon' />&nbsp;Add Services</Link>):('')}
                            </li>
                            <li>
                                {userType === "super_admin" || adminAccess.view_jobs || adminAccess.edit_job || adminAccess.delete_job ?(<Link onClick={()=>setSideNav(!sideNav)} to='/admin/view-services'><BsListUl className='sidebar-icon' />&nbsp;View Services</Link>):('')}
                            </li>
                            <li>
                                {userType === "super_admin" || adminAccess.add_job?(<Link onClick={()=>setSideNav(!sideNav)} to='/admin/add-services-cats'><BsPlusCircle className='sidebar-icon' />&nbsp;Add Categories</Link>):('')}
                            </li>
                            <li>
                                {userType === "super_admin" || adminAccess.view_jobs || adminAccess.edit_job || adminAccess.delete_job ?(<Link onClick={()=>setSideNav(!sideNav)} to='/admin/view-services-main-cats'><BsListUl className='sidebar-icon' />&nbsp;View Main Categories</Link>):('')}
                            </li>
                            <li>
                                {userType === "super_admin" || adminAccess.view_jobs || adminAccess.edit_job || adminAccess.delete_job ?(<Link onClick={()=>setSideNav(!sideNav)} to='/admin/view-services-sub-cats'><BsListUl className='sidebar-icon' />&nbsp;View Sub Categories</Link>):('')}
                            </li>
                        </ul>
                    </li>
                    {/* <------------------------------------  Notifications ------------------------------------>*/}
                    <li>
                        {userType === "super_admin" || adminAccess.send_notification || adminAccess.view_send_notifications? (<a href="#notifications" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><FaRegBell className='sidebar-icon' />
                            &nbsp;Notifications</a>):('')}
                        <ul className="collapse list-unstyled" id="notifications">
                            <li>
                                {userType === "super_admin" || adminAccess.send_notification?(<Link onClick={()=>setSideNav(!sideNav)} to='/admin/send-notification'><BsPlusCircle className='sidebar-icon' />&nbsp;Send Notifications</Link>):('')}
                            </li>
                            <li>
                                {userType === "super_admin" || adminAccess.view_send_notifications?(<Link onClick={()=>setSideNav(!sideNav)} to='/admin/view-notifications'><BsListUl className='sidebar-icon' />&nbsp;View All Notifications</Link>):('')}
                            </li>
                        </ul>
                    </li>
                    {/* <------------------------------------  Messages ------------------------------------>*/}
                    <li>
                        {userType === "super_admin" || adminAccess.send_message || adminAccess.view_user_chats?(<a href="#messages" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><IoChatbubblesOutline className='sidebar-icon' />
                            &nbsp;Messages</a>):('')}
                        <ul className="collapse list-unstyled" id="messages">
                            <li>
                                {userType === "super_admin" || adminAccess.send_message?(<Link onClick={()=>setSideNav(!sideNav)} to='/admin/send-message'><BsPlusCircle className='sidebar-icon' />&nbsp;Send Messages</Link>):('')}
                            </li>
                            <li>
                                {userType === "super_admin" || adminAccess.view_user_chats?(<Link onClick={()=>setSideNav(!sideNav)} to='/admin/view-chats'><BsListUl className='sidebar-icon' />&nbsp;View Chats</Link>):('')}
                            </li>
                        </ul>
                    </li>
                    {/* <------------------------------------ Mail ------------------------------------>*/}
                    <li>
                        {userType === "super_admin" || adminAccess.send_mail || adminAccess.view_send_mails?(<a href="#mails" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><IoMailOutline className='sidebar-icon' />
                            &nbsp;Mails</a>):('')}
                        <ul className="collapse list-unstyled" id="mails">
                            <li>
                                {userType === "super_admin" || adminAccess.send_mail?(<Link onClick={()=>setSideNav(!sideNav)} to='/admin/send-mail'><BsPlusCircle className='sidebar-icon' />&nbsp;Send Mail</Link>):('')}
                            </li>
                            <li>
                                {userType === "super_admin" || adminAccess.view_send_mails?(<Link onClick={()=>setSideNav(!sideNav)} to='/admin/view-mails'><BsListUl className='sidebar-icon' />&nbsp;View Inbox</Link>):('')}
                            </li>
                        </ul>
                    </li>
                </ul>


            </nav>
        </>
    )
}

export default LeftNav;