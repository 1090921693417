import React, { useState, useEffect, useRef,useContext } from 'react';
import HeaderTop from '../../../components/header/large-screen/HeaderTop';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { commonSidebar } from '../../../components/context/ContextProvider';
import { NavLink, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { addJob } from '../../../../services/Apis';
import SmallFooter from '../../../components/footer/SmallFooter';
import LocationSuggestion from '../../../components/functions/LocationSuggestion';
import postJobImg from "../../../../../public/assets/images/post-job-img.png"
import SmallHeader from '../../../components/header/small-screen/SmallHeader';
import HomeSidebar from '../../home/large-view/HomeSidebar';
const PostEditJob = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 700);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 700);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const checkAndNavigate = (navigateTo) => {
    if (isValid) { navigate(navigateTo); }
    else {
      // alert("Please Login First");
      // navigate("/login");
      toast.error('Please Login First', {
        onClose: () => navigate('/login')
      });
    }
  }
  const navigate = useNavigate();
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        if(!valid){
          navigate("/");
        }
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();
  }, []);

  const username = sessionStorage.getItem("user_username");
  const user_id = sessionStorage.getItem("user_id");

  const [inputdata, setInputData] = useState({
    title: "",
    workplace_type: "",
    designer_type: "",
    employment_type: "",
    apply_link: "",
    company_name: "",
    website: "",
    status: "active"
  })
  const [description, setDescription] = useState("");
  const [showImg, setShowImg] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [location, setLocation] = useState('');
  const [query, setQuery] = useState('');

  const LogoChange = (e) => {
    setShowImg(URL.createObjectURL(e.target.files[0]));
    setCompanyLogo(e.target.files[0]);
    console.log("showimgssssss" + showImg);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputdata, [name]: value });
  }

  const setUserLocation = (e) => {
    setLocation(e.target.value);
    setQuery(e.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    setLocation(suggestion);
    setQuery('');
  };

  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const submitJob = async (e) => {
    e.preventDefault();
    const { title, workplace_type, designer_type, employment_type, apply_link, company_name, website, status, } = inputdata;
    if (title === "") { toast.error("Job Title is Required") }
    else if (description === "") { toast.error("Description is Required") }
    else if (location === "") { toast.error("Location is Required") }
    else if (workplace_type === "") { toast.error("Workplace Type is Required") }
    else if (designer_type === "") { toast.error("Designer Type is Required") }
    else if (employment_type === "") { toast.error("Employment Type is Required") }
    else if (apply_link === "") { toast.error("Job Apply Link is Required") }
    else if (company_name === "") { toast.error("Company Name is Required") }
    else if (companyLogo === "") { toast.error("Company Logo is Required") }
    else if (status === "") { toast.error("Status is Required") }
    else {
      const data = new FormData();
      data.append("username", username)
      data.append("user_id", user_id);
      data.append("title", title)
      data.append("description", description)
      data.append("location", location)
      data.append("workplace_type", workplace_type)
      data.append("designer_type", designer_type)
      data.append("employment_type", employment_type)
      data.append("apply_link", apply_link)
      data.append("company_name", company_name)
      data.append("website", website)
      data.append("company_logo", companyLogo)
      data.append("status", status)

      const config = {
        "Content-Type": "multipart/form-data"
      }

      const response = await addJob(data, config);
      if (response.status === 200) {
        setInputData({
          ...inputdata,
          title: "",
          location: "",
          workplace_type: "",
          designer_type: "",
          employment_type: "",
          apply_link: "",
          company_name: "",
          website: "",
        });
        setDescription("");
        setCompanyLogo("");
        // toast.success("Registration Succesfull");
        navigate("/jobs");
      }
      else {
        toast.error("Sorry Registration Unsuccessfull");
      }
    };
  }
  const { showMenu, setShowMenu } = useContext(commonSidebar);
  return (
    <>{isSmallScreen ? ( <SmallHeader />) : (<HeaderTop /> )}
     <HomeSidebar showMenu={showMenu} checkAndNavigate={checkAndNavigate}/>
      <div className="container-fluid" id="post-job"  style={showMenu ? { filter: "blur(2px)" } : {}}>
        <div class="banner d-flex">
          <div class="banner-text">
            <h1 style={{  "fontFamily": "auto" }}>Edit your Job</h1>
            <p style={{ "font-size": "20px" }}>The #1 job board for hiring designers and creative professionals.</p>
          </div>
          <div className='imgRight'>
            <img src={postJobImg} alt="" />
          </div>
        </div>
        <div class="content-wrappers">
          <div class="content-box">
            <div class="row d-flex justify-content-center">
              <div class="job-form-box col-lg-7 col-sm-12">
                <span class="job-form-head">Edit</span>
                <div class="form-inner">
                  <form onSubmit={submitJob}>
                    <label class="form-label mt-5">Job title <span style={{ "color": "red", "fontSize": "15px" }}>*</span></label>
                    <input type="text" class="form-control" placeholder="e.g; Senior Web Designer" name="title" onChange={handleChange} required/>

                    <label class="form-label mt-5">Add your Job Description <span style={{ "color": "red", "fontSize": "15px" }}>*</span></label>
                    <ReactQuill theme="snow" value={description} onChange={setDescription} />

                    <div className="job-locate-div">
                      <label class="form-label mt-5">Job Location <span style={{ "color": "red", "fontSize": "15px" }}>*</span></label>
                      <input type="text" class="form-control" placeholder="e.g; New York City or San Francisco" name="location" value={location} onChange={setUserLocation} autoComplete='off' required />
                      {query ? (<LocationSuggestion query={query} limit={10} onSuggestionClick={handleSuggestionClick} />) : ('')}
                    </div>

                    <label class="form-label mt-5">Workplace type <span style={{ "color": "red", "fontSize": "15px" }}>*</span></label>
                    <select class="form-select form-control" name="workplace_type" onChange={handleChange} required>
                      <option selected>----Select Workplace Type----</option>
                      <option value="remote">Remote</option>
                      <option value="on_site">On-Site</option>
                      <option value="hybrid">Hybrid</option>
                    </select>

                    <label class="form-label mt-5">What type of designer are you looking for? <span style={{ "color": "red", "fontSize": "15px" }}>*</span></label>
                    <select class="form-select form-control" name="designer_type" onChange={handleChange} required>
                      <option selected>---- Select Designer Type ----</option>
                      <option value="animation">Animation</option>
                      <option value="graphic_designer">Brand / Graphic Designer</option>
                      <option value="illustration">Illustration</option>
                      <option value="leadership">Leadership</option>
                      <option value="mobile_design">Mobile Design</option>
                      <option value="visual_design">UI / Visual Design</option>
                      <option value="product_design">Product Design</option>
                      <option value="ux_design">UX Design / Research</option>
                      <option value="web_design">Web Design</option>
                    </select>

                    <label class="form-label mt-5">Employment type <span style={{ "color": "red", "fontSize": "15px" }}>*</span></label>
                    <select class="form-select form-control" name="employment_type" onChange={handleChange} required>
                      <option selected>---- Select Employment Type ----</option>
                      <option value="fulltime">Full-time employee</option>
                      <option value="freelance">Freelance / Contract hire</option>
                    </select>

                    <label class="form-label mt-5">Where can people apply? <span style={{ "color": "red", "fontSize": "15px" }}>*</span></label>
                    <input type="text" class="form-control" placeholder="e.g; https://abdcompany.com/jobs-apply" name="apply_link" onChange={handleChange}  required/>

                    <div class="company-info">
                      <span style={{ "textTransform": "uppercase", "fontSize": "14px", "fontWeight": "600", "fontFamily": "sans-serif" }}>Company Information</span><br />
                      <label class="form-label mt-4">Whats your company name? <span style={{ "color": "red", "fontSize": "15px" }}>*</span></label>
                      <input type="text" class="form-control" name="company_name" onChange={handleChange}  required/>
                      <label class="form-label mt-4">Your company logo <span style={{ "color": "red", "fontSize": "15px" }}>*</span></label>
                      <div class="d-flex">
                        <input type="file" ref={fileInputRef} style={{ "display": "none" }} onChange={LogoChange} />
                        <button onClick={handleButtonClick} type="button">Choose image</button>
                        <img src={showImg} id="company-logo" className={showImg ? 'd-flex' : 'd-none'} />
                      </div>
                      <label class="form-label mt-4">Your Company Website <span style={{ "color": "red", "fontSize": "15px" }}>*</span></label>
                      <input type="text" class="form-control" placeholder="https://domain.com" name="website" onChange={handleChange}  required/>
                    </div>

                    <div class="job-submit-btn">
                      <button style={{ "marginRight": "15px" }} className='btn btn-danger'><NavLink to="/jobs">Cancel</NavLink></button>
                      <button type='submit' className='post-job-btn'>Post Job</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>


          </div>
        </div>
        <ToastContainer position="top-center" autoClose={1000}/>
      </div>
      {isSmallScreen ? (<SmallFooter />) : (" ")}
    </>
  )
}

export default PostEditJob