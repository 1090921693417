import React, { useState, useEffect, useContext } from 'react';
import HeaderTop from '../../../components/header/large-screen/HeaderTop';
import Footer from '../../../components/footer/Footer';
import './editProfile.css';
import { NavLink, useNavigate } from 'react-router-dom';
import GeneralSet from './settings/GeneralSet';
import EditP from './settings/EditP';
import Pwd from './settings/Pwd';
import Social from './settings/Social';
import { getSingleUser } from '../../../../services/Apis';
import { BASE_URL } from '../../../../services/helper';
import ExportUserData from './settings/ExportUserData';
import SmallFooter from '../../../components/footer/SmallFooter';
import { isTokenValid } from '../../../components/functions/OtherFunctions';
import Sidebar from '../../../components/common/Sidebar';
import HomeSidebar from '../../home/large-view/HomeSidebar';
import { headerMenu } from '../../../components/context/ContextProvider';
import SmallHeader from '../../../components/header/small-screen/SmallHeader';

const EditProfile = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 700);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeMenuText, setActiveMenuText] = useState("General"); // Default to "General"

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 700);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        if (!valid) {
          navigate("/");
        }
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();
  }, [navigate]);

  const [sett, setSett] = useState("general");

  const switchSettings = (setting, menuText) => {
    setSett(setting);
    setActiveMenuText(menuText); // Update the active menu text
    setIsDropdownOpen(false); // Close the dropdown after selecting an option
  }

  const username = sessionStorage.getItem("user_username");
  const [userData, setUserData] = useState({});

  const userProfileGet = async (username) => {
    const res = await getSingleUser(username);
    if (res.status === 200) {
      setUserData(res.data);
    } else {
      console.log("Error in Fetching User Details.");
    }
  }

  useEffect(() => {
    userProfileGet(username);
  }, [username]);
  const checkAndNavigate = (navigateTo) => {
    if (isValid) { navigate(navigateTo); }
    else {
      // alert("Please Login First");
      // navigate("/login");
      toast.error('Please Login First', {
        onClose: () => navigate('/login')
      });
    }
  }
  const { showMenu, setShowMenu } = useContext(headerMenu);

  return (
    <>
      <SmallHeader />
      {!isSmallScreen && <HeaderTop />}
      <HomeSidebar showMenu={showMenu} checkAndNavigate={checkAndNavigate} />
      <div className="container" id="edit-profile-page" style={{ "marginTop": "120px" }}>
        <div className="row">
          <div className="edit-head d-flex justify-content-center">
            <div className="col-lg-10 col-sm-12 edit-head-wrap">
              <div className="row d-flex justify-content-center">
                <div className="col-2 profile-img d-flex justify-content-end">
                  <NavLink to={`/${userData.username}`}>
                    <img src={`${BASE_URL}/uploads/admins-users-img/${userData.user_img}`} width="48px" height="48px" className='rounded-circle' alt="User" />
                  </NavLink>
                </div>
                <div className="col-10">
                  <div className='user-title'>{userData.fname + " " + userData.lname} / {activeMenuText}</div>
                  <div className='settings-title'>Update your username and manage your account</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ "marginTop": "35px" }}>
          <div className="edit-bottom d-flex justify-content-center">
            <div className="col-10 edit-profile-nav">
              <div className="flex_remove justify-content-center">
                <div className="col-md-3 edit_slider " id="edit-sidebar">
                  {isSmallScreen ? (
                    <div>
                      <button
                        className="menu_btn_setting"
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                      >
                        {activeMenuText} {/* Display the active menu item text */}
                      </button>
                      {isDropdownOpen && (
                        <ul className="dropdown-menu show">
                          <li className={sett === "general" ? 'active' : ''} onClick={() => switchSettings("general", "General")}><NavLink>General</NavLink></li>
                          <li className={sett === "editP" ? 'active' : ''} onClick={() => switchSettings("editP", "Edit Profile")}><NavLink>Edit Profile</NavLink></li>
                          <li className={sett === "pwd" ? 'active' : ''} onClick={() => switchSettings("pwd", "Password")}><NavLink>Password</NavLink></li>
                          <li className={sett === "social" ? 'active' : ''} onClick={() => switchSettings("social", "Social Profiles")}><NavLink>Social Profiles</NavLink></li>
                          <li className={sett === "dataExp" ? 'active' : ''} onClick={() => switchSettings("dataExp", "Data Export")}><NavLink>Data Export</NavLink></li>

                          <li><NavLink className="text-danger" to="/confirm-delete-user">Delete Account</NavLink></li>
                        </ul>
                      )}
                    </div>
                  ) : (
                    <ul>
                      <li className={sett === "general" ? 'active' : ''} onClick={() => switchSettings("general", "General")}><NavLink>General</NavLink></li>
                      <li className={sett === "editP" ? 'active' : ''} onClick={() => switchSettings("editP", "Edit Profile")}><NavLink>Edit Profile</NavLink></li>
                      <li className={sett === "pwd" ? 'active' : ''} onClick={() => switchSettings("pwd", "Password")}><NavLink>Password</NavLink></li>
                      <li className={sett === "social" ? 'active' : ''} onClick={() => switchSettings("social", "Social Profiles")}><NavLink>Social Profiles</NavLink></li>
                      <li className={sett === "dataExp" ? 'active' : ''} onClick={() => switchSettings("dataExp", "Data Export")}><NavLink>Data Export</NavLink></li>

                      <li><NavLink className="text-danger" to="/confirm-delete-user">Delete Account</NavLink></li>
                    </ul>
                  )}
                </div>
                <div className="col-md-8 edit_slider">
                  {sett === "general" && <GeneralSet />}
                  {sett === "editP" && <EditP />}
                  {sett === "pwd" && <Pwd userData={userData} />}
                  {sett === "social" && <Social />}
                  {sett === "dataExp" && <ExportUserData />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isSmallScreen && <SmallFooter />}
    </>
  )
}

export default EditProfile;
