import React, { useState, useEffect, useContext } from "react";
import HeaderTop from "../../../components/header/large-screen/HeaderTop";
import "../jobs.css";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../../services/helper";
import job2_light from "../../../../../public/assets/images/job_img.png";
import { commonSidebar } from "../../../components/context/ContextProvider";
import { getFilteredJobs, getSingleJob } from "../../../../services/Apis";
import TimeAgoConverter from "../../../components/functions/TimeAgoConverter";
import { ToastContainer, toast } from "react-toastify";
import { isTokenValid } from "../../../components/functions/OtherFunctions";
import HomeSidebar from "../../home/large-view/HomeSidebar";

const LJobs = () => {

  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        setIsValid(valid);
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();
  }, []);

  const [allJobs, setAllJobs] = useState([]);
  const [search, setSearch] = useState("");
  const [employmentType, setEmploymentType] = useState([]);
  const [designerType, setDesignerType] = useState([]);
  const [location, setLocation] = useState("");
  const [workplaceType, setWorkplaceType] = useState("");

  const [activeJobId, setActiveJobId] = useState("");
  const [jobData, setJobData] = useState("");

  const fetchJobs = async () => {
    try {
      const res = await getFilteredJobs(search, designerType, workplaceType, location, employmentType);
      if (res.status === 200) {
        setAllJobs(res.data);
        setActiveJobId(res.data[0]._id)
      } else {
        console.log("Error in Fetching Jobs.");
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, [search, employmentType, designerType, workplaceType, location]);

  const fetchActiveJob = async () => {
    try {
      const res = await getSingleJob(activeJobId);
      if (res.status === 200) {
        setJobData(res.data.singleJob);
      } else {
        console.log("Error in Fetching Job Data.");
      }
    } catch (error) {
      console.error("Error fetching job data:", error);
    }
  };

  useEffect(() => {
    fetchActiveJob();
  }, [activeJobId]);

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    fetchJobs();
    toast.success("Filter Applied");
  };

  const checkAndNavigate = (navigateTo) => {
    if (isValid) { navigate(navigateTo); }
    else {
      // alert("Please Login First");
      // navigate("/login");
      toast.error('Please Login First', {
        onClose: () => navigate('/login')
      });
    }
  }
  const { showMenu, setShowMenu } = useContext(commonSidebar);

  const handleApplyJob = (link) => {
    if (isValid) {
      const newWindow = window.open('', '_blank');
      newWindow.location.href = `${link}`;
    }
    else {
      toast.error('Please Login First');
    }
  };

  return (
    <>
      <HeaderTop />
      <HomeSidebar showMenu={showMenu} checkAndNavigate={checkAndNavigate} />
      <div className="container-fluid" id="jobs-page">
        <div
          className="job-main row"
          style={showMenu ? { filter: "blur(2px)" } : {}}
        >
          <div class="col-lg-12" className="banner-wrapper">
            <div class="job-head text-left">
              <div className="d-flex justify-content-start">
                <h1 style={{ maxWidth: "950px" }}>
                  The premier job board <br /> for all types of careers.
                </h1>
              </div>
              <div className="d-flex justify-content-start" style={{ maxWidth: "70%" }}>
                <p style={{ maxWidth: "950px" }}>
                  Yoeavnu is the core of the design community and the premier
                  resource <br /> for discovering and connecting with designers
                  and job opportunities around the globe.
                </p>
              </div>
              <div className="container_search">
                <form action="" onSubmit={handleFilterSubmit}>

                  <div className="search-bar">
                    <div className="pl-10" style={{ paddingLeft: "10px" }}>
                      <svg width="20" height="20" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.438 14.4654H15.3991L15.0309 14.1103C16.3643 12.564 17.0971 10.5896 17.0955 8.54774C17.0955 6.85716 16.5942 5.20454 15.6549 3.79887C14.7157 2.39321 13.3807 1.29762 11.8188 0.650662C10.2569 0.0037036 8.53826 -0.16557 6.88016 0.164246C5.22206 0.494063 3.699 1.30816 2.50358 2.50358C1.30816 3.699 0.494063 5.22206 0.164246 6.88016C-0.16557 8.53826 0.0037036 10.2569 0.650662 11.8188C1.29762 13.3807 2.39321 14.7157 3.79887 15.6549C5.20454 16.5942 6.85716 17.0955 8.54774 17.0955C10.665 17.0955 12.6112 16.3196 14.1103 15.0309L14.4654 15.3991V16.438L21.0406 23L23 21.0406L16.438 14.4654ZM8.54774 14.4654C5.2733 14.4654 2.63008 11.8222 2.63008 8.54774C2.63008 5.2733 5.2733 2.63008 8.54774 2.63008C11.8222 2.63008 14.4654 5.2733 14.4654 8.54774C14.4654 11.8222 11.8222 14.4654 8.54774 14.4654Z" fill="#272D3B" />
                      </svg>
                      <input type="text" placeholder="Search by Company" onChange={(e) => setSearch(e.target.value)} />

                    </div>
                    <div className="location_input">
                      <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 17.04L7.631 16.329C8.347 15.5097 8.99133 14.73 9.564 13.99L10.037 13.366C12.0123 10.7053 13 8.59433 13 7.033C13 3.701 10.314 1 7 1C3.686 1 1 3.701 1 7.033C1 8.59433 1.98767 10.7057 3.963 13.367L4.436 13.991C5.25355 15.0383 6.10877 16.0546 7 17.04Z" stroke="#272D3B" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M7 9.5C8.38071 9.5 9.5 8.38071 9.5 7C9.5 5.61929 8.38071 4.5 7 4.5C5.61929 4.5 4.5 5.61929 4.5 7C4.5 8.38071 5.61929 9.5 7 9.5Z" stroke="#272D3B" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                      <input type="text" placeholder="Location" onChange={(e) => setLocation(e.target.value)} />
                    </div>
                    <button className="find-job" type="submit">Find Job</button>
                  </div>
                  <div className="filters">
                    <div className="filter">
                      <select>
                        <option value="date-post">Date Post</option>
                        <option value="date-post">24 hours ago</option>
                        <option value="date-post">1 week ago</option>
                        <option value="date-post">1 month ago</option>
                      </select>
                      <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0L5 5L10 0H0Z" fill="black" />
                      </svg>
                    </div>
                    <div className="filter">
                      <select name="designer_type" onChange={(e) => setDesignerType(e.target.value)} class=" form-control">
                        <option value="">Job Postion</option>
                        <option value="animation">Animation</option>
                        <option value="graphic_designer">Brand / Graphic Design</option>
                        <option value="illustration">Illustration</option>
                        <option value="leadership">Leadership</option>
                        <option value="mobile_design">Mobile Design</option>
                        <option value="visual_design">Visual Design</option>
                        <option value="ux_design">UX Design</option>
                        <option value="product_design">Product Design</option>
                        <option value="web_design">Web Design</option>
                      </select>
                      <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0L5 5L10 0H0Z" fill="black" />
                      </svg>
                    </div>
                    <div className="filter">
                      <select name="workplace_type" onChange={(e) => setWorkplaceType(e.target.value)} class="form-control">
                        <option value="">Location Type</option>
                        <option value="remote">Remote</option>
                        <option value="on-site">On-site</option>
                      </select>
                      <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0L5 5L10 0H0Z" fill="black" />
                      </svg>
                    </div>
                    <div className="filter">
                      <select onChange={(e) => setEmploymentType(e.target.value)} class="form-control">
                        <option value="">Job type</option>
                        <option value="fulltime">Full Time</option>
                        <option value="freelance">Freelance</option>
                      </select>
                      <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0L5 5L10 0H0Z" fill="black" />
                      </svg>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* <div class="job-post-btn text-center">
              <button type='button' onClick={checkUser}><i class="fa fa-plus"></i>Post a Job</button>
            </div> */}
            <div className="job-bg-img2"><img src={job2_light} /></div>
          </div>

          <div class="col-lg-12">
            <div class="view-job-main row">
              <div class="view-jobs">
                <h1 className="recent-jobs" style={{ marginTop: "20px " }}>
                  Recent Jobs
                </h1>

                <div className="job_tabs_container">
                  <div className="job_tabs">
                    {allJobs.map((job, index) => (
                      <button type="button" className={`tab-button ${activeJobId === job._id ? "active" : ""}`} onClick={() => setActiveJobId(job._id)} >
                        <div className="job_tab_left_content">
                          <div className="text_title">

                            <div className="d-flex gap-2">
                              <img src={`${BASE_URL}/uploads/jobs-img/${job.company_logo}`} alt="" width="30px" height="30px" style={{ "borderRadius": "50%" }} />
                              <h2>
                                {job.company_name}
                                <span>
                                  <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                    <path d="M11.1622 17.1489L18.0242 21.2905L16.2032 13.4847L22.2658 8.23269L14.2823 7.55537L11.1622 0.19368L8.04208 7.55537L0.0585938 8.23269L6.12116 13.4847L4.30017 21.2905L11.1622 17.1489Z" fill="black" />
                                  </svg>
                                </span>
                              </h2>
                            </div>

                            {/* <div>
                              <button>
                                <svg width="4" height="14" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                  <path d="M3.5 12C3.5 12.3978 3.34196 12.7794 3.06066 13.0607C2.77936 13.342 2.39782 13.5 2 13.5C1.60218 13.5 1.22064 13.342 0.93934 13.0607C0.658035 12.7794 0.5 12.3978 0.5 12C0.5 11.6022 0.658035 11.2206 0.93934 10.9393C1.22064 10.658 1.60218 10.5 2 10.5C2.39782 10.5 2.77936 10.658 3.06066 10.9393C3.34196 11.2206 3.5 11.6022 3.5 12ZM3.5 7C3.5 7.39782 3.34196 7.77936 3.06066 8.06066C2.77936 8.34196 2.39782 8.5 2 8.5C1.60218 8.5 1.22064 8.34196 0.93934 8.06066C0.658035 7.77936 0.5 7.39782 0.5 7C0.5 6.60218 0.658035 6.22064 0.93934 5.93934C1.22064 5.65804 1.60218 5.5 2 5.5C2.39782 5.5 2.77936 5.65804 3.06066 5.93934C3.34196 6.22064 3.5 6.60218 3.5 7ZM3.5 2C3.5 2.39782 3.34196 2.77936 3.06066 3.06066C2.77936 3.34196 2.39782 3.5 2 3.5C1.60218 3.5 1.22064 3.34196 0.93934 3.06066C0.658035 2.77936 0.5 2.39782 0.5 2C0.5 1.60218 0.658035 1.22064 0.93934 0.93934C1.22064 0.658035 1.60218 0.5 2 0.5C2.39782 0.5 2.77936 0.658035 3.06066 0.93934C3.34196 1.22064 3.5 1.60218 3.5 2Z" fill="black" />
                                </svg>
                              </button>
                            </div> */}

                          </div>
                          <div className="job_details">
                            <h2>
                              {job.title}
                              <span>
                                {job.workplace_type === "remote" ? "Remotely" :
                                  job.workplace_type === "on_site" ? "On-Site" :
                                    job.workplace_type === "hybrid" ? "Hybrid" :
                                      "Not Specified"}
                              </span>
                            </h2>

                            <p className="loction">
                              {" "}
                              <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 12.9993L5.97325 12.4674C6.51025 11.8544 6.9935 11.2712 7.423 10.7176L7.77775 10.2508C9.25925 8.26039 10 6.68119 10 5.51318C10 3.02057 7.9855 1 5.5 1C3.0145 1 1 3.02057 1 5.51318C1 6.68119 1.74075 8.26064 3.22225 10.2515L3.577 10.7183C4.19016 11.5018 4.83158 12.2621 5.5 12.9993Z" stroke="#545454" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M5.5 7.375C6.53553 7.375 7.375 6.53553 7.375 5.5C7.375 4.46447 6.53553 3.625 5.5 3.625C4.46447 3.625 3.625 4.46447 3.625 5.5C3.625 6.53553 4.46447 7.375 5.5 7.375Z" stroke="#545454" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                              {job.location}
                            </p>

                            <div className="d-flex gap-2">
                              {job.pay ? (
                                <div className="salary">
                                  <span>{job.pay}</span>
                                </div>
                              ) : ('')}
                              <div className="salary">
                                {job.designer_type === "animation" ? "Animation Designer" :
                                  job.designer_type === "graphic_designer" ? "Brand / Graphic Designer" :
                                    job.designer_type === "illustration" ? "Illustration Designer" :
                                      job.designer_type === "leadership" ? "Leadership Designer" :
                                        job.designer_type === "mobile_design" ? "Mobile Design Designer" :
                                          job.designer_type === "visual_design" ? "UI / Visual Designer" :
                                            job.designer_type === "product_design" ? "Product Designer" :
                                              job.designer_type === "ux_design" ? "UX Design / Research Designer" :
                                                job.designer_type === "web_design" ? "Web Designer" :
                                                  "UI/UX Designer"}
                              </div>
                              <div className="job_type">
                                <span>{job.employment_type == "fulltime" ? "Full Time" : "Freelance / Contract Hire"}</span>
                              </div>
                            </div>
                            <p className="description" dangerouslySetInnerHTML={{ __html: job.description.substring(0, 200) + "..." }} ></p>
                          </div>

                          <span className="post_Job_time">Posted <TimeAgoConverter dateTime={job.offer_date} /></span>
                        </div>
                      </button>
                    ))}


                  </div>
                  <div className="tab-content">
                    {activeJobId ? (
                      <div className="job_tab_right_content">
                        <div className="right_title">
                          <h2>
                            {jobData.title}
                            <span>
                              {jobData.workplace_type === "remote" ? "Remotely" :
                                jobData.workplace_type === "on_site" ? "On-Site" :
                                  jobData.workplace_type === "hybrid" ? "Hybrid" :
                                    "Not Specified"}
                            </span>
                          </h2>
                        </div>

                        <div className="company_deacrip d-flex gap-2">
                          <img src={`${BASE_URL}/uploads/jobs-img/${jobData.company_logo}`} alt="" width="30px" height="30px" style={{ "borderRadius": "50%" }} />
                          <h2>
                            {jobData.company_name}
                            <span>
                              <svg width="20" height="19" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.1622 17.1489L18.0242 21.2905L16.2032 13.4847L22.2658 8.23269L14.2823 7.55537L11.1622 0.19368L8.04208 7.55537L0.0585938 8.23269L6.12116 13.4847L4.30017 21.2905L11.1622 17.1489Z" fill="black" />
                              </svg>
                            </span>
                          </h2>
                        </div>

                        {/* <button className="apply_btn">Apply now</button> */}
                        <button className="apply_btn" type="button" onClick={() => handleApplyJob(jobData.apply_link)}>Apply now</button>

                        <hr />
                        <div className="job_decription">
                          <h3>Job Details</h3>
                          {jobData.pay ? (<>
                            <div className="decrip_tag">
                              <span>
                                <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.452 6.60403C2.15013 6.4791 1.8267 6.41455 1.5 6.41403V4.58603C2.16166 4.58497 2.79591 4.32166 3.26377 3.8538C3.73163 3.38594 3.99494 2.75168 3.996 2.09003H9.09C9.09 2.75307 9.35339 3.38895 9.82223 3.85779C10.2911 4.32663 10.927 4.59003 11.59 4.59003V6.42603C10.9321 6.42917 10.302 6.69148 9.8363 7.1561C9.37056 7.62071 9.10673 8.25018 9.102 8.90803H3.995C3.9939 8.41458 3.84679 7.93249 3.57221 7.52249C3.29764 7.11249 2.90787 6.79292 2.452 6.60403ZM0 1.09003C0 0.957419 0.0526785 0.830242 0.146447 0.736474C0.240215 0.642705 0.367392 0.590027 0.5 0.590027H12.59C12.7226 0.590027 12.8498 0.642705 12.9436 0.736474C13.0373 0.830242 13.09 0.957419 13.09 1.09003V9.90803C13.09 10.0406 13.0373 10.1678 12.9436 10.2616C12.8498 10.3553 12.7226 10.408 12.59 10.408H0.5C0.367392 10.408 0.240215 10.3553 0.146447 10.2616C0.0526785 10.1678 0 10.0406 0 9.90803V1.09003ZM6.544 7.50003C7.07443 7.50003 7.58314 7.28931 7.95821 6.91424C8.33329 6.53917 8.544 6.03046 8.544 5.50003C8.544 4.96959 8.33329 4.46089 7.95821 4.08581C7.58314 3.71074 7.07443 3.50003 6.544 3.50003C6.01357 3.50003 5.50486 3.71074 5.12979 4.08581C4.75471 4.46089 4.544 4.96959 4.544 5.50003C4.544 6.03046 4.75471 6.53917 5.12979 6.91424C5.50486 7.28931 6.01357 7.50003 6.544 7.50003ZM14.6 4.80003V11.91H2.76C2.62739 11.91 2.50021 11.9627 2.40645 12.0565C2.31268 12.1502 2.26 12.2774 2.26 12.41V12.91C2.26 13.0426 2.31268 13.1698 2.40645 13.2636C2.50021 13.3573 2.62739 13.41 2.76 13.41H15.423C15.865 13.41 16.1 13.188 16.1 12.747V4.80003C16.1 4.66742 16.0473 4.54024 15.9536 4.44647C15.8598 4.35271 15.7326 4.30003 15.6 4.30003H15.1C14.9674 4.30003 14.8402 4.35271 14.7464 4.44647C14.6527 4.54024 14.6 4.66742 14.6 4.80003Z" fill="#545454" fill-opacity="0.72" />
                                </svg>
                                Pay
                              </span>
                              <p className="">{jobData.pay}</p>
                            </div>
                          </>) : ('')}

                          <div className="decrip_tag">
                            <span>
                              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 3C7 3 6 6 6 6H2.5C2.36739 6 2.24021 6.05268 2.14645 6.14645C2.05268 6.24021 2 6.36739 2 6.5V9H18V6.5C18 6.36739 17.9473 6.24021 17.8536 6.14645C17.7598 6.05268 17.6326 6 17.5 6H14C14 6 13 3 10 3ZM12.5 6H7.5C7.5 6 8.5 4.5 10 4.5C11.5 4.5 12.5 6 12.5 6Z" fill="#545454" fill-opacity="0.72" />
                                <path d="M8 11H2V16.5C2 16.6326 2.05268 16.7598 2.14645 16.8536C2.24021 16.9473 2.36739 17 2.5 17H17.5C17.6326 17 17.7598 16.9473 17.8536 16.8536C17.9473 16.7598 18 16.6326 18 16.5V11H12C12 12 11 13 10 13C9 13 8 12 8 11Z" fill="#545454" fill-opacity="0.72" />
                              </svg>
                              Job type
                            </span>
                            <p className="">{jobData.employment_type == "fulltime" ? "Full Time" : "Freelance / Contract Hire"}</p>
                          </div>

                          <div className="decrip_tag">
                            <span>
                              <svg width="15" height="20" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 12.9993L5.97325 12.4674C6.51025 11.8544 6.9935 11.2712 7.423 10.7176L7.77775 10.2508C9.25925 8.26039 10 6.68119 10 5.51318C10 3.02057 7.9855 1 5.5 1C3.0145 1 1 3.02057 1 5.51318C1 6.68119 1.74075 8.26064 3.22225 10.2515L3.577 10.7183C4.19016 11.5018 4.83158 12.2621 5.5 12.9993Z" stroke="#545454" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M5.5 7.375C6.53553 7.375 7.375 6.53553 7.375 5.5C7.375 4.46447 6.53553 3.625 5.5 3.625C4.46447 3.625 3.625 4.46447 3.625 5.5C3.625 6.53553 4.46447 7.375 5.5 7.375Z" stroke="#545454" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                              Location
                            </span>
                            <p className="">{jobData.location}</p>
                          </div>

                          <hr />
                          <h3>Full job description</h3>
                          <p className="decription_content" dangerouslySetInnerHTML={{ __html: jobData.description }} ></p>
                        </div>
                      </div>
                    ) : ('')}
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={1000} />
      {/* <Footer /> */}
    </>
  );
};

export default LJobs;
