import React, { useState, useEffect, useContext } from "react";
import HeaderTop from "../../../components/header/large-screen/HeaderTop";
import HomeSidebar from "../../home/large-view/HomeSidebar";
import { commonSidebar } from "../../../components/context/ContextProvider";
import "./Category.css";
import { Link, useParams } from "react-router-dom";
import SmallHeader from "../../../components/header/small-screen/SmallHeader";
import SmallFooter from "../../../components/footer/SmallFooter";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import RecommendedService from "../RecommendedService";
import { createChat, getServicesSubCats, getSingleServiceById, getUserLikedServices, sendEmail, serviceLikeUnlike } from "../../../../services/Apis";
import { BASE_URL } from "../../../../services/helper";
import { formatDate3, isTokenValid } from "../../../components/functions/OtherFunctions";
import { ToastContainer, toast } from "react-toastify";

const ViewSingleService = () => {
  const { showMenu, setShowMenu } = useContext(commonSidebar);

  const navigate = useNavigate();

  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        setIsValid(valid);
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();
  }, []);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 700);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 700);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: false, // Display dots for navigation
    infinite: true, // Loop through slides infinitely
    speed: 500, // Transition speed
    slidesToShow: 1, // Number of slides to show at a time
    slidesToScroll: 1, // Number of slides to scroll at a time
    autoplay: true, // Autoplay the slides
    autoplaySpeed: 2000, // Autoplay speed (in ms)
    arrows: true, // Show next and previous arrows
    responsive: [
      {
        breakpoint: 768, // Below this width, show fewer slides
        settings: {
          slidesToShow: 3, // On mobile, show 1 slide
        },
      },
      {
        breakpoint: 350, // Below this width, show fewer slides
        settings: {
          slidesToShow: 2, // On mobile, show 1 slide
        },
      },
    ],
  };

  const { service_id, service_by } = useParams();

  const [data, setData] = useState({});
  const fetchServiceById = async () => {
    try {
      const res = await getSingleServiceById(service_id);
      if (res.status === 200) {
        setData(res.data);
      } else {
        console.log('Error in Fetching Service.');
      }
    } catch (error) {
      console.error('Error fetching Service:', error);
    }
  };

  const [allCats, setAllCats] = useState([]);

  const fetchAllSubCategories = async () => {
    try {
      const res = await getServicesSubCats();
      if (res.status === 200) {
        setAllCats(res.data);
      } else {
        console.log('Error in fetching categories.');
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {
    fetchServiceById();
    fetchAllSubCategories();
  }, [service_id]);

  //<----------------------------------------------- Message Field ----------------------------------------------->
  const username = sessionStorage.getItem("user_username") || "";

  const handleOpenChat = async (receiver) => {
    if (isValid === true) {
      if (username == receiver) {
        navigate("/message");
      } else {
        const data = { sender: username, receiver: receiver };
        try {
          const config = { "Content-Type": "application/json" };
          const res = await createChat(data, config);
          if (res.status === 200) {
            navigate("/message");
          }
        } catch (error) {
          console.error("Error:", error.response.data);
        }
      }
    } else {
      toast.error("Please Login");
    }
  };

  const sendServiceEmail = (email) => {
    if (isValid) {
      window.location.href = `mailto:${email}`;
    } else {
      toast.error('Please Login First');
    }
  };

  //<----------------------------------------------- Fetch Services Liked by user ----------------------------------------------->

  const [serviceLiked, setServiceLiked] = useState([]);
  const getLikedServices = async () => {
    try {
      const res = await getUserLikedServices(username);
      if (res.status === 200) {
        setServiceLiked(res.data.likedServices);
      } else {
        console.log("Error in Fetching Liked Services.");
      }
    } catch (error) {
      console.error("Error fetching liked Services:", error);
    }
  };

  useEffect(() => {
    if (username) {
      getLikedServices();
    }
  }, [username, service_id, service_by]);

  //<----------------------------------------------- Handle Like Unlike ----------------------------------------------->
  const handleLike = async (service_id) => {
    if (username) {
      const data = { service_id, liked_by: username, service_by };
      try {
        const res = await serviceLikeUnlike(data);
        if (res.status === 200) {
          getLikedServices();
        }
      } catch (error) {
        console.error("Error:", error.response.data);
      }
    } else {
      alert("Please Login");
    }
  };

  return (
    <div>
      {isSmallScreen ? <SmallHeader /> : <HeaderTop />}
      <HomeSidebar showMenu={showMenu} />

      <div
        id="category_main "
        className="view_category single_service"
        style={showMenu ? { filter: "blur(2px)" } : {}}
      >
        <h2 className="page_headng">
          {data.main_cat_title} / <span>{data.sub_cat_title}</span>
        </h2>
        <div className="row">
          <div className="col-lg-7 col-sm-12">
            <div className="left_side">
              <img src={`${BASE_URL}/uploads/services-img/${data.service_img}`} alt="" />
              <div className="text_sec">
                <div className="flex_price">
                  <div>
                    <h2>From ₹{data.service_price}</h2>
                  </div>
                  <div>
                    <button type="button"
                      className="like-div"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleLike(data._id);
                      }}>
                      {/* <svg width="35" height="35" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="24.5855" cy="24.5855" r="24.5855" fill="#F2F2F2"></circle>
                        <path d="M37.9469 20.8201C37.9469 30.0454 25.4926 37.5127 24.9622 37.8211C24.8224 37.9037 24.6661 37.9469 24.5074 37.9469C24.3487 37.9469 24.1924 37.9037 24.0526 37.8211C23.5222 37.5127 11.0679 30.0454 11.0679 20.8201C11.0701 18.6537 11.8546 16.5768 13.2494 15.0449C14.6441 13.5131 16.5352 12.6514 18.5076 12.649C20.9855 12.649 23.1551 13.8193 24.5074 15.7975C25.8598 13.8193 28.0293 12.649 30.5072 12.649C32.4797 12.6514 34.3707 13.5131 35.7654 15.0449C37.1602 16.5768 37.9447 18.6537 37.9469 20.8201Z" fill="#FF0000"></path>
                        <path d="M30.9602 31.9437C27.9983 34.7927 24.9712 36.6521 24.5074 36.9284C24.0436 36.6521 21.0165 34.7927 18.0546 31.9437C14.9166 28.9255 12.0681 25.0034 12.0679 20.8206C12.07 18.8906 12.7698 17.057 13.9888 15.7182C15.2049 14.3825 16.8326 13.6513 18.5082 13.649C20.6545 13.6492 22.5154 14.6555 23.6819 16.3619L24.5074 17.5694L25.3329 16.3619C26.4994 14.6555 28.3603 13.6492 30.5066 13.649C32.1822 13.6513 33.8099 14.3825 35.026 15.7182C36.2451 17.0571 36.945 18.8909 36.9469 20.8211C36.9465 25.0037 34.0981 28.9256 30.9602 31.9437Z" fill="white" stroke="#1E2023" stroke-width="2"></path>
                      </svg> */}
                      {serviceLiked.length > 0 ? (
                        serviceLiked.some(
                          (liked) => liked.service_id === data._id
                        ) ? (
                          <svg width="35" height="35" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="24.5855" cy="24.5855" r="24.5855" fill="#F2F2F2"></circle>
                            <path d="M37.9469 20.8201C37.9469 30.0454 25.4926 37.5127 24.9622 37.8211C24.8224 37.9037 24.6661 37.9469 24.5074 37.9469C24.3487 37.9469 24.1924 37.9037 24.0526 37.8211C23.5222 37.5127 11.0679 30.0454 11.0679 20.8201C11.0701 18.6537 11.8546 16.5768 13.2494 15.0449C14.6441 13.5131 16.5352 12.6514 18.5076 12.649C20.9855 12.649 23.1551 13.8193 24.5074 15.7975C25.8598 13.8193 28.0293 12.649 30.5072 12.649C32.4797 12.6514 34.3707 13.5131 35.7654 15.0449C37.1602 16.5768 37.9447 18.6537 37.9469 20.8201Z" fill="#FF0000"></path>
                            <path d="M30.9602 31.9437C27.9983 34.7927 24.9712 36.6521 24.5074 36.9284C24.0436 36.6521 21.0165 34.7927 18.0546 31.9437C14.9166 28.9255 12.0681 25.0034 12.0679 20.8206C12.07 18.8906 12.7698 17.057 13.9888 15.7182C15.2049 14.3825 16.8326 13.6513 18.5082 13.649C20.6545 13.6492 22.5154 14.6555 23.6819 16.3619L24.5074 17.5694L25.3329 16.3619C26.4994 14.6555 28.3603 13.6492 30.5066 13.649C32.1822 13.6513 33.8099 14.3825 35.026 15.7182C36.2451 17.0571 36.945 18.8909 36.9469 20.8211C36.9465 25.0037 34.0981 28.9256 30.9602 31.9437Z" fill="red" stroke="red" stroke-width="2"></path>
                          </svg>
                        ) : (
                          <svg width="35" height="35" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="24.5855" cy="24.5855" r="24.5855" fill="#F2F2F2"></circle>
                            <path d="M37.9469 20.8201C37.9469 30.0454 25.4926 37.5127 24.9622 37.8211C24.8224 37.9037 24.6661 37.9469 24.5074 37.9469C24.3487 37.9469 24.1924 37.9037 24.0526 37.8211C23.5222 37.5127 11.0679 30.0454 11.0679 20.8201C11.0701 18.6537 11.8546 16.5768 13.2494 15.0449C14.6441 13.5131 16.5352 12.6514 18.5076 12.649C20.9855 12.649 23.1551 13.8193 24.5074 15.7975C25.8598 13.8193 28.0293 12.649 30.5072 12.649C32.4797 12.6514 34.3707 13.5131 35.7654 15.0449C37.1602 16.5768 37.9447 18.6537 37.9469 20.8201Z" fill="#FF0000"></path>
                            <path d="M30.9602 31.9437C27.9983 34.7927 24.9712 36.6521 24.5074 36.9284C24.0436 36.6521 21.0165 34.7927 18.0546 31.9437C14.9166 28.9255 12.0681 25.0034 12.0679 20.8206C12.07 18.8906 12.7698 17.057 13.9888 15.7182C15.2049 14.3825 16.8326 13.6513 18.5082 13.649C20.6545 13.6492 22.5154 14.6555 23.6819 16.3619L24.5074 17.5694L25.3329 16.3619C26.4994 14.6555 28.3603 13.6492 30.5066 13.649C32.1822 13.6513 33.8099 14.3825 35.026 15.7182C36.2451 17.0571 36.945 18.8909 36.9469 20.8211C36.9465 25.0037 34.0981 28.9256 30.9602 31.9437Z" fill="white" stroke="#1E2023" stroke-width="2"></path>
                          </svg>
                        )
                      ) : (
                        <svg width="35" height="35" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="24.5855" cy="24.5855" r="24.5855" fill="#F2F2F2"></circle>
                          <path d="M37.9469 20.8201C37.9469 30.0454 25.4926 37.5127 24.9622 37.8211C24.8224 37.9037 24.6661 37.9469 24.5074 37.9469C24.3487 37.9469 24.1924 37.9037 24.0526 37.8211C23.5222 37.5127 11.0679 30.0454 11.0679 20.8201C11.0701 18.6537 11.8546 16.5768 13.2494 15.0449C14.6441 13.5131 16.5352 12.6514 18.5076 12.649C20.9855 12.649 23.1551 13.8193 24.5074 15.7975C25.8598 13.8193 28.0293 12.649 30.5072 12.649C32.4797 12.6514 34.3707 13.5131 35.7654 15.0449C37.1602 16.5768 37.9447 18.6537 37.9469 20.8201Z" fill="#FF0000"></path>
                          <path d="M30.9602 31.9437C27.9983 34.7927 24.9712 36.6521 24.5074 36.9284C24.0436 36.6521 21.0165 34.7927 18.0546 31.9437C14.9166 28.9255 12.0681 25.0034 12.0679 20.8206C12.07 18.8906 12.7698 17.057 13.9888 15.7182C15.2049 14.3825 16.8326 13.6513 18.5082 13.649C20.6545 13.6492 22.5154 14.6555 23.6819 16.3619L24.5074 17.5694L25.3329 16.3619C26.4994 14.6555 28.3603 13.6492 30.5066 13.649C32.1822 13.6513 33.8099 14.3825 35.026 15.7182C36.2451 17.0571 36.945 18.8909 36.9469 20.8211C36.9465 25.0037 34.0981 28.9256 30.9602 31.9437Z" fill="white" stroke="#1E2023" stroke-width="2"></path>
                        </svg>
                      )}
                    </button>

                    <button>
                      <svg width="35" height="35" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="24.5855" cy="24.5855" r="24.5855" fill="#F2F2F2"></circle>
                        <path
                          d="M26.3335 23.6667L22.8335 27.1667M33.6695 15.5362C33.7802 15.498 33.8994 15.4917 34.0135 15.518C34.1276 15.5444 34.232 15.6023 34.3148 15.6852C34.3975 15.768 34.4553 15.8725 34.4815 15.9867C34.5077 16.1008 34.5012 16.22 34.4628 16.3307L27.5515 36.0823C27.5101 36.2004 27.4343 36.3033 27.3337 36.3777C27.2331 36.4522 27.1125 36.4946 26.9875 36.4997C26.8625 36.5047 26.7389 36.472 26.6327 36.406C26.5264 36.3399 26.4425 36.2434 26.3918 36.129L22.6363 27.68C22.5741 27.5389 22.4613 27.4261 22.3202 27.3638L13.8712 23.6072C13.7572 23.5563 13.6611 23.4724 13.5953 23.3663C13.5294 23.2602 13.4969 23.1368 13.502 23.0121C13.507 22.8874 13.5493 22.767 13.6234 22.6666C13.6976 22.5661 13.8001 22.4902 13.9178 22.4487L33.6695 15.5362Z"
                          stroke="#1E2023"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </button>

                  </div>
                </div>

                <h3>{data.title}</h3>
                <p dangerouslySetInnerHTML={{ __html: data.description }} />
              </div>
            </div>
          </div>

          <div className="col-lg-5 col-sm-12">
            <div className="right_side">
              <div className="profile">
                <div className="d-flex align-items-center">
                  <div>
                    <img src={`${BASE_URL}/uploads/admins-users-img/${data.user_img}`} alt="" />
                  </div>
                  <div>
                    <h2>{data.fname + " " + data.lname}</h2>
                    <span>Member since {formatDate3(data.user_reg_date)}</span>
                  </div>
                </div>
                <button className="email_btn" type="button" onClick={() => sendServiceEmail(data.email)}>
                  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2ZM18 2L10 7L2 2H18ZM18 14H2V4L10 9L18 4V14Z" fill="white" />
                  </svg>
                  Email
                </button>
                <button className="chat_btn" type="button" onClick={() => handleOpenChat(data.username)}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.6 19.92L7.124 18.701L7.134 18.693C7.452 18.438 7.613 18.31 7.792 18.219C7.95267 18.137 8.122 18.0777 8.3 18.041C8.499 18 8.706 18 9.122 18H17.803C18.921 18 19.481 18 19.908 17.782C20.2843 17.5903 20.5903 17.2843 20.782 16.908C21 16.48 21 15.92 21 14.804V7.197C21 6.079 21 5.519 20.782 5.092C20.59 4.71554 20.2837 4.40957 19.907 4.218C19.48 4 18.92 4 17.8 4H6.2C5.08 4 4.52 4 4.092 4.218C3.71569 4.40974 3.40974 4.71569 3.218 5.092C3 5.52 3 6.08 3 7.2V18.671C3 19.737 3 20.27 3.218 20.543C3.31174 20.6607 3.43083 20.7557 3.56641 20.821C3.70198 20.8863 3.85053 20.9201 4.001 20.92C4.351 20.92 4.767 20.586 5.6 19.92Z" stroke="#272D3B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  Chat
                </button>
              </div>

              <div className="profile">
                {isSmallScreen ? (
                  <div id="category_main">
                    <div className="category_section">
                      <Slider  {...settings}>
                        {allCats.map((cat) => (
                          <Link to={`/service/category/${cat.main_cat}/${cat.sub_cat}`}>
                            <img src={`${BASE_URL}/uploads/services-cat-img/${cat.sub_cat_img}`} alt="" />
                            <h2>{cat.sub_cat_title}</h2>
                          </Link>
                        ))}
                      </Slider>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div style={{ width: "80%", margin: " auto" }}>
                      <Slider {...settings}>
                        {allCats.map((cat) => (
                          <Link to={`/service/category/${cat.main_cat}/${cat.sub_cat}`} style={{ textDecoration: "none" }} >
                            {" "}
                            {/* <div key={cat._id}> */}
                            <img src={`${BASE_URL}/uploads/services-cat-img/${cat.sub_cat_img}`} alt={`Slide ${cat._id}`} style={{ width: "100%", height: "auto" }} />
                            <h2 className="m" style={{ margin: "16px", textAlign: "center" }}>
                              {cat.sub_cat_title}
                            </h2>
                            {/* </div> */}
                          </Link>
                        ))}
                      </Slider>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="view_category_recommanded">
          <RecommendedService />
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={1000} />
      {isSmallScreen ? <SmallFooter /> : ""}
    </div>
  );
}

export default ViewSingleService