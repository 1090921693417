import React, { useCallback, useContext, useState, useEffect } from 'react';
import './login.css';
import { Link, useNavigate } from 'react-router-dom';
import { checkUserEmailExist, socialLogin, socialSignUp, userLogin } from '../../../services/Apis';
import { afterLoginFullname, loginAlert, mainTheme } from '../../components/context/ContextProvider';
import { jwtDecode } from 'jwt-decode';
import { BASE_URL } from '../../../services/helper';
import { LoginSocialGoogle, LoginSocialFacebook } from 'reactjs-social-login';
import { GoogleLoginButton, FacebookLoginButton, GithubLoginButton } from 'react-social-login-buttons';
import { ToastContainer, toast } from 'react-toastify';
import Logo from '../../../../public/assets/images/logos.png'

const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID;
const FACEBOOK_APP_ID = import.meta.env.VITE_FACEBOOK_APP_ID;
const GITHUB_CLIENT_ID = import.meta.env.VITE_GITHUB_CLIENT_ID;

const Login = () => {

  const { pageTheme, setPageTheme } = useContext(mainTheme);

  // <---------------------------------------------- Change Screen Based on Width ---------------------------------------------->

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 700);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // <---------------------------------------------- Manual Login Code ---------------------------------------------->
  const navigate = useNavigate();

  const { login, setLogin } = useContext(loginAlert);
  const { loginFullname, setLoginFullname } = useContext(afterLoginFullname);

  const [loginData, setLoginData] = useState({
    username: "",
    password: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const config = {
        "Content-Type": "application/json"
      };
      const res = await userLogin(loginData, config);
      if (res.status === 200) {
        const token = res.data;
        const decodedToken = jwtDecode(token);
        sessionStorage.setItem('user_token', token);
        sessionStorage.setItem('user_id', decodedToken.userId);
        sessionStorage.setItem('user_username', decodedToken.username);
        setLogin(decodedToken.username);
        setLoginFullname(decodedToken.fname + " " + decodedToken.lname);
        navigate("/");
      } else {
        if (res.response.data.message) {
          // alert(res.response.data.message);
          toast.error(res.response.data.message);
        } else {
          // alert("Some Unknown Error Occurred");
          toast.error("Some Unknown Error Occurred");
        }
      }
    } catch (error) {
      console.error('Error:', error.response.data);
    }
  };

  // <---------------------------------------------- choosing Login Type ---------------------------------------------->
  const [chooseLogin, setChooseLogin] = useState('');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [userExist, setUserExist] = useState(true);

  const handleChooseLogin = (choose) => {
    if (choose === "Google") {
      setChooseLogin(choose);
    }
    else if (choose === "GitHub") {
      if (sessionStorage.getItem("github_accessToken") === null) {
        handleGitHubLogin();
      }
      setChooseLogin(choose);
    }
    else if (choose === "Facebook") {
      setChooseLogin(choose);
    }
  }

  // <---------------------------------------------- Google Login Code ---------------------------------------------->
  const onResolveGoogleLogin = async ({ provider, data }) => {
    try {
      const { access_token } = data;
      // const response = await fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`);
      const response = await fetch(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${access_token}`);
      if (!response.ok) {
        throw new Error('Failed to fetch user data from Google API');
      }
      const userData = await response.json();
      const { given_name, family_name, email } = userData;
      setFname(given_name);
      setLname(family_name);
      submitSocialLogin(email);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  // <---------------------------------------------- GitHub Login Code ---------------------------------------------->;
  const [reRender, setReRender] = useState(true);
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const codeParams = urlParams.get("code");

    if (codeParams && (sessionStorage.getItem("github_accessToken") === null)) {
      async function getAccessToken() {
        await fetch(`${BASE_URL}/getGitHubAccessToken?code=${codeParams}`, {
          method: "GET"
        }).then((response) => {
          return response.json();
        }).then((data) => {
          if (data.access_token) {
            sessionStorage.setItem("github_accessToken", data.access_token);
            setReRender(!reRender);
          }
        })
      }
      getAccessToken();
    }
  }, [])

  async function getUserData() {
    await fetch(`${BASE_URL}/getUserData`, {
      method: "GET",
      headers: {
        "Authorization": "Bearer " + sessionStorage.getItem("github_accessToken")
      }
    }).then((response) => {
      return response.json();
    }).then((data) => {
      const fullName = data.name;
      const [firstName, lastName] = fullName.split(' ');
      console.log("Profile Picture:", data);
      setFname(firstName);
      setLname(lastName);
      submitSocialLogin(data.email);
    });
  }


  const handleGitHubLogin = () => {
    window.location.assign("https://github.com/login/oauth/authorize?client_id=" + GITHUB_CLIENT_ID);
  }

  // <---------------------------------------------- Facebook Login Code ---------------------------------------------->

  const onResolveFacebookLogin = useCallback(async (data) => {
    try {
      const { userID, accessToken } = data.data;
      const response = await fetch(`https://graph.facebook.com/${userID}?fields=id,name,email,picture&access_token=${accessToken}`);
      const userData = await response.json();
      const [firstName, lastName] = userData.name.split(' ');
      setFname(firstName);
      setLname(lastName);
      submitSocialLogin(userData.email);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, []);

  // <---------------------------------------------- Social Login Code ---------------------------------------------->

  const submitSocialLogin = async (email) => {
    if (email) {
      try {
        const EmailExist = await checkUserEmailExist(email);
        if (EmailExist.data.exists) {
          handleSocialLogin(email);
        }
        else {
          setUserExist(false);
          setEmail(email);
        }
      }
      catch (error) {
        console.error('Error:', error);
      }
    }
    else {
      if (chooseLogin) {
        // alert(chooseLogin + " didnt gave access to your Email! Please try someother way");
        toast.error(chooseLogin + " didnt gave access to your Email! Please try someother way");
      }
      else {
        // alert("Cannot get your Email! Please try someother way");
        toast.error("Cannot get your Email! Please try someother way");
      }
    }
  }

  const handleSocialLogin = async (email) => {
    const config = { "Content-Type": "application/json" };
    const sendData = { email };
    const res = await socialLogin(sendData, config);
    if (res.status === 200) {
      const token = res.data;
      const decodedToken = jwtDecode(token);
      sessionStorage.setItem('user_token', token);
      sessionStorage.setItem('user_id', decodedToken.userId);
      sessionStorage.setItem('user_username', decodedToken.username);
      setLogin(decodedToken.username);
      setLoginFullname(decodedToken.fname + " " + decodedToken.lname);
      navigate("/");
    } else {
      if (res.response.data.message) {
        // alert(res.response.data.message);
        toast.error(res.response.data.message);
      } else {
        // alert("Some Unknown Error Occurred");
        toast.error("Some Unknown Error Occurred");
      }
    }
  }

  const handleSocialSignup = async (e) => {
    e.preventDefault();
    const config = { "Content-Type": "application/json" };
    if (fname && lname && username && email && password) {
      const sendData = { fname, lname, username, email, password };
      const res = await socialSignUp(sendData, config);
      if (res.status === 200) {
        const token = res.data;
        const decodedToken = jwtDecode(token);
        sessionStorage.setItem('user_token', token);
        sessionStorage.setItem('user_id', decodedToken.userId);
        sessionStorage.setItem('user_username', decodedToken.username);
        setLogin(decodedToken.username);
        setLoginFullname(decodedToken.fname + " " + decodedToken.lname);
        navigate("/");
      } else {
        if (res.response.data.message) {
          // alert(res.response.data.message);
          toast.error(res.response.data.message);
        } else {
          // alert("Some Unknown Error Occurred");
          toast.error("Some Unknown Error Occurred");
        }
      }

    } else {
      // alert("Some Data is Missing");
      toast.error("Some Data is Missing");
    }
  }

  return (
    <>

      <div className="container-fluid" id="signup-page">
        <div className="row d-flex h-100">
          {/* <div className="col-lg-5">
              {((pageTheme === "dark_th") || (pageTheme === "blue_th")) ?
                (<img src={login_dark} className='w-100' alt="Login" />) :
                (<img src={login_light} className='w-100' alt="Login" />)}
            </div> */}

          <div className="col-lg-7 m-auto">
            <div className='login-wrapper'>        
              <img src={Logo} />
              <h1 className='login-head'>Login</h1>
              <p>Please Sign In your account</p>
              {userExist ? (
                <>
                  <form onSubmit={handleSubmit}>
                    <div className='input-wrapper'>
                      <div className='input_label_sect'>
                        <label htmlFor="First Name">Username</label>
                        <input type='text' name="username" onChange={handleChange} />
                      </div>
                      <div className='input_label_sect'>
                        <label htmlFor="Password">Password</label>
                        <input type='password' name="password" onChange={handleChange} />
                      </div>
                    </div>
                    <p className='forget-pwd'>Forgot Your <Link to="">Password ?</Link></p>

                    <div className='login-btn'><button type='submit'>Login</button></div>
                  </form>
                </>
              ) : (
                <>
                  <h5 className='login-head'>Please Set Username and Password</h5>

                  <form onSubmit={handleSocialSignup}>
                    <div className='input-wrapper'>
                      <div className='input_label_sect'>
                        <label htmlFor="Password">Username</label>
                        <input type="text" name="username" onChange={(e) => setUsername(e.target.value)} required />
                      </div>
                      <div className='input_label_sect'>
                        <label htmlFor="Password">Password</label>
                        <input type='password' name="password" onChange={(e) => setPassword(e.target.value)} required />
                      </div>
                    </div>
                    <div className='login-btn'><button type='submit'>Sign Up</button></div>
                  </form>
                </>
              )}
              <div className='other-signin'>
                <h1>Or Continue with</h1>
                <div className='icons-wrapper'>
                  <div
                    className="icons"
                    onClick={() => handleChooseLogin("Google")}
                  >
                    <svg
                      width="31"
                      height="31"
                      viewBox="0 0 31 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M28.1654 12.9703H27.1249V12.9167H15.4999V18.0833H22.7998C21.7348 21.091 18.8731 23.25 15.4999 23.25C11.22 23.25 7.74992 19.7799 7.74992 15.5C7.74992 11.2201 11.22 7.75 15.4999 7.75C17.4755 7.75 19.2729 8.49529 20.6414 9.71268L24.2949 6.0592C21.988 3.90922 18.9022 2.58333 15.4999 2.58333C8.36669 2.58333 2.58325 8.36677 2.58325 15.5C2.58325 22.6332 8.36669 28.4167 15.4999 28.4167C22.6331 28.4167 28.4166 22.6332 28.4166 15.5C28.4166 14.6339 28.3275 13.7885 28.1654 12.9703Z"
                        fill="#FFC107"
                      />
                      <path
                        d="M4.07251 9.48793L8.31628 12.6002C9.46457 9.75725 12.2455 7.74999 15.4999 7.74999C17.4755 7.74999 19.2728 8.49529 20.6414 9.71268L24.2948 6.0592C21.9879 3.90922 18.9021 2.58333 15.4999 2.58333C10.5386 2.58333 6.23605 5.38431 4.07251 9.48793Z"
                        fill="#FF3D00"
                      />
                      <path
                        d="M15.5 28.4167C18.8364 28.4167 21.868 27.1399 24.16 25.0635L20.1623 21.6806C18.8221 22.7004 17.1841 23.2518 15.5 23.25C12.1404 23.25 9.28777 21.1078 8.2131 18.1182L4.00098 21.3635C6.13868 25.5466 10.48 28.4167 15.5 28.4167Z"
                        fill="#4CAF50"
                      />
                      <path
                        d="M28.1654 12.9703H27.125V12.9167H15.5V18.0833H22.7999C22.2904 19.5148 21.3728 20.7656 20.1603 21.6813L20.1623 21.68L24.16 25.0629C23.8771 25.3199 28.4167 21.9583 28.4167 15.5C28.4167 14.6339 28.3275 13.7885 28.1654 12.9703Z"
                        fill="#1976D2"
                      />
                    </svg>
                    <span>Sign up with Google</span>
                  </div>
                  <div
                    className="icons"
                    onClick={() => handleChooseLogin("Facebook")}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M24 12.0733C24 5.40546 18.6274 0 12 0C5.37262 0 0 5.40546 0 12.0733C0 18.0994 4.38825 23.0943 10.125 24V15.5633H7.07812V12.0733H10.125V9.41343C10.125 6.38755 11.9166 4.71615 14.6576 4.71615C15.9705 4.71615 17.3438 4.95195 17.3438 4.95195V7.92313H15.8306C14.3399 7.92313 13.875 8.85381 13.875 9.80864V12.0733H17.2031L16.6711 15.5633H13.875V24C19.6117 23.0943 24 18.0995 24 12.0733Z"
                        fill="#1976D2"
                      />
                    </svg>
                    <span>Sign up with Facebook</span>
                  </div>
                  {/* <div className='icons' onClick={() => handleChooseLogin("GitHub")}><i className="bi bi-github"></i></div> */}
                </div>
              </div>
              {(sessionStorage.getItem("github_accessToken") && chooseLogin !== "Google" && chooseLogin !== "Facebook") || chooseLogin === "GitHub" ? (
                <GithubLoginButton onClick={getUserData} />
              ) : ('')}
              {chooseLogin === "Google" && (
                <>
                  <LoginSocialGoogle isOnlyGetToken client_id={GOOGLE_CLIENT_ID} onResolve={onResolveGoogleLogin} onReject={(err) => { console.log(err); }} scope="https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email">
                    <GoogleLoginButton />
                  </LoginSocialGoogle>
                </>)}
              {chooseLogin === "Facebook" && (
                <>
                  <LoginSocialFacebook isOnlyGetToken appId={FACEBOOK_APP_ID} onResolve={onResolveFacebookLogin} onReject={(err) => { console.log(err) }}>
                    <FacebookLoginButton />
                  </LoginSocialFacebook>
                </>)}
              <div className='signup'>
                <p style={{ textAlign: "center", color: " rgb(39, 45, 59)" }}>Sign Up Free<Link to="/signup"> Sign up</Link></p>
              </div>
              <div className='signup'>
                <p style={{ "fontSize": "17px", textAlign: "center", margin: "5px", }}>Go back to <Link to="/"> Home</Link></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer position="top-center" autoClose={1000} />
    </>
  )
}

export default Login;