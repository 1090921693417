import React, { useState, useEffect } from 'react';
import { BsUpload } from "react-icons/bs";
import { useNavigate, useParams } from 'react-router-dom';
import { editService, getServicesMainCats, getServicesSubCats, getSingleServiceById } from '../../../../services/Apis';
import AccessChecker from '../../../components/OtherFunctions/AccessChecker';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { BASE_URL } from '../../../../services/helper';

const EditService = () => {
  const {id} = useParams();

  const [serviceData, setServiceData] = useState({
    title:"",
    main_cat_id:"",
    sub_cat_id:"",
    service_price:"",
    featured:"",
    status:""
  });

  const [showImg, setShowImg] = useState("");
  const [serviceImg, setServiceImg] = useState("");
  const [description, setDescription] = useState("");

  const fetchSingleService = async () => {
    try {
      const res = await getSingleServiceById(id);
      if (res.status === 200) {
        setServiceData(res.data);
        setServiceImg(res.data.service_img);
        setDescription(res.data.description);
      } else {
        console.log('Error in Fetching Service.');
      }
    } catch (error) {
      console.error('Error fetching Services:', error);
    }
  };

  const [mainCats, setMainCats] = useState([]);
  const fetchMainCategory = async () => {
    const response = await getServicesMainCats();
    if (response.status === 200) {
      setMainCats(response.data);
    } else {
      console.log("Error in Fetching Main Categories.");
    }
  };

  const [subCats, setSubCats] = useState([]);
  const fetchSubCategory = async () => {
    const response = await getServicesSubCats();
    if (response.status === 200) {
      setSubCats(response.data);
    } else {
      console.log("Error in Fetching Sub Categories.");
    }
  };

  useEffect(() => {
    fetchMainCategory();
    fetchSubCategory();
    fetchSingleService()
  }, []);

  const navigate = useNavigate();

  const selectImg = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (allowedFileTypes.includes(selectedFile.type)) {
        setShowImg(URL.createObjectURL(selectedFile));
        setServiceImg(selectedFile);
      } else {
        alert("Only png, jpg, jpeg Images are Allowed");
      }
    }
  }

  const setInputValue = (e) => {
    const { name, value } = e.target;
    setServiceData({ ...serviceData, [name]: value });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {title, main_cat_id, sub_cat_id, featured, service_price, status} = serviceData
    const formData = new FormData();
    formData.append("service_img", serviceImg);
    formData.append("title", title);
    formData.append("main_cat_id", main_cat_id);
    formData.append("sub_cat_id", sub_cat_id);
    formData.append("featured", featured);
    formData.append("service_price", service_price);
    formData.append("description", description);
    formData.append("status", status);
    if (!serviceImg) {
      alert("Please Select Image");
    }
    else 
    {
      const config = {  "Content-Type": "multipart/form-data" };
      const response = await editService(id, formData, config);
      if (response.status === 200) {
        alert("Service Updated Successfully");
        navigate("/admin/view-services")
      } else {
        alert("Sorry !! Service update Unsuccessful");
      }
    }
  };

  return (
    <>
      <AccessChecker accessKey="add_post" />
      <div className='form-card add-service' id="add-post">
        <div className="row">
          <div className="col-md-12 form-head">
            <h1>Update Service</h1>
          </div>
        </div>
        <hr />
        <form className='form-div' onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="form-group">
            <div className="row">
              <div className="col-lg-6">
                <div className="img-upload-wrap">
                  <div className='upld-img-icon' style={{"height":"320px"}}>
                    <label htmlFor="imageInput" style={{ cursor: 'pointer' }}><img src={!showImg? `${BASE_URL}/uploads/services-img/${serviceImg}` : showImg} alt="Post Image" /></label>
                    <input type="file" id="imageInput" accept="image/*" name="serviceImg" style={{ display: 'none' }} onChange={selectImg} />
                  </div>
                  <label htmlFor="imageInput" style={{ cursor: 'pointer' }}><span className='upload-icon-btn'><BsUpload /></span></label>
                </div>
              </div>

              <div className="col-lg-6">

              <div className="row d-grid h-100">
              <div className="col-lg-12">
                <label>Main Category</label>
                <select name="main_cat_id" value={serviceData.main_cat_id} onChange={setInputValue} className="form-control" required>
                  <option value="0">Select Main Category</option>
                  {mainCats.map((cat, index) => (
                    <option key={index} value={cat._id}>{cat.main_cat_title}</option>
                  ))}
                </select>
              </div>
              <div className="col-lg-12">
                <label>Sub Category</label>
                <select name="sub_cat_id" value={serviceData.sub_cat_id} onChange={setInputValue} className="form-control" required>
                  <option value="0">Select Sub Category</option>
                  {subCats.map((cat, index) => (
                    <option key={index} value={cat._id}>{cat.sub_cat_title}</option>
                  ))}
                </select>
              </div>
              <div className="col-lg-12">
                <label>Service Title</label>
                <input type="text" className="form-control" placeholder="Enter Service Title" name="title" value={serviceData.title} onChange={setInputValue} required />
              </div>
              <div className="col-lg-12">
                <label>Service Charge (Starts From)</label>
                <input type="number" className="form-control" placeholder="Enter Min Service Charge" name="service_price" value={serviceData.service_price} onChange={setInputValue} required />
              </div>
            </div>

              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-lg-12">
                <label>Description</label>
                <ReactQuill theme="snow" value={description} onChange={setDescription} style={{ borderRadius: "5px 5px 0 0" }} />
              </div>
            </div>
          </div>
          
          <div className="form-group">
            <div className="row">
              <div className="col-lg-5">
                <label>Featured</label>
                <select className='form-control' name="featured" value={serviceData.featured} onChange={setInputValue} required>
                  <option>--------- Select Option ---------</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
              <div className="col-lg-5">
                <label>Status</label>
                <select className='form-control' name="status" value={serviceData.status} onChange={setInputValue} required>
                  <option>--------- Select Option ---------</option>
                  <option value="active">Active</option>
                  <option value="inactive">InActive</option>
                </select>
              </div>
              <div className="col-lg-2">
                <div className='d-flex justify-content-end pt-2'>
                  <button type="submit" className="btn btn-primary">Update Service</button>
                </div>
              </div>
            </div>
          </div>

        </form>
      </div>
    </>
  )
}

export default EditService;