import React, { useContext, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import SignUp from './pages/signup/SignUp';
import Login from './pages/login/Login';
import Home from './pages/home/Home';
import Profile from './pages/profile/Profile';
import Post from './pages/post/Post';
import Jobs from './pages/jobs/Jobs';
import PostJob from './pages/jobs/PostJob';
import EditProfile from './pages/profile/edit-profile/EditProfile';
import CompleteProfile from './pages/signup/CompleteProfile';
import SearchPage from './pages/search/SearchPage';
import NotificationPage from './pages/notification/NotificationPage';
import MessagePage from './pages/message/MessagePage';
import ErrorPage from './pages/error/ErrorPage';
import BuyCode from './pages/buy-sell-code/BuyCode';
import ViewJob from './pages/jobs/ViewJob';
import ViewPost from './pages/post/ViewPost';
import ConfirmDeleteUser from './pages/delete-user/ConfirmDeleteUser';
import { mainTheme } from './components/context/ContextProvider';
import './UserTheme.css';
import './UserResponsive.css';
import GoogleAnalytics from './components/GoogleAnalytics';
import VerifyEmail from './pages/signup/VerifyEmail';
import ContactUs from './pages/contact-us/ContactUs';
import PostEditJob from './pages/profile/view-profile/ProfileEditJob';
import Service from './pages/service/Service';
import ViewSingleService from './pages/service/category/ViewSingleService';
import ServicePost from './pages/service/PostService';
import ServicesMainCategory from './pages/service/category/ServicesMainCategory';
import ServicesSubCategory from './pages/service/category/ServicesSubCategory';

const usePageViews = () => {
  let location = useLocation();
  useEffect(() => {
    GoogleAnalytics();
  }, [location]);
};

const UserRoutes = () => {
  usePageViews();
  const { pageTheme } = useContext(mainTheme);

  return (
    <div style={{ "width": "100%", "overflow": "hidden" }} className={pageTheme === "light_th" || !pageTheme ? "light_th" : (pageTheme === "dark_th" ? "dark_th" : "blue_th")}>
      <Routes>
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Home />} />
        <Route path="/:username" element={<Profile />} />
        <Route path="/post" element={<Post />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/post-job" element={<PostJob />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/message" element={<MessagePage />} />
        <Route path="/notification" element={<NotificationPage />} />
        <Route path="/complete-profile/:id" element={<CompleteProfile />} />
        <Route path="/buy-code" element={<BuyCode />} />
        <Route path="/view-job/:job_id" element={<ViewJob />} />
        <Route path="/view-post/:post_by/:post_id" element={<ViewPost />} />
        <Route path="/confirm-delete-user" element={<ConfirmDeleteUser />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/contact-us" element={<ContactUs/>} />
        <Route path="/job-edit" element={<PostEditJob/>} />
        
        <Route path="/service" element={<Service/>} />
        <Route path="/service/category/:main_cat" element={<ServicesMainCategory/>} />
        <Route path="/service/category/:main_cat/:sub_cat" element={<ServicesSubCategory/>} />
        <Route path="/view-service/:service_by/:service_id" element={<ViewSingleService/>} />
        <Route path="/post-service" element={<ServicePost/>} />

        {/* <Route path="*" element={<ErrorPage />} /> */}
      </Routes>
    </div>
  );
}

export default UserRoutes;
