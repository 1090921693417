import React, { useEffect, useState } from "react";
import SmallFooter from '../../components/footer/SmallFooter';
import './message.css';
import { getUserMessages, sendUserMessage, getUserChats, updateMsgStatus, chatById } from "../../../services/Apis";
import { BASE_URL } from "../../../services/helper";
import CommonSmallHeader from "../../components/header/small-screen/CommonSmallHeader";
import { formatDate2, isTokenValid } from "../../components/functions/OtherFunctions";
import WordLimit from "../../components/wordLimit/WordLimit";
import { useNavigate } from "react-router-dom";
import SmallHeader from "../../components/header/small-screen/SmallHeader";

const SMessage = () => {
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState("messages");
  const [userChats, setUserChats] = useState([]);
  const [chatData, setChatData] = useState({});
  const [chatReceiver, setChatReceiver] = useState("");
  const [chatSender, setChatSender] = useState("");
  const [msgs, setMsgs] = useState([]);
  const [fetchIntervalId, setFetchIntervalId] = useState(null);
  const [bolen, setBolen] = useState(false); // Renamed from isChatOpen to bolen
  const username = sessionStorage.getItem('user_username') || "";
  const user_id = sessionStorage.getItem('user_id') || "";

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        if (!valid) {
          navigate("/");
        }
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();
  }, []);

  const [loadingChats, setLoadingChats] = useState(true);
  const fetchUserChats = async () => {
    try {
      const res = await getUserChats(username, "");
      if (res.status === 200) {
        setUserChats(res.data);
      } else {
        console.log('Error in Fetching Chats.');
      }
    } catch (error) {
      console.error('Error fetching User Chats:', error);
    } finally {
      setLoadingChats(false);
    }
  };

  useEffect(() => {
    if (username) {
      fetchUserChats();
    }
  }, []);

  const singleChatData = async (id) => {
    try {
      const res = await chatById(id);
      if (res.status === 200) {
        setChatData(res.data);
      } else {
        console.log('Error in Fetching Chats.');
      }
    } catch (error) {
      console.error('Error fetching User Chats:', error);
    }
  }

  const openChat = (chat_id, receiver, sender) => {
    singleChatData(chat_id);
    setBolen(true); // Renamed from setIsChatOpen to setBolen
    setChatReceiver(receiver);
    setChatSender(sender);
    getMessages(sender, receiver);
    fetchUserChats(username);

    const intervalId = setInterval(() => {
      getMessages(sender, receiver);
      if (!bolen) {
        fetchUserChats();
      }
    }, 2000);
    setFetchIntervalId(intervalId);
  };

  const [loadingMsgs, setLoadingMgs] = useState(true);
  const getMessages = async (sender, receiver) => {
    try {
      const res = await getUserMessages(sender, receiver);
      if (res.status === 200) {
        setMsgs(res.data);
      } else {
        console.log("Error in Fetching User Messages.");
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    } finally {
      setLoadingMgs(false);
    }
  };

  const markMessageSeen = async () => {
    if (msgs.length > 0) {
      const unseenMessageIds = msgs.filter((msg) => (msg.status === 'unseen' && msg.receiver === username)).map((msg) => msg._id);
      if (unseenMessageIds.length > 0) {
        const config = { "Content-Type": "application/json" };
        const data = { messageIds: unseenMessageIds };
        await updateMsgStatus(data, config);
      }
    }
  }

  useEffect(() => {
    markMessageSeen();
  }, [msgs]);

  const closeChat = () => {
    setChatData({});
    setBolen(false); // Renamed from setIsChatOpen to setBolen
    setMsgs([]);
    clearInterval(fetchIntervalId);
  };

  const [text, setText] = useState("");
  const sentText = async () => {
    if (text !== "") {
      try {
        const data = username === chatSender
          ? { sender: chatSender, receiver: chatReceiver, content: text }
          : { sender: chatReceiver, receiver: chatSender, content: text };

        const config = { "Content-Type": "application/json" };
        const res = await sendUserMessage(data, config);
        if (res.status === 200) {
          setText("");
          getMessages(chatSender, chatReceiver);
        } else {
          alert("Some Unknown Error Occurred");
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  return (
    <>
      <SmallHeader />
      {activePage === "messages" ? (
        !bolen ? (  // Changed isChatOpen to bolen
          <div className="container-fluid mt-2" id="small-msg-chats">
            <div className="new">
              {loadingChats ? (
                // <p>Loading Chats....</p>
                <></>
              ) : (<>
                {userChats.length > 0 ? (<>
                  {userChats.map((ch, index) => (
                    <div className="row" key={index}>
                      <div className="col-12 d-flex" onClick={() => openChat(ch.chat._id, ch.chat.receiver, ch.chat.sender)}>
                        <div className="left-img">
                          {ch.chat.sender === username ? (
                            <img src={`${BASE_URL}/uploads/admins-users-img/${ch.chat.receiver_img}`} alt="Receiver" />
                          ) : (
                            <img src={`${BASE_URL}/uploads/admins-users-img/${ch.chat.sender_img}`} alt="Sender" />
                          )}
                        </div>
                        <div className="right-details row">
                          <div className="col-8">
                            <div className="top-details">
                              <div className='username'>{ch.chat.sender === username ? (ch.chat.receiver) : (ch.chat.sender)}</div>
                            </div>
                            <div className="bottom-details">
                              {ch.latestMsg ? <WordLimit text={ch.latestMsg.content} limit={40} /> : ''}
                            </div>
                          </div>
                          <div className="col-4 position-relative">
                            <div className="chat-date">{formatDate2(ch.chat.chat_created)}</div>
                            {ch.latestMsg && (ch.latestMsg.status === "unseen") && (ch.latestMsg.receiver_id === user_id) && (
                              <div className="unseen-chat bg-success"></div>
                            )}
                          </div>
                        </div>
                      </div>
                      <hr className='notif-separator' />
                    </div>
                  ))}
                </>) : (<p>No Chats to Show</p>)}
              </>)}
            </div>
            <SmallFooter />
          </div>
        ) : (
          <div className="container-fluid" id="small-page-topbar" style={{ "padding": "0", "height": "100vh" }}>
            <div className="row px-2">
              <div className="col-12 page-head" style={{ marginTop: "0px" }}>
                <div className='page-title'>
                  <div className="user-data">
                    {username === chatData.sender ? (
                      <img src={`${BASE_URL}/uploads/admins-users-img/${chatData.receiver_img}`} className='profile-img' alt="Receiver" />
                    ) : (
                      <img src={`${BASE_URL}/uploads/admins-users-img/${chatData.sender_img}`} className='profile-img' alt="Sender" />
                    )}
                    <div className="d-flex">
                      <h2>{chatReceiver === username ? (chatSender) : (chatReceiver)}
                        {/* <p>Last seen: 4 hours ago</p> */}
                      </h2>
                    </div>
                  </div>
                  <div className="close-chat">
                    <button type="button" onClick={closeChat}><i className="fa-solid fa-xmark"></i></button>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid" id="small-msg">
              <div className="msg-body">
                {loadingMsgs ? (
                  // <p>Loading Messages...</p>
                  <></>
                ) : (
                  <>
                    {msgs.length > 0 ? (
                      <>
                        {msgs.map((msg, index) => (
                          <div key={index}>
                            {msg.sender !== username ? (
                              <div className="sender mt-2">
                                <div>
                                  {username === chatData.sender ? (
                                    <img src={`${BASE_URL}/uploads/admins-users-img/${chatData.receiver_img}`} width="30px" height="30px" style={{ "borderRadius": "50%" }} alt="Receiver" />
                                  ) : (
                                    <img src={`${BASE_URL}/uploads/admins-users-img/${chatData.sender_img}`} width="30px" height="30px" style={{ "borderRadius": "50%" }} alt="Sender" />
                                  )}
                                </div>
                                <div className="get-msg">{msg.content}
                                  <span>3:44pm </span>
                                </div>
                              </div>
                            ) : (
                              <div className="receiver mt-2">
                                <div className="send-msg">{msg.content}
                                  <span>3:44pm </span>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </>
                    ) : (<></>)}
                  </>
                )}
              </div>
              <div className="input-group bottom_footer_message mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type your message here"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
                <div className="file_btn">
                  {/* <button type="" ><svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.3829 1.18105L3.33971 9.22481C2.42563 10.139 1.91211 11.3789 1.91211 12.6717C1.91211 13.9645 2.42563 15.2044 3.33971 16.1186C3.7924 16.5713 4.32982 16.9304 4.92128 17.1754C5.51275 17.4204 6.14668 17.5465 6.78688 17.5465C7.42708 17.5465 8.06101 17.4204 8.65248 17.1754C9.24394 16.9304 9.78136 16.5713 10.234 16.1186L19.8096 6.54355C20.1115 6.24176 20.3509 5.88346 20.5143 5.48911C20.6776 5.09477 20.7617 4.67211 20.7617 4.24526C20.7617 3.81842 20.6776 3.39576 20.5143 3.00141C20.3509 2.60707 20.1115 2.24877 19.8096 1.94697C19.5078 1.64512 19.1495 1.40568 18.7552 1.24232C18.3608 1.07896 17.9382 0.994873 17.5113 0.994873C17.0845 0.994873 16.6618 1.07896 16.2675 1.24232C15.8731 1.40568 15.5148 1.64512 15.213 1.94697L5.63801 11.5226C5.48708 11.6735 5.36736 11.8526 5.28568 12.0498C5.204 12.2469 5.16196 12.4583 5.16196 12.6717C5.16196 12.8851 5.204 13.0965 5.28568 13.2936C5.36736 13.4908 5.48708 13.6699 5.63801 13.8208C5.94274 14.1255 6.35599 14.2966 6.78688 14.2966C7.21777 14.2966 7.63102 14.1255 7.93576 13.8208L15.9795 5.7771" stroke="#272D3B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>

                  </button>
                  <button type=""><svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.59267 20.6251H13.407C16.7881 20.6251 18.4792 20.6251 19.6936 19.7982C20.2177 19.4417 20.6691 18.9814 21.0218 18.4437C21.8332 17.2062 21.8332 15.4816 21.8332 12.0346C21.8332 8.58755 21.8332 6.86293 21.0218 5.62543C20.6691 5.08767 20.2177 4.62739 19.6936 4.27093C18.9136 3.7388 17.9364 3.54868 16.4403 3.48118C15.7264 3.48118 15.1122 2.92993 14.9724 2.21555C14.8656 1.69228 14.5881 1.22334 14.1868 0.887997C13.7856 0.552651 13.2851 0.371462 12.77 0.375052H9.22967C8.15934 0.375052 7.23742 1.14568 7.02725 2.21555C6.8875 2.92993 6.27325 3.48118 5.55934 3.48118C4.06434 3.54868 3.08717 3.73993 2.30609 4.27093C1.78234 4.62751 1.33138 5.08778 0.979004 5.62543C0.166504 6.86293 0.166504 8.58643 0.166504 12.0346C0.166504 15.4827 0.166504 17.2051 0.977921 18.4437C1.32892 18.9792 1.77959 19.4393 2.30609 19.7982C3.5205 20.6251 5.21159 20.6251 8.59267 20.6251ZM10.9998 7.43218C8.50709 7.43218 6.48559 9.49205 6.48559 12.0334C6.48559 14.5748 8.50817 16.6381 10.9998 16.6381C13.4915 16.6381 15.5141 14.5771 15.5141 12.0357C15.5141 9.4943 13.4915 7.43218 10.9998 7.43218ZM10.9998 9.27268C9.50484 9.27268 8.2915 10.5091 8.2915 12.0346C8.2915 13.5589 9.50484 14.7953 10.9998 14.7953C12.4948 14.7953 13.7082 13.5589 13.7082 12.0346C13.7082 10.5102 12.4948 9.27268 10.9998 9.27268ZM16.1153 8.35243C16.1153 7.84393 16.5194 7.43218 17.0188 7.43218H18.2213C18.7197 7.43218 19.1248 7.84393 19.1248 8.35243C19.1225 8.59869 19.0262 8.83393 18.857 9.00648C18.6878 9.17903 18.4596 9.27478 18.2224 9.27268H17.0188C16.9013 9.27387 16.7847 9.251 16.6757 9.2054C16.5667 9.15979 16.4674 9.09234 16.3835 9.00688C16.2996 8.92143 16.2328 8.81965 16.1868 8.70736C16.1407 8.59507 16.1165 8.47446 16.1153 8.35243Z" fill="#272D3B" />
                  </svg>
                  </button> */}
                  <button className="btn btn-outline-secondary" type="button" onClick={sentText}><svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="22.5" cy="22.5" r="22.5" fill="#272D3B" />
                    <path d="M31.9635 14.5181C32.46 13.1448 31.1292 11.8141 29.7559 12.3117L12.9719 18.3817C11.5941 18.8805 11.4274 20.7605 12.695 21.4949L18.0525 24.5965L22.8366 19.8125C23.0534 19.6031 23.3437 19.4873 23.645 19.4899C23.9463 19.4925 24.2345 19.6134 24.4476 19.8265C24.6607 20.0395 24.7815 20.3278 24.7841 20.6291C24.7868 20.9304 24.6709 21.2207 24.4616 21.4374L19.6775 26.2215L22.7803 31.579C23.5135 32.8466 25.3936 32.6788 25.8923 31.3021L31.9635 14.5181Z" fill="white" />
                  </svg>
                  </button>

                </div>

              </div>
            </div>
          </div>
        )
      ) : null}
    </>
  );
};

export default SMessage;
