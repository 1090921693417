import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAllServices, changeServiceFeatured, changeServiceStatus, deleteService } from '../../../../services/Apis';
import { BASE_URL } from '../../../../services/helper';
import Dropdown from "react-bootstrap/Dropdown";
import Badge from "react-bootstrap/Badge";
import { NavLink } from 'react-router-dom';
import AccessChecker from '../../../components/OtherFunctions/AccessChecker';
import '../services.css';

const ViewServices = () => {

  const [services, setServices] = useState([]);
  const fetchAllServices = async () => {
    try {
      const res = await getAllServices();
      if (res.status === 200) {
        setServices(res.data);
      } else {
        console.log('Error in Fetching Services.');
      }
    } catch (error) {
      console.error('Error fetching Services:', error);
    }
  };

  useEffect(() => {
    fetchAllServices();
  }, []);

  const featuredChange = async (id, data) => {
    const response = await changeServiceFeatured(id, data);
    if (response.status === 200) {
      fetchAllServices();
    }
    else {
      alert("Cannot Change Featured Service");
    }
  }

  const statusChange = async (id, data) => {
    const response = await changeServiceStatus(id, data);
    if (response.status === 200) {
      fetchAllServices();
    }
    else {
      alert("Cannot Change Featured Service");
    }
  }

  const handleDeleteSubCat = async (id) => {
    const response = await deleteService(id);
    if (response.status === 200) {
      fetchAllServices();
    }
    else {
      alert("Cannot Delete Service");
    }
  }

  return (
    <>
      <AccessChecker accessKey="view_users" />
      <div className='form-card expand-card' id="view-services-cats">
        <div className="row">
          <div className="col-md-12 form-head d-flex justify-content-between">
            <h1>View Services</h1>
            <div><Link to="/admin/add-service"><button className='btn btn-primary'>Add Service</button></Link></div>
          </div>
        </div>
        <div>
          <hr />
          <table class="table table-bordered" style={{ width: '90%', margin: 'auto', marginTop: '30px' }}>
            <thead>
              <th className='text-left'>Sl No.</th>
              <th>Image <br/>(Click to View)</th>
              <th>Main Category</th>
              <th>Sub Category</th>
              <th>User</th>
              <th>Price</th>
              <th>Featured</th>
              <th>Status</th>
              <th>Action</th>
            </thead>

            <tbody>
              {services.map((service, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td><a href={`${BASE_URL}/uploads/services-img/${service.service_img}`} target='_blank'><img src={`${BASE_URL}/uploads/services-img/${service.service_img}`} /></a></td>
                  <td>{service.main_cat_title}</td>
                  <td>{service.sub_cat_title}</td>
                  <td>{service.username}</td>
                  <td>Rs{service.service_price}</td>
                  <td>
                    <Dropdown className="text-center">
                      <Dropdown.Toggle className="dropdown_btn" id="dropdown-basic">
                        <Badge bg={service.featured ? "primary" : "danger"}>
                          {service.featured ? "Yes" : "No"} <i className="fa-solid fa-angle-down"></i>
                        </Badge>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => featuredChange(service._id, true)}>Yes</Dropdown.Item>
                        <Dropdown.Item onClick={() => featuredChange(service._id, false)}>No</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td>
                    <Dropdown className="text-center">
                      <Dropdown.Toggle className="dropdown_btn" id="dropdown-basic">
                        <Badge bg={service.status == "active" ? "primary" : "danger"}>
                          {service.status === "active" ? "Active" : "InActive"} <i className="fa-solid fa-angle-down"></i>
                        </Badge>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {/* <Dropdown.Item onClick={() => statusChange(post._id, "active")}>Active</Dropdown.Item>
                        <Dropdown.Item onClick={() => statusChange(post._id, "inactive")}>InActive</Dropdown.Item> */}
                        <Dropdown.Item onClick={() => statusChange(service._id, "active")}>Active</Dropdown.Item>
                        <Dropdown.Item onClick={() => statusChange(service._id, "inactive")}>InActive</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td>
                    <Dropdown className="text-center">
                      <Dropdown.Toggle variant="light" className="action" id="dropdown-basic">
                        <i className="fa-solid fa-ellipsis-vertical"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <NavLink to={`/admin/view-single-service/${service._id}`} className="text-decoration-none">
                            <i className="fa-solid fa-eye" style={{ color: "green" }}></i> <span>View</span>
                          </NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <NavLink to={`/admin/edit-service/${service._id}`} className="text-decoration-none">
                            <i className="fa-solid fa-pen-to-square" style={{ color: "blue" }}></i> <span>Edit</span>
                          </NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div onClick={() => handleDeleteSubCat(service._id)}><i className="fa-solid fa-trash" style={{ color: "red" }}></i> <span>Delete</span></div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>

          </table>
        </div>

      </div>
    </>
  )
}

export default ViewServices;