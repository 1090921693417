import React, { useState, useEffect } from 'react'
import HeaderTop from '../../components/header/large-screen/HeaderTop'
import Footer from '../../components/footer/Footer'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getSingleJob } from '../../../services/Apis';
import { BASE_URL } from '../../../services/helper';
import SmallFooter from '../../components/footer/SmallFooter';
import SmallHeader from '../../components/header/small-screen/SmallHeader';

const ViewJob = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 700);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 700);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [jobData, setJobData] = useState({
    username: "",
    title: "",
    description: "",
    location: "",
    workplace_type: "",
    designer_type: "",
    employment_type: "",
    apply_link: "",
    company_name: "",
    company_logo: "",
    website: "",
    offer_date: "",
    status: ""
  });

  const { job_id } = useParams();
  const singleJobData = async () => {
    const response = await getSingleJob(job_id);
    if (response.status === 200) {
      setJobData(response.data.singleJob);
    }
    else {
      console.log("Error in Fetching Job Details.");
    }
  }

  useEffect(() => {
    singleJobData();
  }, [])

  return (
    <>
      {isSmallScreen ? (<SmallHeader />) : (<HeaderTop />)}

      <div className="container-fluid" id="view-user-job">

        <div className="container">

          <div className="job_tab_right_content">
            <div className="right_title">
              <h2>
                {jobData.title}
                <span>
                  {jobData.workplace_type === "remote" ? "Remotely" :
                    jobData.workplace_type === "on_site" ? "On-Site" :
                      jobData.workplace_type === "hybrid" ? "Hybrid" :
                        "Not Specified"}
                </span>
              </h2>
            </div>

            <div className="company_deacrip d-flex gap-2 align-items-center">
              <img src={`${BASE_URL}/uploads/jobs-img/${jobData.company_logo}`} alt="" width="30px" height="30px" style={{ "borderRadius": "50%" }} />
              <h2>
                {jobData.company_name}
                <span>
                  <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <path d="M11.1622 17.1489L18.0242 21.2905L16.2032 13.4847L22.2658 8.23269L14.2823 7.55537L11.1622 0.19368L8.04208 7.55537L0.0585938 8.23269L6.12116 13.4847L4.30017 21.2905L11.1622 17.1489Z" fill="black" />
                  </svg>
                </span>
              </h2>
            </div>

            <a className="apply_btn" href={jobData.apply_link} target="_blank">Apply now</a>

            <hr />
            <div className="job_decription">
              <h3>Job Details</h3>
              <div className="decrip_tag">
                {jobData.pay ? (<>
                  <span>
                    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg" >
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.452 6.60403C2.15013 6.4791 1.8267 6.41455 1.5 6.41403V4.58603C2.16166 4.58497 2.79591 4.32166 3.26377 3.8538C3.73163 3.38594 3.99494 2.75168 3.996 2.09003H9.09C9.09 2.75307 9.35339 3.38895 9.82223 3.85779C10.2911 4.32663 10.927 4.59003 11.59 4.59003V6.42603C10.9321 6.42917 10.302 6.69148 9.8363 7.1561C9.37056 7.62071 9.10673 8.25018 9.102 8.90803H3.995C3.9939 8.41458 3.84679 7.93249 3.57221 7.52249C3.29764 7.11249 2.90787 6.79292 2.452 6.60403ZM0 1.09003C0 0.957419 0.0526785 0.830242 0.146447 0.736474C0.240215 0.642705 0.367392 0.590027 0.5 0.590027H12.59C12.7226 0.590027 12.8498 0.642705 12.9436 0.736474C13.0373 0.830242 13.09 0.957419 13.09 1.09003V9.90803C13.09 10.0406 13.0373 10.1678 12.9436 10.2616C12.8498 10.3553 12.7226 10.408 12.59 10.408H0.5C0.367392 10.408 0.240215 10.3553 0.146447 10.2616C0.0526785 10.1678 0 10.0406 0 9.90803V1.09003ZM6.544 7.50003C7.07443 7.50003 7.58314 7.28931 7.95821 6.91424C8.33329 6.53917 8.544 6.03046 8.544 5.50003C8.544 4.96959 8.33329 4.46089 7.95821 4.08581C7.58314 3.71074 7.07443 3.50003 6.544 3.50003C6.01357 3.50003 5.50486 3.71074 5.12979 4.08581C4.75471 4.46089 4.544 4.96959 4.544 5.50003C4.544 6.03046 4.75471 6.53917 5.12979 6.91424C5.50486 7.28931 6.01357 7.50003 6.544 7.50003ZM14.6 4.80003V11.91H2.76C2.62739 11.91 2.50021 11.9627 2.40645 12.0565C2.31268 12.1502 2.26 12.2774 2.26 12.41V12.91C2.26 13.0426 2.31268 13.1698 2.40645 13.2636C2.50021 13.3573 2.62739 13.41 2.76 13.41H15.423C15.865 13.41 16.1 13.188 16.1 12.747V4.80003C16.1 4.66742 16.0473 4.54024 15.9536 4.44647C15.8598 4.35271 15.7326 4.30003 15.6 4.30003H15.1C14.9674 4.30003 14.8402 4.35271 14.7464 4.44647C14.6527 4.54024 14.6 4.66742 14.6 4.80003Z" fill="#545454" fill-opacity="0.72" />
                    </svg>
                    Pay
                  </span>
                  <p className="">{jobData.pay}</p>
                </>) : ('')}
              </div>

              <div className="decrip_tag">
                <span>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 3C7 3 6 6 6 6H2.5C2.36739 6 2.24021 6.05268 2.14645 6.14645C2.05268 6.24021 2 6.36739 2 6.5V9H18V6.5C18 6.36739 17.9473 6.24021 17.8536 6.14645C17.7598 6.05268 17.6326 6 17.5 6H14C14 6 13 3 10 3ZM12.5 6H7.5C7.5 6 8.5 4.5 10 4.5C11.5 4.5 12.5 6 12.5 6Z" fill="#545454" fill-opacity="0.72" />
                    <path d="M8 11H2V16.5C2 16.6326 2.05268 16.7598 2.14645 16.8536C2.24021 16.9473 2.36739 17 2.5 17H17.5C17.6326 17 17.7598 16.9473 17.8536 16.8536C17.9473 16.7598 18 16.6326 18 16.5V11H12C12 12 11 13 10 13C9 13 8 12 8 11Z" fill="#545454" fill-opacity="0.72" />
                  </svg>
                  Job type
                </span>
                <p className="">{jobData.employment_type == "fulltime" ? "Full Time" : "Freelance / Contract Hire"}</p>
              </div>

              <div className="decrip_tag">
                <span>
                  <svg width="15" height="20" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 12.9993L5.97325 12.4674C6.51025 11.8544 6.9935 11.2712 7.423 10.7176L7.77775 10.2508C9.25925 8.26039 10 6.68119 10 5.51318C10 3.02057 7.9855 1 5.5 1C3.0145 1 1 3.02057 1 5.51318C1 6.68119 1.74075 8.26064 3.22225 10.2515L3.577 10.7183C4.19016 11.5018 4.83158 12.2621 5.5 12.9993Z" stroke="#545454" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M5.5 7.375C6.53553 7.375 7.375 6.53553 7.375 5.5C7.375 4.46447 6.53553 3.625 5.5 3.625C4.46447 3.625 3.625 4.46447 3.625 5.5C3.625 6.53553 4.46447 7.375 5.5 7.375Z" stroke="#545454" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  Location
                </span>
                <p className="">{jobData.location}</p>
              </div>
              <hr />
              <h3>Full job description</h3>

              <p className="decription_content" dangerouslySetInnerHTML={{ __html: jobData.description }} ></p>

            </div>
          </div>
        </div>
      </div>
      {isSmallScreen ? (<SmallFooter />) : ("")}

    </>
  )
}

export default ViewJob