import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import DeletePopup from "../../../../../public/assets/images/delete-popup.svg"

const DeleteJobComponent = () => {
  // State to control the popup visibility
  const [showPopup, setShowPopup] = useState(false);

  // Use `useEffect` to add/remove the `no-scroll` class on the body when the popup is shown
  useEffect(() => {
    if (showPopup) {
      // Add the no-scroll class to the body element
      document.body.classList.add('no-scroll');
    } else {
      // Remove the no-scroll class from the body element
      document.body.classList.remove('no-scroll');
    }

    // Clean up when the component unmounts or showPopup changes
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [showPopup]);

  // Function to open the popup
  const handleOpenPopup = (event) => {
    event.preventDefault(); // Prevent the default link behavior
    setShowPopup(true);
  };

  // Function to close the popup
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  return (
    <div>
      {/* Link that triggers the popup */}
      <Link to="/" onClick={handleOpenPopup} className="delete-link">
      <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.8125 17C2.29687 17 1.85562 16.8152 1.48875 16.4456C1.12187 16.076 0.938125 15.6312 0.9375 15.1111V2.83333H0V0.944444H4.6875V0H10.3125V0.944444H15V2.83333H14.0625V15.1111C14.0625 15.6306 13.8791 16.0754 13.5122 16.4456C13.1453 16.8158 12.7038 17.0006 12.1875 17H2.8125ZM4.6875 13.2222H6.5625V4.72222H4.6875V13.2222ZM8.4375 13.2222H10.3125V4.72222H8.4375V13.2222Z" fill="#272D3B"/>
</svg>
    Delete Job
      </Link>

      {/* Modal Popup */}
      {showPopup && (
    <div id='deletePopup'> 
            <div className="overlay">
          <div className="popup">
            <img src={DeletePopup} alt="" />
            <h3>Delete the job</h3>
            <h2>Are you sure you want to delete this job?</h2>
            <div className="button-group">
              {/* Confirm and Cancel buttons */}
              <button onClick={handleClosePopup} className="button cancel">
                Cancel
              </button>
              <button
                onClick={() => {
                  alert('Job Deleted!'); // Simulate job deletion
                  setShowPopup(false);
                }}
                className="button confirm"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
    </div>
      )}
    </div>
  );
};

export default DeleteJobComponent;
