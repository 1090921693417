import React, { useState } from "react";
import NotInterstd from "../../../../../public/assets/images/sad.svg";
import FReport from "../../../../../public/assets/images/f-report.svg";
import Block from "../../../../../public/assets/images/block.svg";
import ReportPost from "../../../../../public/assets/images/report.svg";
import ReportContent from "./ReportContent";

const ReportComponent = ({post_id, post_username, isValid}) => {
  // State to manage popup visibility
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  // Function to toggle popup visibility
  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  return (
    <div>
      {/* SVG icon with onClick event to toggle popup */}
      <div className="report_opt" onClick={togglePopup}>
        <svg
          width="4"
          height="12"
          viewBox="0 0 4 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.3125 10.375C3.3125 10.7231 3.17422 11.0569 2.92808 11.3031C2.68194 11.5492 2.3481 11.6875 2 11.6875C1.6519 11.6875 1.31806 11.5492 1.07192 11.3031C0.825781 11.0569 0.6875 10.7231 0.6875 10.375C0.6875 10.0269 0.825781 9.69306 1.07192 9.44692C1.31806 9.20078 1.6519 9.0625 2 9.0625C2.3481 9.0625 2.68194 9.20078 2.92808 9.44692C3.17422 9.69306 3.3125 10.0269 3.3125 10.375ZM3.3125 6C3.3125 6.3481 3.17422 6.68194 2.92808 6.92808C2.68194 7.17422 2.3481 7.3125 2 7.3125C1.6519 7.3125 1.31806 7.17422 1.07192 6.92808C0.825781 6.68194 0.6875 6.3481 0.6875 6C0.6875 5.6519 0.825781 5.31806 1.07192 5.07192C1.31806 4.82578 1.6519 4.6875 2 4.6875C2.3481 4.6875 2.68194 4.82578 2.92808 5.07192C3.17422 5.31806 3.3125 5.6519 3.3125 6ZM3.3125 1.625C3.3125 1.9731 3.17422 2.30694 2.92808 2.55308C2.68194 2.79922 2.3481 2.9375 2 2.9375C1.6519 2.9375 1.31806 2.79922 1.07192 2.55308C0.825781 2.30694 0.6875 1.9731 0.6875 1.625C0.6875 1.2769 0.825781 0.943064 1.07192 0.696922C1.31806 0.450781 1.6519 0.3125 2 0.3125C2.3481 0.3125 2.68194 0.450781 2.92808 0.696922C3.17422 0.943064 3.3125 1.2769 3.3125 1.625Z"
            fill="#545454"
          />
        </svg>
      </div>

      {isPopupVisible && (
        <div className="report-popup">
          {/* <button className="report_cause">
            {" "}
            <img src={NotInterstd} alt="" /> <p>Not interseted in this post</p>
          </button> */}
          <button className="report_cause">
            {" "}
            <img src={FReport} alt="" /> <p>Folow @{post_username}</p>
          </button>
          <button className="report_cause">
            {" "}
            <img src={FReport} alt="" /> <p>Message @{post_username}</p>
          </button>
          <button className="report_cause">
            {" "}
            <img src={FReport} alt="" /> <p>View @{post_username}</p>
          </button>
          {/* <button className="report_cause">
            {" "}
            <img src={Block} alt="" /> <p>Block @{post_username}</p>
          </button> */}
          <ReportContent />
          <button className="closeBtn" onClick={togglePopup}>
            Close
          </button>
        </div>
      )}
    </div>
  );
};

export default ReportComponent;
