import React, { useEffect, useState, useContext, useRef } from "react";
import light_logo from "../../assets/images/yoeavnu_logo.png";
import dark_logo from "../../assets/images/dark_logo.png";
import { mainTheme } from "../../components/context/ContextProvider";
import { NavLink, useNavigate } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { FaBell } from "react-icons/fa";
import {
  getUserMessages,
  sendUserMessage,
  getUserChats,
  updateMsgStatus,
  chatById,
  getSingleUser,
  getSingleUserUnseenNotifs,
  updateNotifStatus,
} from "../../../services/Apis";
import { BASE_URL } from "../../../services/helper";
import {
  formatDate2,
  isTokenValid,
} from "../../components/functions/OtherFunctions";
import { FaSearch } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import admin_icon from "../../assets/notif-imgs/admin.png";
import comment_icon from "../../assets/notif-imgs/comment.png";
import like_icon from "../../assets/notif-imgs/like.png";
import follower_icon from "../../assets/notif-imgs/follower.png";
import TimeAgoConverter from "../../components/functions/TimeAgoConverter";
import WordLimit from "../../components/wordLimit/WordLimit";
import HeaderTop from "../../components/header/large-screen/HeaderTop";
import SubmitButton from "../../../../public/assets/images/submit.png";
import { commonSidebar } from "../../components/context/ContextProvider";
import HomeSidebar from "../home/large-view/HomeSidebar";
const LMessage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        if (!valid) {
          navigate("/");
        }
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();
  }, []);

  const { pageTheme, setPageTheme } = useContext(mainTheme);
  const username = sessionStorage.getItem("user_username") || "";
  const user_id = sessionStorage.getItem("user_id") || "";
  const chatMessagesRef = useRef(null); // Ref for chat messages div
  const [userChats, setUserChats] = useState([]);
  const [chatSearchKey, setChatSearchKey] = useState("");
  const [chatData, setChatData] = useState({});
  const [chatReceiver, setChatReceiver] = useState("");
  const [chatSender, setChatSender] = useState("");
  const [msgs, setMsgs] = useState([]);
  const [fetchIntervalId, setFetchIntervalId] = useState(null);
  const [isChatOpen, setIsChatOpen] = useState(false);

  //<------------------------------------------------- To Fetch Current User Chats ------------------------------------------------->
  const fetchUserChats = async () => {
    try {
      const res = await getUserChats(username, chatSearchKey);
      if (res.status === 200) {
        setUserChats(res.data);
      } else if (res.response.status === 404) {
        console.log("status 404");
        setUserChats([]);
      } else {
        console.log("Error in Fetching Chats.");
      }
    } catch (error) {
      console.error("Error fetching User Chats:", error);
    }
  };

  useEffect(() => {
    if (username) {
      fetchUserChats();
    }
  }, [chatSearchKey]);

  //<------------------------------------------------- To Fetch Selected single Chat Data ------------------------------------------------->
  const singleChatData = async (id) => {
    try {
      const res = await chatById(id);
      if (res.status === 200) {
        setChatData(res.data);
      } else {
        console.log("Error in Fetching Chats.");
      }
    } catch (error) {
      console.error("Error fetching User Chats:", error);
    }
  };

  //<------------------------------------------------- To Handle Chat is Opened ------------------------------------------------->
  const openChat = (chat_id, receiver, sender) => {
    if (isChatOpen) {
      setChatData({});
      setMsgs([]);
      clearInterval(fetchIntervalId);
    }
    singleChatData(chat_id);
    setIsChatOpen(true);
    setChatReceiver(receiver);
    setChatSender(sender);
    getMessages(sender, receiver);
    fetchUserChats();

    const intervalId = setInterval(() => {
      getMessages(sender, receiver);
      fetchUserChats();
    }, 2000);
    setFetchIntervalId(intervalId);
  };

  //<------------------------------------------------- To Get Message of Each Chat ------------------------------------------------->
  const getMessages = async (sender, receiver) => {
    try {
      const res = await getUserMessages(sender, receiver);
      if (res.status === 200) {
        setMsgs(res.data);
      } else {
        console.log("Error in Fetching User Messages.");
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  //<------------------------------------------------- To Mark Message as Seen for Each Chat ------------------------------------------------->
  const markMessageSeen = async () => {
    if (msgs.length > 0) {
      const unseenMessageIds = msgs
        .filter((msg) => msg.status === "unseen" && msg.receiver === username)
        .map((msg) => msg._id);
      if (unseenMessageIds.length > 0) {
        const config = { "Content-Type": "application/json" };
        const data = { messageIds: unseenMessageIds };
        await updateMsgStatus(data, config);
      }
    }
  };

  useEffect(() => {
    markMessageSeen();
  }, [msgs]);

  //<------------------------------------------------- To Handle Chat is Closed ------------------------------------------------->
  const closeChat = () => {
    setIsChatOpen(false);
    setMsgs([]);
    setChatData({});
    clearInterval(fetchIntervalId);
  };

  //<------------------------------------------------- To Handle when message is sent ------------------------------------------------->
  const [text, setText] = useState("");

  const sentText = async () => {
    if (text !== "") {
      try {
        var data;
        if (username === chatSender) {
          data = { sender: chatSender, receiver: chatReceiver, content: text };
        } else {
          data = { sender: chatReceiver, receiver: chatSender, content: text };
        }
        const config = { "Content-Type": "application/json" };
        const res = await sendUserMessage(data, config);
        if (res.status === 200) {
          setText("");
          getMessages(chatSender, chatReceiver);
        } else {
          alert("Some Unknown Error Occurred");
        }
      } catch (error) {
        console.error("Error:", error.res.data);
      }
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [msgs]);

  const scrollToBottom = () => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    }
  };

  const [userData, setUserData] = useState({});
  const userProfileGet = async () => {
    try {
      const res = await getSingleUser(username);
      if (res.status === 200) {
        setUserData(res.data);
      } else {
        console.log("Error in Fetching User Details.");
      }
    } catch (error) {
      console.error("Error in Fetching User Details:", error);
    }
  };

  useEffect(() => {
    userProfileGet();
  }, [username]);

  const [notifs, setNotifs] = useState([]);
  const fetchAllNotifs = async () => {
    try {
      const res = await getSingleUserUnseenNotifs(username);
      if (res.status === 200) {
        setNotifs(res.data);
      } else {
        console.log("Error in Fetching User Details.");
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchAllNotifs();
      // await fetchUserChats();
    };
    fetchData(); // Call initially
    const interval = setInterval(fetchData, 1000); // Call every second
    return () => clearInterval(interval); // Cleanup
  }, [username]);

  const seenStatusChange = async (id) => {
    const response = await updateNotifStatus(id);
    if (response.status === 200) {
      fetchAllNotifs();
    } else {
      alert("Cannot Update Notif Status");
    }
  };

  const handleNotifClick = (notif_id, link) => {
    seenStatusChange(notif_id);
    navigate(link);
  };
  const checkAndNavigate = (navigateTo) => {
    if (isValid) { navigate(navigateTo); }
    else {
      // alert("Please Login First");
      // navigate("/login");
      toast.error('Please Login First', {
        onClose: () => navigate('/login')
      });
    }}
    const { showMenu, setShowMenu } = useContext(commonSidebar);

  return (
    <>
      <div className="container-fluid" id="large-msg">
        {/* Page Header Start */}
        {/* <div className="page-header">
          <div className="row">
            <NavLink to="/" className="col-lg-2 logo">
              {pageTheme == "blue_th" || pageTheme == "dark_th" ? (<img src={dark_logo} alt="Company Logo" width="150px" />) : (<img src={light_logo} alt="Company Logo" width="150px" />)}
            </NavLink>
            <div className="col-lg-10 head-nav">
              <NavLink to="/"><AiFillHome className='nav-icon' /></NavLink>
              <Dropdown className="header-notification position-relative">
                <Dropdown.Toggle className='sidebar-icon'>
                  <FaBell className='nav-icon' />
                  {notifs.length > 0 ? (<div className='new-notif-alert bg-danger'></div>) : ('')}
                </Dropdown.Toggle>
                <Dropdown.Menu className="notif-dropdown">
                  <div className='drop-head'>Notifications</div>
                  {notifs.length > 0 ? (
                    notifs.map((notif, index) => (
                      <React.Fragment key={index}>
                        <ul>
                          <li onClick={() => handleNotifClick(notif._id, notif.redirect_link)}>
                            <div className='left-notif-div'>
                              {notif.sender_type === "admin" ? (
                                <img src={admin_icon} alt="profile-pic" width="40px" height="40px" className='rounded-circle' />
                              ) : notif.sender_type === "comments" ? (
                                <img src={comment_icon} alt="profile-pic" width="40px" height="40px" className='rounded-circle' />
                              ) : notif.sender_type === "follows" ? (
                                <img src={follower_icon} alt="profile-pic" width="40px" height="40px" className='rounded-circle' />
                              ) : (
                                <img src={like_icon} alt="profile-pic" width="40px" height="40px" className='rounded-circle' />
                              )}
                            </div>
                            <div className='right-notif-div'>
                              <p>{notif.content}</p>
                              <p><TimeAgoConverter dateTime={notif.sent_date} /></p>
                            </div>
                          </li>
                        </ul>
                        <hr style={{ margin: "0" }} />
                      </React.Fragment>
                    ))
                  ) : (
                    <div className='no-new-msg'>No New Notifications</div>
                  )}
                </Dropdown.Menu>
              </Dropdown>
              <NavLink to={`/${userData.username}`}><img src={`${BASE_URL}/uploads/admins-users-img/${userData.user_img}`} /></NavLink>
            </div>
          </div>
        </div> */}
        <HeaderTop />
        <HomeSidebar showMenu={showMenu} checkAndNavigate={checkAndNavigate}/>

        {/* Page Header End */}
        <div className="row msg-wrap" style={showMenu ? { filter: "blur(2px)" } : {}}>
          <div className="col-lg-3 left-div">
            <div className="head">All Messages</div>

            <div className="search-div">
              {/* Search for chat */}

              <input
                placeholder="Search..."
                className="search-input"
                value={chatSearchKey}
                onChange={(e) => setChatSearchKey(e.target.value)}
              />
              <i class="fa-solid fa-magnifying-glass"></i>
            </div>
            <hr style={{ marginBottom: "30px" }} />

            {userChats.length > 0 ? (
              userChats.map((ch, index) => (
                <>
                  <div
                    className="chats-wrap"
                    onClick={() =>
                      openChat(ch.chat._id, ch.chat.receiver, ch.chat.sender)
                    }
                  >
                    <div className="col-lg-3 d-flex justify-content-center">
                      {ch.chat.sender === username ? (
                        <img
                          src={`${BASE_URL}/uploads/admins-users-img/${ch.chat.receiver_img}`}
                          alt="Receiver"
                          onClick={
                            ch.chat.receiver !== "yoeavnu_admin"
                              ? () => navigate(`/${ch.chat.receiver}`)
                              : undefined
                          }
                        />
                      ) : (
                        <img
                          src={`${BASE_URL}/uploads/admins-users-img/${ch.chat.sender_img}`}
                          onClick={
                            ch.chat.sender !== "yoeavnu_admin"
                              ? () => navigate(`/${ch.chat.sender}`)
                              : undefined
                          }
                        />
                      )}
                    </div>
                    <div className="col-lg-6 d-grid">
                      <div className="user-name">
                        {ch.chat.sender === username
                          ? ch.chat.receiver
                          : ch.chat.sender}
                      </div>
                      <div className="last-msg">
                        {ch.latestMsg ? (
                          <WordLimit text={ch.latestMsg.content} limit={40} />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-lg-3 position-relative">
                      <div className="chat-date">
                        {formatDate2(ch.chat.chat_created)}
                      </div>
                      {ch.latestMsg &&
                        ch.latestMsg.status === "unseen" &&
                        ch.latestMsg.receiver_id === user_id && (
                          <div className="new-chat"></div>
                        )}
                    </div>
                  </div>
                  <div className="chat-separator"></div>
                </>
              ))
            ) : (
              <p className="no-chats">No Chats Found</p>
            )}
          </div>
          <div className="right-div col-lg-9">
            {isChatOpen ? (
              <>
                <div className="open-chat">
                  <div className="open-chat-header">
                    <div className="user-details">
                      <div className="user-pic">
                        {chatData.sender_id === user_id ? (
                          <img
                            src={`${BASE_URL}/uploads/admins-users-img/${chatData.receiver_img}`}
                            onClick={() => navigate(`/${chatData.receiver}`)}
                          />
                        ) : (
                          <img
                            src={`${BASE_URL}/uploads/admins-users-img/${chatData.sender_img}`}
                            onClick={() => navigate(`/${chatData.sender}`)}
                          />
                        )}
                      </div>
                      <div className="user-name">
                        {chatReceiver === username ? chatSender : chatReceiver}
                        <span>@username</span>
                        <p>Last seen: 4hours ago</p>
                      </div>
                    </div>
                    <div className="close-chat">
                      <button type="button" onClick={() => closeChat()}>
                        <i className="fa-solid fa-xmark"></i>
                      </button>
                    </div>
                  </div>

                  <div className="chat-messages" ref={chatMessagesRef}>
                    {msgs.map((msg, index) => (
                      <>
                        {msg.sender_id !== user_id ? (
                          <div className="msg-sender">
                            <div className="message">{msg.content}</div>
                          </div>
                        ) : (
                          <div className="msg-receiver">
                            <div className="message">{msg.content}</div>
                          </div>
                        )}
                      </>
                    ))}
                  </div>

                  <div className="sent-chat">
                    <div className="chat-input">
                      <input
                        type="text"
                        placeholder="Send message..."
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        name=""
                        id=""
                      />

                      {/* <button className="file">
                        <svg
                          width="20"
                          height="28"
                          viewBox="0 0 35 31"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.6186 2.36936L4.62571 15.3631C3.14911 16.8399 2.31958 18.8428 2.31958 20.9312C2.31958 23.0196 3.14911 25.0224 4.62571 26.4992C5.35697 27.2305 6.22511 27.8106 7.18056 28.2063C8.136 28.6021 9.16004 28.8058 10.1942 28.8058C11.2284 28.8058 12.2524 28.6021 13.2079 28.2063C14.1633 27.8106 15.0314 27.2305 15.7627 26.4992L31.231 11.0319C31.7186 10.5443 32.1054 9.96554 32.3692 9.32853C32.6331 8.69151 32.769 8.00875 32.769 7.31923C32.769 6.62972 32.6331 5.94696 32.3692 5.30994C32.1054 4.67292 31.7186 4.09413 31.231 3.60661C30.7434 3.11901 30.1646 2.73221 29.5276 2.46832C28.8906 2.20443 28.2079 2.0686 27.5183 2.0686C26.8288 2.0686 26.1461 2.20443 25.509 2.46832C24.872 2.73221 24.2932 3.11901 23.8057 3.60661L8.33834 19.0749C8.09453 19.3186 7.90114 19.608 7.76919 19.9265C7.63725 20.245 7.56933 20.5864 7.56933 20.9312C7.56933 21.2759 7.63725 21.6173 7.76919 21.9358C7.90114 22.2543 8.09453 22.5437 8.33834 22.7875C8.8306 23.2796 9.49816 23.556 10.1942 23.556C10.8903 23.556 11.5578 23.2796 12.0501 22.7875L25.0438 9.79373"
                            stroke="#272D3B"
                            stroke-width="4"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                      <button>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 37 33"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.5188 32.625H22.481C28.0728 32.625 30.8696 32.625 32.878 31.3081C33.7449 30.7404 34.4913 30.0074 35.0746 29.1509C36.4166 27.1801 36.4166 24.4335 36.4166 18.9438C36.4166 13.4541 36.4166 10.7075 35.0746 8.73667C34.4913 7.88024 33.7449 7.14721 32.878 6.5795C31.588 5.73204 29.972 5.42925 27.4977 5.32175C26.317 5.32175 25.3011 4.44384 25.07 3.30613C24.8933 2.47277 24.4344 1.72594 23.7707 1.19187C23.1071 0.657804 22.2794 0.369243 21.4275 0.374961H15.5723C13.8022 0.374961 12.2775 1.60225 11.9299 3.30613C11.6988 4.44384 10.6829 5.32175 9.50217 5.32175C7.02967 5.42925 5.41359 5.73384 4.12179 6.5795C3.25559 7.14739 2.50978 7.88041 1.927 8.73667C0.583252 10.7075 0.583252 13.4523 0.583252 18.9438C0.583252 24.4353 0.583252 27.1783 1.92521 29.1509C2.50571 30.0038 3.25104 30.7365 4.12179 31.3081C6.13025 32.625 8.92704 32.625 14.5188 32.625ZM18.4999 11.6141C14.3773 11.6141 11.034 14.8946 11.034 18.942C11.034 22.9894 14.3791 26.2753 18.4999 26.2753C22.6208 26.2753 25.9658 22.993 25.9658 18.9456C25.9658 14.8982 22.6208 11.6141 18.4999 11.6141ZM18.4999 14.5453C16.0274 14.5453 14.0208 16.5143 14.0208 18.9438C14.0208 21.3715 16.0274 23.3405 18.4999 23.3405C20.9724 23.3405 22.9791 21.3715 22.9791 18.9438C22.9791 16.5161 20.9724 14.5453 18.4999 14.5453ZM26.9602 13.0797C26.9602 12.2698 27.6285 11.6141 28.4544 11.6141H30.4432C31.2673 11.6141 31.9374 12.2698 31.9374 13.0797C31.9336 13.4719 31.7743 13.8465 31.4945 14.1213C31.2146 14.3961 30.8372 14.5486 30.445 14.5453H28.4544C28.2601 14.5471 28.0673 14.5107 27.887 14.4381C27.7067 14.3655 27.5425 14.258 27.4037 14.122C27.265 13.9859 27.1544 13.8238 27.0783 13.6449C27.0022 13.4661 26.962 13.274 26.9602 13.0797Z"
                            fill="#272D3B"
                          />
                        </svg>
                      </button> */}
                    </div>
                    <div className="send-btn">
                      <button type="button" onClick={sentText}>
                        <img src={SubmitButton} />
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LMessage;
