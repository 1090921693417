import React, { useContext, useEffect, useRef, useState } from "react";
import {
  addCollection,
  addComment,
  createChat,
  getCommentsByPostid,
  getLatestPosts,
  getSinglePost,
  getSingleUser,
  getUserCollections,
  getUserFollowers,
  getUserLikedPosts,
  getUserPosts,
  postLikeUnlike,
  updatePostTags,
  userFollowUnfollow,
} from "../../../services/Apis";
import { Link, useNavigate, useParams } from "react-router-dom";
import HeaderTop from "../../components/header/large-screen/HeaderTop";
import Sidebar from "../../components/common/Sidebar";
import { BASE_URL } from "../../../services/helper";
import { mainTheme } from "../../components/context/ContextProvider";
import {
  formatDate,
  isTokenValid,
} from "../../components/functions/OtherFunctions";
import Porfile_img from "../../../../public/assets/images/profile.png";
import TimeAgoConverter from "../../components/functions/TimeAgoConverter";
import SharePostModal from "../home/large-view/SharePostModal";
import SmallFooter from "../../components/footer/SmallFooter";
import SmallHeader from "../../components/header/small-screen/SmallHeader";
import { ToastContainer, toast } from "react-toastify";
import Header from "../../components/header/large-screen/Header";
import HomeSidebar from "../../pages/home/large-view/HomeSidebar";
const WEBSITE_URL = import.meta.env.VITE_WEBSITE_URL;
import { headerMenu } from "../../components/context/ContextProvider";
import Follow from "../../../../public/assets/images/follow.svg"
import Following from "../../../../public/assets/images/following.svg"
import Email_img from "../../../../public/assets/images/email.svg"
import CommentBox from "../home/small-view/CommentBox";

const ViewPost = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 700);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 700);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isValid, setIsValid] = useState(false);
  const navigate = useNavigate();
  const { post_id, post_by } = useParams();
  const username = sessionStorage.getItem("user_username") || "";
  const user_id = sessionStorage.getItem("user_id") || "";

  const [postData, setPostData] = useState({});
  const [tagsArray, setTagsArray] = useState([]);
  const [postByUser, setPostByUser] = useState({});
  const [comments, setComments] = useState([]);
  const [ltPost, setLtPosts] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [postLiked, setPostLiked] = useState([]);
  const [collections, setCollections] = useState([]);
  const [userPosts, setUserPosts] = useState([]);
  const [text, setText] = useState("");
  const textAreaRef = useRef(null);
  const [modalShow, setModalShow] = useState(false);
  const [postLink, setPostLink] = useState();
  const { pageTheme, setPageTheme } = useContext(mainTheme);
  const [showAddTag, setShowAddTag] = useState(false);

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        setIsValid(valid);
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();
  }, []);

  const fetchSinglePost = async () => {
    const response = await getSinglePost(post_id);
    if (response.status === 200) {
      setPostData(response.data.singlePost);
    } else {
      console.log("Error in Fetching Admin Details.");
    }
  };

  useEffect(() => {
    if (postData.tags) {
      setTagsArray(postData.tags.split(","));
    }
  }, [postData.tags]);

  const userProfileGet = async () => {
    try {
      const res = await getSingleUser(post_by);
      if (res.status === 200) {
        setPostByUser(res.data);
      } else {
        console.log("Error in Fetching User Details.");
      }
    } catch (error) {
      console.error("Error in Fetching User Details:", error);
    }
  };

  const fetchPostComments = async () => {
    try {
      const res = await getCommentsByPostid(post_id);
      if (res.status === 200) {
        setComments(res.data);
      } else {
        console.log("Error in Fetching Comments.");
      }
    } catch (error) {
      console.error("Error in Fetching Comments:", error);
    }
  };

  const fetchLatestPosts = async () => {
    try {
      const res = await getLatestPosts();
      if (res.status === 200) {
        setLtPosts(res.data);
      } else {
        console.log("Error in Fetching Posts.");
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  const getCurrentUserData = async () => {
    try {
      const res = await getSingleUser(username);
      if (res.status === 200) {
        setCurrentUser(res.data);
      } else {
        console.log("Error in Fetching User Details.");
      }
    } catch (error) {
      console.error("Error in Fetching User Details:", error);
    }
  };

  const getLikedPost = async () => {
    try {
      const res = await getUserLikedPosts(username);
      if (res.status === 200) {
        setPostLiked(res.data.likedPosts);
      } else {
        console.log("Error in Fetching Liked Posts.");
      }
    } catch (error) {
      console.error("Error fetching liked posts:", error);
    }
  };

  const fetchCollections = async () => {
    try {
      const res = await getUserCollections(username);
      if (res.status === 200) {
        setCollections(res.data);
      } else {
        console.log("Error in Fetching Collections.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchUserPosts = async () => {
    try {
      const res = await getUserPosts(post_by);
      if (res.status === 200) {
        setUserPosts(res.data.userPosts);
      } else {
        console.log("Error in Fetching Posts.");
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  const [followers, setFollowers] = useState([]);
  const fetchFollowers = async () => {
    try {
      const res = await getUserFollowers(post_by);
      if (res.status === 200) {
        setFollowers(res.data.followers);
      } else {
        console.log("Error in Fetching Followers.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchAll = async () => {
    await fetchSinglePost();
    await userProfileGet();
    await fetchPostComments();
    await fetchLatestPosts();
    await fetchUserPosts();
    if (username) {
      await getCurrentUserData();
      await getLikedPost();
      await fetchCollections();
      await fetchFollowers();
    }
  };

  useEffect(() => {
    fetchAll();
  }, [post_id, post_by]);

  const handleltPostClick = (username, post_id) => {
    setTimeout(() => {
      const newUrl = `/view-post/${username}/${post_id}`;
      window.location.href = newUrl;
    }, 300);
  };

  useEffect(() => {
    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.style.height = "auto";
      textArea.style.height = `${textArea.scrollHeight}px`;
    }
  }, [text]);

  const submitComment = async () => {
    const commentData = {
      post_id,
      content: text,
      commented_by: username,
      posted_by: post_by,
    };
    if (text === "") {
      // alert("Comment cannot be Empty");
      toast.error("Comment cannot be Empty");
    } else {
      try {
        const config = { "Content-Type": "application/json" };
        const res = await addComment(commentData, config);
        if (res.status === 200) {
          setText("");
          fetchPostComments();
          // alert("Commented Successfully");
          toast.success("Commented Successfully");
        } else {
          // alert("Comment Cannot be Added Now");
          toast.error("Comment Cannot be Added Now");
        }
      } catch (error) {
        console.error("Error:", error.res.data);
      }
    }
  };

  const handlePostModal = () => {
    setModalShow(true);
    setPostLink(`${WEBSITE_URL}/view-post/${post_by}/${post_id}`);
  };

  const handleLike = async () => {
    if (isValid === true) {
      const data = { post_id, liked_by: username, posted_by: post_by };
      try {
        const config = { "Content-Type": "application/json" };
        const res = await postLikeUnlike(data, config);
        if (res.status === 200) {
          getLikedPost();
          fetchSinglePost();
        }
      } catch (error) {
        console.error("Error:", error.response.data);
      }
    } else {
      // alert("Please Login First");
      toast.error("Please Login First");
    }
  };

  const handleCollection = async () => {
    if (isValid === true) {
      const data = {
        post_id,
        post_img: postData.post_img,
        post_title: postData.title,
        posted_by: post_by,
        user_id,
      };
      try {
        const config = { "Content-Type": "application/json" };
        const res = await addCollection(data, config);
        if (res.status === 200) {
          fetchCollections(username);
        }
      } catch (error) {
        console.error("Error:", error.response.data);
      }
    } else {
      // alert("Please Login First");
      toast.error("Please Login First");
    }
  };

  const handleFollow = async () => {
    if (isValid === true) {
      const data = { user_id: postByUser._id, follower_id: user_id };
      try {
        const config = { "Content-Type": "application/json" };
        const res = await userFollowUnfollow(data, config);
        if (res.status === 200) {
          fetchFollowers();
        }
      } catch (error) {
        console.error("Error:", error.response.data);
      }
    } else {
      // alert("Please Login First");
      toast.error("Please Login First");
    }
  };

  const handleTagsChange = (e) => {
    setPostData({ ...postData, tags: e.target.value });
  };

  const submitTags = async () => {
    if (postData.tags) {
      const config = {
        "Content-Type": "application/json",
      };
      const res = await updatePostTags(
        post_id,
        { tags: postData.tags },
        config
      );
      if (res.status === 200) {
        // alert("Tags Updated Successfully");
        alert("Tags Updated Successfully");
        fetchSinglePost();
      } else {
        console.log(res.response.data.message);
        if (res.response.data.message) {
          // alert(res.response.data.message)
          toast.error(res.response.data.message);
        } else {
          // alert("Some Unknown Error Occured")
          toast.error("Some Unknown Error Occured");
        }
      }
    } else {
      // alert("Tag cannot be Empty please Add atleast one Tag")
      toast.error("Tag cannot be Empty please Add atleast one Tag");
    }
  };

  const openChat = async () => {
    if (isValid === true) {
      if (username === post_by) {
        navigate("/edit-profile");
      } else {
        const data = { sender: username, receiver: post_by };
        try {
          const config = { "Content-Type": "application/json" };
          const res = await createChat(data, config);
          if (res.status === 200) {
            navigate("/message");
          }
        } catch (error) {
          console.error("Error:", error.response.data);
        }
      }
    } else {
      // alert("Please Login First");
      toast.error("Please Login First");
    }
  };

  const checkAndNavigate = (navigateTo) => {
    if (isValid) {
      navigate(navigateTo);
    } else {
      // alert("Please Login First");
      // navigate("/login");
      toast.error("Please Login First", {
        onClose: () => navigate("/login"),
      });
    }
  };
  const { showMenu, setShowMenu } = useContext(headerMenu);
  const [themeMenu, setThemeMenu] = useState(false);

  return (
    <>
      {/* {!isSmallScreen ? (<>
      <HeaderTop />
      <Sidebar />
    </>) : (
      <SmallHeader />
    )} */}

      {!isSmallScreen ? (
        <>
          <HeaderTop />

          <div className="container-fluid" id="home1">
            <HomeSidebar
              showMenu={showMenu}
              checkAndNavigate={checkAndNavigate}
              pageTheme={pageTheme}
              setPageTheme={setPageTheme}
              themeMenu={themeMenu}
              setThemeMenu={setThemeMenu}
            />
          </div>

          <div className="container-fluid" id="home1">
            <div className="row d-flex">
              <div className="main_page_ViewPost">
                <div className={showMenu ? "overlay-main" : "d-none"}></div>
                <div className="container-fluid" id="view-user-post">
                  <div className="row post-wrapper" style={showMenu ? { filter: "blur(3px)" } : {}}>
                    <div className="col-lg-11 left-wrapper">
                      <div className="user-profile">
                        <div className="title">{postData.title}</div>
                        <div className="post_data_flex">
                          <div className="d-flex align-items-center">
                            <div className="user-img">
                              <img
                                src={`${BASE_URL}/uploads/admins-users-img/${postByUser.user_img}`}
                                alt={postByUser.username}
                              />
                            </div>

                            <div className="userName">
                              <h2>
                                {" "}
                                {postByUser.fname + " " + postByUser.lname}{" "}
                              </h2>

                              <span>@{postByUser.username}</span>
                            </div>
                          </div>

                          <div className="d-flex">
                            <div onClick={openChat} style={{ cursor: "pointer" }}>
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 42 42"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="20.6643"
                                  cy="20.6643"
                                  r="20.6643"
                                  fill="#272D3B"
                                />
                                <path
                                  d="M29.1268 12.2018H13.3826C12.3002 12.2018 11.4244 13.0874 11.4244 14.1698L11.4146 25.9779C11.4146 27.0604 12.3002 27.946 13.3826 27.946H29.1268C30.2092 27.946 31.0948 27.0604 31.0948 25.9779V14.1698C31.0948 13.0874 30.2092 12.2018 29.1268 12.2018ZM29.1268 16.1378L21.2547 21.0579L13.3826 16.1378V14.1698L21.2547 19.0898L29.1268 14.1698V16.1378Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                            <div className="follow">
                              {username !== post_by ? (
                                <>
                                  <button type="button" onClick={handleFollow} className="follow_btn" >
                                    {followers.length > 0 ? (
                                      followers.some(
                                        (follow) =>
                                          follow.follower_id === user_id
                                      ) ? (
                                        <>
                                          <i className="fa-solid fa-check"></i>{" "}
                                          Following
                                        </>
                                      ) : (
                                        <>
                                          <i className="fa-solid fa-plus"></i>{" "}
                                          Follow
                                        </>
                                      )
                                    ) : (
                                      <>
                                        <i className="fa-solid fa-plus"></i>{" "}
                                        Follow
                                      </>
                                    )}
                                  </button>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="post-img">
                        <img
                          src={`${BASE_URL}/uploads/posts-img/${postData.post_img}`}
                          alt={postData.title}
                        />
                      </div>
                      <div className="title-div">
                        <div className="options">
                          <div className="small-follow-div">
                            {username !== post_by ? (
                              <>
                                <button type="button" onClick={handleFollow} >
                                  {followers.length > 0 ? (
                                    followers.some(
                                      (follow) => follow.follower_id === user_id
                                    ) ? (
                                      <>
                                        <i className="fa-solid fa-check"></i>{" "}
                                        Following
                                      </>
                                    ) : (
                                      <>
                                        <i className="fa-solid fa-plus"></i>{" "}
                                        Follow
                                      </>
                                    )
                                  ) : (
                                    <>
                                      <i className="fa-solid fa-plus"></i>{" "}
                                      Follow
                                    </>
                                  )}
                                </button>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="icon-div">
                            <div className="icons" onClick={handleLike}>
                              {postLiked.length > 0 ? (
                                postLiked.some(
                                  (liked) => liked.post_id === post_id
                                ) ? (
                                  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                    <circle cx="24.5855" cy="24.5855" r="24.5855" fill="#F2F2F2" />
                                    <path d="M37.9469 20.8201C37.9469 30.0454 25.4926 37.5127 24.9622 37.8211C24.8224 37.9037 24.6661 37.9469 24.5074 37.9469C24.3487 37.9469 24.1924 37.9037 24.0526 37.8211C23.5222 37.5127 11.0679 30.0454 11.0679 20.8201C11.0701 18.6537 11.8546 16.5768 13.2494 15.0449C14.6441 13.5131 16.5352 12.6514 18.5076 12.649C20.9855 12.649 23.1551 13.8193 24.5074 15.7975C25.8598 13.8193 28.0293 12.649 30.5072 12.649C32.4797 12.6514 34.3707 13.5131 35.7654 15.0449C37.1602 16.5768 37.9447 18.6537 37.9469 20.8201Z" fill="#FF0000" />
                                    <path d="M37.9469 20.8201C37.9469 30.0454 25.4926 37.5127 24.9622 37.8211C24.8224 37.9037 24.6661 37.9469 24.5074 37.9469C24.3487 37.9469 24.1924 37.9037 24.0526 37.8211C23.5222 37.5127 11.0679 30.0454 11.0679 20.8201C11.0701 18.6537 11.8546 16.5768 13.2494 15.0449C14.6441 13.5131 16.5352 12.6514 18.5076 12.649C20.9855 12.649 23.1551 13.8193 24.5074 15.7975C25.8598 13.8193 28.0293 12.649 30.5072 12.649C32.4797 12.6514 34.3707 13.5131 35.7654 15.0449C37.1602 16.5768 37.9447 18.6537 37.9469 20.8201Z" fill="#FF0000" />
                                  </svg>
                                ) : (
                                  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="24.5855" cy="24.5855" r="24.5855" fill="#F2F2F2" />
                                    <path d="M37.9469 20.8201C37.9469 30.0454 25.4926 37.5127 24.9622 37.8211C24.8224 37.9037 24.6661 37.9469 24.5074 37.9469C24.3487 37.9469 24.1924 37.9037 24.0526 37.8211C23.5222 37.5127 11.0679 30.0454 11.0679 20.8201C11.0701 18.6537 11.8546 16.5768 13.2494 15.0449C14.6441 13.5131 16.5352 12.6514 18.5076 12.649C20.9855 12.649 23.1551 13.8193 24.5074 15.7975C25.8598 13.8193 28.0293 12.649 30.5072 12.649C32.4797 12.6514 34.3707 13.5131 35.7654 15.0449C37.1602 16.5768 37.9447 18.6537 37.9469 20.8201Z" fill="#FF0000" />
                                    <path d="M30.9602 31.9437C27.9983 34.7927 24.9712 36.6521 24.5074 36.9284C24.0436 36.6521 21.0165 34.7927 18.0546 31.9437C14.9166 28.9255 12.0681 25.0034 12.0679 20.8206C12.07 18.8906 12.7698 17.057 13.9888 15.7182C15.2049 14.3825 16.8326 13.6513 18.5082 13.649C20.6545 13.6492 22.5154 14.6555 23.6819 16.3619L24.5074 17.5694L25.3329 16.3619C26.4994 14.6555 28.3603 13.6492 30.5066 13.649C32.1822 13.6513 33.8099 14.3825 35.026 15.7182C36.2451 17.0571 36.945 18.8909 36.9469 20.8211C36.9465 25.0037 34.0981 28.9256 30.9602 31.9437Z" fill="white" stroke="#1E2023" stroke-width="2" />
                                  </svg>
                                )
                              ) : (
                                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="24.5855" cy="24.5855" r="24.5855" fill="#F2F2F2" />
                                  <path d="M37.9469 20.8201C37.9469 30.0454 25.4926 37.5127 24.9622 37.8211C24.8224 37.9037 24.6661 37.9469 24.5074 37.9469C24.3487 37.9469 24.1924 37.9037 24.0526 37.8211C23.5222 37.5127 11.0679 30.0454 11.0679 20.8201C11.0701 18.6537 11.8546 16.5768 13.2494 15.0449C14.6441 13.5131 16.5352 12.6514 18.5076 12.649C20.9855 12.649 23.1551 13.8193 24.5074 15.7975C25.8598 13.8193 28.0293 12.649 30.5072 12.649C32.4797 12.6514 34.3707 13.5131 35.7654 15.0449C37.1602 16.5768 37.9447 18.6537 37.9469 20.8201Z" fill="#FF0000" />
                                  <path d="M30.9602 31.9437C27.9983 34.7927 24.9712 36.6521 24.5074 36.9284C24.0436 36.6521 21.0165 34.7927 18.0546 31.9437C14.9166 28.9255 12.0681 25.0034 12.0679 20.8206C12.07 18.8906 12.7698 17.057 13.9888 15.7182C15.2049 14.3825 16.8326 13.6513 18.5082 13.649C20.6545 13.6492 22.5154 14.6555 23.6819 16.3619L24.5074 17.5694L25.3329 16.3619C26.4994 14.6555 28.3603 13.6492 30.5066 13.649C32.1822 13.6513 33.8099 14.3825 35.026 15.7182C36.2451 17.0571 36.945 18.8909 36.9469 20.8211C36.9465 25.0037 34.0981 28.9256 30.9602 31.9437Z" fill="white" stroke="#1E2023" stroke-width="2" />
                                </svg>

                              )}
                            </div>
                            <div className="icons" onClick={handlePostModal}>
                              <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="24.5855" cy="24.5855" r="24.5855" fill="#F2F2F2" />
                                <path d="M26.3335 23.6667L22.8335 27.1667M33.6695 15.5362C33.7802 15.498 33.8994 15.4917 34.0135 15.518C34.1276 15.5444 34.232 15.6023 34.3148 15.6852C34.3975 15.768 34.4553 15.8725 34.4815 15.9867C34.5077 16.1008 34.5012 16.22 34.4628 16.3307L27.5515 36.0823C27.5101 36.2004 27.4343 36.3033 27.3337 36.3777C27.2331 36.4522 27.1125 36.4946 26.9875 36.4997C26.8625 36.5047 26.7389 36.472 26.6327 36.406C26.5264 36.3399 26.4425 36.2434 26.3918 36.129L22.6363 27.68C22.5741 27.5389 22.4613 27.4261 22.3202 27.3638L13.8712 23.6072C13.7572 23.5563 13.6611 23.4724 13.5953 23.3663C13.5294 23.2602 13.4969 23.1368 13.502 23.0121C13.507 22.8874 13.5493 22.767 13.6234 22.6666C13.6976 22.5661 13.8001 22.4902 13.9178 22.4487L33.6695 15.5362Z" stroke="#1E2023" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>

                            </div>
                            <div className="icons " onClick={handleCollection}>
                              {collections.length > 0 ? (
                                collections.some(
                                  (coll) => coll.post_id === post_id
                                ) ? (
                                  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="24.6621" cy="24.5855" r="24.5855" fill="#F2F2F2" />
                                    <path d="M30.0994 13H16.6832C15.2075 13 14 14.2 14 15.6667V37L23.3913 33L32.7826 37V15.6667C32.7826 14.2 31.5752 13 30.0994 13Z" fill="black" />
                                  </svg>
                                ) : (
                                  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="24.6621" cy="24.5855" r="24.5855" fill="#F2F2F2" />
                                    <path d="M14.3071 37.1564V15.2041C14.3071 14.4757 14.5511 13.868 15.0392 13.3811C15.5272 12.8941 16.1349 12.6501 16.8622 12.649H30.7271C31.4544 12.649 32.0621 12.893 32.5501 13.3811C33.0382 13.8691 33.2816 14.4768 33.2806 15.2041V37.1564L23.7939 33.0818L14.3071 37.1564ZM15.8883 34.7057L23.7939 31.3062L31.6995 34.7057V15.2041C31.6995 14.9606 31.5983 14.7371 31.3959 14.5337C31.1935 14.3303 30.97 14.2291 30.7255 14.2301H16.8622C16.6187 14.2301 16.3953 14.3313 16.1918 14.5337C15.9884 14.7361 15.8872 14.9595 15.8883 15.2041V34.7057Z" fill="#1E2023" />
                                  </svg>
                                )
                              ) : (
                                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="24.6621" cy="24.5855" r="24.5855" fill="#F2F2F2" />
                                  <path d="M14.3071 37.1564V15.2041C14.3071 14.4757 14.5511 13.868 15.0392 13.3811C15.5272 12.8941 16.1349 12.6501 16.8622 12.649H30.7271C31.4544 12.649 32.0621 12.893 32.5501 13.3811C33.0382 13.8691 33.2816 14.4768 33.2806 15.2041V37.1564L23.7939 33.0818L14.3071 37.1564ZM15.8883 34.7057L23.7939 31.3062L31.6995 34.7057V15.2041C31.6995 14.9606 31.5983 14.7371 31.3959 14.5337C31.1935 14.3303 30.97 14.2291 30.7255 14.2301H16.8622C16.6187 14.2301 16.3953 14.3313 16.1918 14.5337C15.9884 14.7361 15.8872 14.9595 15.8883 15.2041V34.7057Z" fill="#1E2023" />
                                </svg>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="desc-div">{postData.description}</div>
                      <div className="post-info">
                        <div className="date">
                          Published on : {formatDate(postData.post_date)}
                        </div>
                        <div className="other-info">
                          {postData.likes} Likes . {postData.comments} Comments
                          . {postData.shared} Shared . {postData.views} Views
                        </div>
                      </div>

                      <div className="view-comments">
                        <div className="head">Comments</div>
                        <div className="comments-wrap add-comment">
                          {isValid ? (
                            <>
                              <div className="user-img">
                                <img
                                  src={`${BASE_URL}/uploads/admins-users-img/${currentUser.user_img}`}
                                  alt={currentUser.username}
                                />
                              </div>
                              <div className="comment">
                                <textarea
                                  ref={textAreaRef}
                                  value={text}
                                  onChange={(e) => setText(e.target.value)}
                                  placeholder="Type your comment..."
                                />
                                {text ? (
                                  <div className="options">
                                    <button
                                      type="button"
                                      onClick={() => setText("")}
                                      className="cancl_btn"
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="button"
                                      onClick={submitComment}
                                    >
                                      Comment
                                    </button>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="user-img">
                                <img
                                  src="/assets/images/default-user.png"
                                  alt="default user"
                                />
                              </div>
                              <div className="comment">
                                <span style={{ fontWeight: "500" }}>
                                  Join our Community
                                </span>{" "}
                                to add your comment. Already a member?{" "}
                                <Link to="/login">Log In</Link>
                              </div>
                            </>
                          )}
                        </div>
                        <div
                          style={{
                            background: "#D9D9D9",
                            height: "1px",
                            width: "100%",
                            marginTop: "20px",
                          }}
                        ></div>

                        {comments.map((comm, index) => (
                          <div key={index} className="comments-wrap">
                            <div className="user-img">
                              <img
                                src={`${BASE_URL}/uploads/admins-users-img/${comm.user.user_img}`}
                                alt={comm.user.username}
                              />
                            </div>
                            <div
                              className="comment"
                              style={{ backgroundColor: "transparent" }}
                            >
                              <div className="info justify-content-space-between">
                                <div className="username">
                                  {comm.user.username}

                                  <div className="time">
                                    <TimeAgoConverter
                                      dateTime={comm.comment_date}
                                    />
                                  </div>
                                </div>
                                <div>
                                  <button className="report_btn">
                                    <svg
                                      width="4"
                                      height="14"
                                      viewBox="0 0 4 14"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M3.5 12C3.5 12.3978 3.34196 12.7794 3.06066 13.0607C2.77936 13.342 2.39782 13.5 2 13.5C1.60218 13.5 1.22064 13.342 0.93934 13.0607C0.658035 12.7794 0.5 12.3978 0.5 12C0.5 11.6022 0.658035 11.2206 0.93934 10.9393C1.22064 10.658 1.60218 10.5 2 10.5C2.39782 10.5 2.77936 10.658 3.06066 10.9393C3.34196 11.2206 3.5 11.6022 3.5 12ZM3.5 7C3.5 7.39782 3.34196 7.77936 3.06066 8.06066C2.77936 8.34196 2.39782 8.5 2 8.5C1.60218 8.5 1.22064 8.34196 0.93934 8.06066C0.658035 7.77936 0.5 7.39782 0.5 7C0.5 6.60218 0.658035 6.22064 0.93934 5.93934C1.22064 5.65804 1.60218 5.5 2 5.5C2.39782 5.5 2.77936 5.65804 3.06066 5.93934C3.34196 6.22064 3.5 6.60218 3.5 7ZM3.5 2C3.5 2.39782 3.34196 2.77936 3.06066 3.06066C2.77936 3.34196 2.39782 3.5 2 3.5C1.60218 3.5 1.22064 3.34196 0.93934 3.06066C0.658035 2.77936 0.5 2.39782 0.5 2C0.5 1.60218 0.658035 1.22064 0.93934 0.93934C1.22064 0.658035 1.60218 0.5 2 0.5C2.39782 0.5 2.77936 0.658035 3.06066 0.93934C3.34196 1.22064 3.5 1.60218 3.5 2Z"
                                        fill="black"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                              <div className="content">{comm.content}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <SmallHeader />
          <div className="container-fluid" id="home1">
            <div className="row d-flex">
              <div className="main_page_ViewPost">
                <div className={showMenu ? "overlay-main" : "d-none"}></div>
                <div className="container-fluid" id="view-user-post">
                  <div className="row post-wrapper">
                    <div className="col-lg-11 left-wrapper">
                      <div className="user-profile">
                        <div className="title">{postData.title}</div>
                        <div className="post_data_flex">
                          <div className="d-flex align-items-center">
                            <div className="user-img">
                              <img
                                src={`${BASE_URL}/uploads/admins-users-img/${postByUser.user_img}`}
                                alt={postByUser.username}
                              />
                            </div>

                            <div className="userName">
                              <h2>
                                {" "}
                                {postByUser.fname + " " + postByUser.lname}{" "}
                              </h2>

                              <span>@{postByUser.username}</span>
                            </div>
                          </div>

                          <div className="d-flex align-items-center">
                            <div className="small-follow-div">
                              {username !== post_by ? (
                                <>
                                  <button type="button" onClick={handleFollow} style={{ border: "none", width: "44px", height: "44px", borderRadius: "60%", backgroundColor: "white" }}>
                                    {followers.length > 0 ? (
                                      followers.some(
                                        (follow) => follow.follower_id === user_id
                                      ) ? (
                                        <>
                                          <img src={Following} alt="" />
                                        </>
                                      ) : (
                                        <>
                                          <img src={Follow} alt="" />

                                        </>
                                      )
                                    ) : (
                                      <>
                                        <img src={Follow} alt="" />

                                      </>
                                    )}
                                  </button>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="follow">
                              {username == post_by ? (
                                <>
                                  <button
                                    type="button"
                                    onClick={handleFollow}
                                    className="follow_btn"
                                  >
                                    {followers.length > 0 ? (
                                      followers.some(
                                        (follow) => follow.follower_id === user_id
                                      ) ? (
                                        <>
                                          <img src={Following} alt="" />

                                        </>
                                      ) : (
                                        <img src={Follow} alt="" />


                                      )
                                    ) : (
                                      <>
                                        <img src={Follow} alt="" />
                                      </>
                                    )}
                                  </button>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="" >
                              <div onClick={openChat}>
                                <img src={Email_img} alt="" />
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>

                      <div className="post-img">
                        <img
                          src={`${BASE_URL}/uploads/posts-img/${postData.post_img}`}
                          alt={postData.title}
                        />
                      </div>
                      <div className="title-div">
                        <div className="options">
                          <div className="icon-div">
                            <div className="icons" onClick={handleLike}>
                              {postLiked.length > 0 ? (
                                postLiked.some(
                                  (liked) => liked.post_id === post_id
                                ) ? (
                                  <svg
                                    width="17"
                                    height="16"
                                    viewBox="0 0 17 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M17 5.16789C17 11.0026 9.12308 15.7254 8.78763 15.9204C8.69922 15.9727 8.60039 16 8.5 16C8.39961 16 8.30078 15.9727 8.21237 15.9204C7.87692 15.7254 0 11.0026 0 5.16789C0.0014061 3.79775 0.497599 2.48418 1.37972 1.51534C2.26184 0.546512 3.45785 0.00154431 4.70536 0C6.27254 0 7.64469 0.740174 8.5 1.9913C9.35531 0.740174 10.7275 0 12.2946 0C13.5421 0.00154431 14.7382 0.546512 15.6203 1.51534C16.5024 2.48418 16.9986 3.79775 17 5.16789Z"
                                      fill="#FF0000"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="17"
                                    height="16"
                                    viewBox="0 0 17 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12.5954 12.218C10.6967 14.0441 8.75596 15.228 8.5 15.3796C8.24405 15.228 6.30328 14.0441 4.40463 12.218C2.41914 10.3082 0.612139 7.82248 0.611987 5.16822C0.613315 3.94274 1.05766 2.77807 1.83224 1.92736C2.60505 1.07858 3.63989 0.613417 4.70574 0.611987C6.06999 0.612105 7.25321 1.25192 7.99479 2.33668L8.5 3.0757L9.00521 2.33668C9.74679 1.25192 10.93 0.612105 12.2943 0.611987C13.3601 0.613418 14.395 1.07858 15.1678 1.92736C15.9423 2.77799 16.3866 3.94253 16.388 5.16789C16.388 7.82227 14.5809 10.3081 12.5954 12.218Z"
                                      stroke="#272D3B"
                                      stroke-width="1.22397"
                                    />
                                  </svg>
                                )
                              ) : (
                                <svg
                                  width="17"
                                  height="16"
                                  viewBox="0 0 17 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.5954 12.218C10.6967 14.0441 8.75596 15.228 8.5 15.3796C8.24405 15.228 6.30328 14.0441 4.40463 12.218C2.41914 10.3082 0.612139 7.82248 0.611987 5.16822C0.613315 3.94274 1.05766 2.77807 1.83224 1.92736C2.60505 1.07858 3.63989 0.613417 4.70574 0.611987C6.06999 0.612105 7.25321 1.25192 7.99479 2.33668L8.5 3.0757L9.00521 2.33668C9.74679 1.25192 10.93 0.612105 12.2943 0.611987C13.3601 0.613418 14.395 1.07858 15.1678 1.92736C15.9423 2.77799 16.3866 3.94253 16.388 5.16789C16.388 7.82227 14.5809 10.3081 12.5954 12.218Z"
                                    stroke="#272D3B"
                                    stroke-width="1.22397"
                                  />
                                </svg>
                              )}
                            </div>
                            <div className="small-post-comment">
                              <CommentBox post_id={post_id} post_username={post_by} isValid={isValid}/>
                            </div>
                            {/* <div>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 7.99999C15.0012 8.91905 14.821 9.8293 14.4697 10.6786C14.1184 11.5279 13.603 12.2995 12.953 12.9492C12.3026 13.5997 11.5305 14.1156 10.6806 14.4675C9.83076 14.8194 8.91988 15.0003 8.00005 15C7.01007 15.0022 6.03101 14.7932 5.12818 14.387L2.20183 14.8199C2.05653 14.8436 1.90764 14.8315 1.76809 14.7846C1.62853 14.7377 1.50254 14.6575 1.40104 14.5508C1.29955 14.4442 1.22562 14.3144 1.18569 14.1727C1.14575 14.031 1.14102 13.8817 1.17189 13.7377L1.57827 10.769C1.19171 9.89731 0.99465 8.9535 1.00011 7.99999C0.998935 7.08094 1.17915 6.17068 1.53041 5.32141C1.88168 4.47213 2.39709 3.70052 3.04712 3.05081C3.69746 2.40031 4.46963 1.88437 5.31949 1.53248C6.16935 1.18059 7.08023 0.99965 8.00005 1C9.85759 1.00012 11.6391 1.73777 12.953 3.05081C13.6027 3.70077 14.1179 4.47242 14.4691 5.32164C14.8204 6.17087 15.0008 7.081 15 7.99999Z" stroke="#272D3B" stroke-width="1.11177" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </div> */}
                            <div className="icons" onClick={handlePostModal}>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.5564 6.44358L7.2226 8.77712M14.448 1.02276C14.5219 0.997294 14.6014 0.993102 14.6774 1.01067C14.7535 1.02823 14.8231 1.06685 14.8783 1.1221C14.9335 1.17736 14.972 1.24701 14.9895 1.32312C15.0069 1.39923 15.0026 1.47871 14.977 1.55248L10.3686 14.7214C10.341 14.8001 10.2904 14.8688 10.2233 14.9184C10.1563 14.968 10.0758 14.9963 9.9925 14.9997C9.90915 15.003 9.82671 14.9813 9.75588 14.9372C9.68506 14.8931 9.62911 14.8288 9.59529 14.7525L7.09113 9.11937C7.04961 9.02527 6.97442 8.95009 6.88031 8.90857L1.24652 6.40391C1.17051 6.36998 1.10642 6.31403 1.06254 6.24331C1.01865 6.17259 0.996987 6.09033 1.00034 6.00716C1.00369 5.924 1.0319 5.84375 1.08133 5.77679C1.13076 5.70982 1.19914 5.65922 1.27763 5.63151L14.448 1.02276Z"
                                  stroke="black"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                            <div
                              className="icons saved_icon"
                              onClick={handleCollection}
                            >
                              {collections.length > 0 ? (
                                collections.some(
                                  (coll) => coll.post_id === post_id
                                ) ? (
                                  <svg
                                    width="12"
                                    height="16"
                                    viewBox="0 0 12 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M0 15.5V1.616C0 1.15533 0.154333 0.771 0.463 0.463C0.771666 0.155 1.156 0.000666667 1.616 0H10.385C10.845 0 11.2293 0.154333 11.538 0.463C11.8467 0.771666 12.0007 1.156 12 1.616V15.5L6 12.923L0 15.5Z"
                                      fill="black"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="11"
                                    height="14"
                                    viewBox="0 0 11 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M0 14V1.45961C0 1.04353 0.141472 0.696387 0.424416 0.418193C0.707361 0.14 1.05967 0.000602151 1.48133 0H9.51958C9.94125 0 10.2936 0.139398 10.5765 0.418193C10.8594 0.696989 11.0006 1.04413 11 1.45961V14L5.5 11.6724L0 14ZM0.916667 12.6L5.5 10.6581L10.0833 12.6V1.45961C10.0833 1.32052 10.0247 1.19286 9.90733 1.07665C9.79 0.96043 9.66044 0.902624 9.51867 0.903226H1.48133C1.34017 0.903226 1.21061 0.961032 1.09267 1.07665C0.974722 1.19226 0.916055 1.31991 0.916667 1.45961V12.6Z"
                                      fill="black"
                                    />
                                  </svg>
                                )
                              ) : (
                                <svg
                                  width="11"
                                  height="14"
                                  viewBox="0 0 11 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0 14V1.45961C0 1.04353 0.141472 0.696387 0.424416 0.418193C0.707361 0.14 1.05967 0.000602151 1.48133 0H9.51958C9.94125 0 10.2936 0.139398 10.5765 0.418193C10.8594 0.696989 11.0006 1.04413 11 1.45961V14L5.5 11.6724L0 14ZM0.916667 12.6L5.5 10.6581L10.0833 12.6V1.45961C10.0833 1.32052 10.0247 1.19286 9.90733 1.07665C9.79 0.96043 9.66044 0.902624 9.51867 0.903226H1.48133C1.34017 0.903226 1.21061 0.961032 1.09267 1.07665C0.974722 1.19226 0.916055 1.31991 0.916667 1.45961V12.6Z"
                                    fill="black"
                                  />
                                </svg>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="desc-div">{postData.description}</div>



                    </div>

                    <div className="col-lg-3 right-wrapper">
                      <div className="latest-posts-div">
                        <div className="post-wrap">
                          {ltPost.length > 0 && ltPost.map((post) => (
                            <div key={post._id}>
                              <div
                                className="more-img"
                                onClick={() => handleltPostClick(post.username, post._id)}
                              >
                                <img
                                  src={`${BASE_URL}/uploads/posts-img/${post.post_img}`}
                                  alt={post.title}
                                />
                              </div>
                              <div className="recommanded_post">
                                <div className="d-flex align-items-center">
                                  <div>
                                    <img src={`${BASE_URL}/uploads/admins-users-img/${post.user_img}`} alt="" />
                                  </div>
                                  <h4>{post.username}</h4>
                                </div>

                                <div className="icon-div d-flex">
                                  <div className="icons" onClick={handleLike} style={{ marginRight: "5px" }}>
                                    {postLiked.length > 0 ? (
                                      postLiked.some(liked => liked.post_id === post._id) ? (
                                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
                                          <path
                                            d="M17 5.16789C17 11.0026 9.12308 15.7254 8.78763 15.9204C8.69922 15.9727 8.60039 16 8.5 16C8.39961 16 8.30078 15.9727 8.21237 15.9204C7.87692 15.7254 0 11.0026 0 5.16789C0.0014061 3.79775 0.497599 2.48418 1.37972 1.51534C2.26184 0.546512 3.45785 0.00154431 4.70536 0C6.27254 0 7.64469 0.740174 8.5 1.9913C9.35531 0.740174 10.7275 0 12.2946 0C13.5421 0.00154431 14.7382 0.546512 15.6203 1.51534C16.5024 2.48418 16.9986 3.79775 17 5.16789Z"
                                            fill="#FF0000"
                                          />
                                        </svg>
                                      ) : (
                                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
                                          <path
                                            d="M12.5954 12.218C10.6967 14.0441 8.75596 15.228 8.5 15.3796C8.24405 15.228 6.30328 14.0441 4.40463 12.218C2.41914 10.3082 0.612139 7.82248 0.611987 5.16822C0.613315 3.94274 1.05766 2.77807 1.83224 1.92736C2.60505 1.07858 3.63989 0.613417 4.70574 0.611987C6.06999 0.612105 7.25321 1.25192 7.99479 2.33668L8.5 3.0757L9.00521 2.33668C9.74679 1.25192 10.93 0.612105 12.2943 0.611987C13.3601 0.613418 14.395 1.07858 15.1678 1.92736C15.9423 2.77799 16.3866 3.94253 16.388 5.16789C16.388 7.82227 14.5809 10.3081 12.5954 12.218Z"
                                            stroke="#272D3B"
                                            strokeWidth="1.22397"
                                          />
                                        </svg>
                                      )
                                    ) : (
                                      <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
                                        <path
                                          d="M12.5954 12.218C10.6967 14.0441 8.75596 15.228 8.5 15.3796C8.24405 15.228 6.30328 14.0441 4.40463 12.218C2.41914 10.3082 0.612139 7.82248 0.611987 5.16822C0.613315 3.94274 1.05766 2.77807 1.83224 1.92736C2.60505 1.07858 3.63989 0.613417 4.70574 0.611987C6.06999 0.612105 7.25321 1.25192 7.99479 2.33668L8.5 3.0757L9.00521 2.33668C9.74679 1.25192 10.93 0.612105 12.2943 0.611987C13.3601 0.613418 14.395 1.07858 15.1678 1.92736C15.9423 2.77799 16.3866 3.94253 16.388 5.16789C16.388 7.82227 14.5809 10.3081 12.5954 12.218Z"
                                          stroke="#272D3B"
                                          strokeWidth="1.22397"
                                        />
                                      </svg>
                                    )}
                                  </div>
                                  <div className="icons" onClick={handlePostModal}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                                      <path
                                        d="M9.5564 6.44358L7.2226 8.77712M14.448 1.02276C14.5219 0.997294 14.6014 0.993102 14.6774 1.01067C14.7535 1.02823 14.8231 1.06685 14.8783 1.1221C14.9335 1.17736 14.972 1.24701 14.9895 1.32312C15.0069 1.39923 15.0026 1.47871 14.977 1.55248L10.3686 14.7214C10.341 14.8001 10.2904 14.8688 10.2233 14.9184C10.1563 14.968 10.0758 14.9963 9.9925 14.9997C9.90915 15.003 9.82671 14.9813 9.75588 14.9372C9.68506 14.8931 9.62911 14.8288 9.59529 14.7525L7.09113 9.11937C7.04961 9.02527 6.97442 8.95009 6.88031 8.90857L1.24652 6.40391C1.17051 6.36998 1.10642 6.31403 1.06254 6.24331C1.01865 6.17259 0.996987 6.09033 1.00034 6.00716C1.00369 5.924 1.0319 5.84375 1.08133 5.77679C1.13076 5.70982 1.19914 5.65922 1.27763 5.63151L14.448 1.02276Z"
                                        stroke="black"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {isSmallScreen ? <SmallFooter /> : ''}
      <ToastContainer position="top-center" autoClose={1000} />
      <SharePostModal
        show={modalShow}
        postLink={postLink}
        onHide={() => setModalShow(false)}
        post_id={post_id}
        fetchSinglePost={fetchSinglePost}
      />
    </>
  );
};

export default ViewPost;
