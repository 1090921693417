import React, { useEffect, useState } from "react";
import Profile from "../../../../../public/assets/images/profile.png";
import { addComment, getAllCommentsWithUser, getCommentsByPostid } from "../../../../services/Apis";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../../../services/helper";

const CommentBox = ({ post_id, post_username, isValid }) => {
  const username = sessionStorage.getItem("user_username") || "";

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  //<----------------------------------------------- Fetch all Comments ----------------------------------------------->
  const [allCommentsUser, setAllCommentsUser] = useState([]);

  const fetchAllCommentsWithUser = async () => {
    try {
      const res = await getCommentsByPostid(post_id);
      setAllCommentsUser(res.data);
      console.log(res.data)
    } catch (error) {
      console.error("Error:", error.response.data);
    }
  };

  useEffect(() => {
    fetchAllCommentsWithUser();
  }, []);

  const [comm, setComm] = useState("");

  const postComment = async () => {
    if(isValid && username!==""){
    const commentData = { post_id, content: comm, commented_by: username, posted_by: post_username };
    if (comm.trim() === "") {
      toast.error("Comment cannot be Empty");
    } else {
      try {
        const res = await addComment(commentData);
        if (res.status === 200) {
          setComm("");
          fetchAllCommentsWithUser();
          toast.success("Comment Successfully");
        } else {
          toast.error("Comment Cannot be Added Now");
        }
      } catch (error) {
        console.error("Error:", error.response.data);
        toast.error("Comment Cannot be Added Now");
      }
    }
  }else{
    toast.error("Please Login to Add Comment");
  }
  };

  return (
    <div>
      {/* SVG icon with onClick event to toggle popup */}
      <div onClick={() => setIsPopupVisible(!isPopupVisible)}>
        <span>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.7311 10.082C17.7323 11.084 17.5359 12.0763 17.1529 13.0022C16.77 13.9281 16.2081 14.7693 15.4994 15.4776C14.7904 16.1868 13.9486 16.7492 13.0221 17.1329C12.0956 17.5165 11.1025 17.7137 10.0997 17.7134C9.02044 17.7157 7.95307 17.4879 6.96881 17.0451L3.7785 17.517C3.6201 17.5429 3.45777 17.5297 3.30563 17.4786C3.15349 17.4274 3.01613 17.34 2.90548 17.2237C2.79483 17.1074 2.71423 16.9659 2.6707 16.8114C2.62716 16.657 2.622 16.4942 2.65566 16.3372L3.09869 13.1007C2.67727 12.1505 2.46243 11.1215 2.46838 10.082C2.4671 9.08008 2.66357 8.08773 3.04652 7.16185C3.42947 6.23597 3.99138 5.39477 4.70003 4.68646C5.40904 3.9773 6.25086 3.41482 7.17737 3.03119C8.10389 2.64756 9.09693 2.4503 10.0997 2.45068C12.1248 2.45082 14.067 3.25499 15.4994 4.68646C16.2077 5.39504 16.7694 6.23629 17.1523 7.16211C17.5352 8.08792 17.7319 9.08014 17.7311 10.082Z"
              stroke="#272D3B"
              strokeWidth="1.11177"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>

      {/* Conditionally render the report popup */}
      {isPopupVisible && (
        <div className="report-popup">
          <div className="comment-header">
            <button className="closeBtnComment" onClick={() => setIsPopupVisible(!isPopupVisible)}>
              &times;
            </button>
            <h2 className="comment_title">Comments</h2>
          </div>

          <div className="comment-wrap">

            {allCommentsUser.length > 0 &&
              allCommentsUser.some((comm) => comm.post_id === post_id) ? (
              allCommentsUser
                .filter((comm) => comm.post_id === post_id).map((comm, index) => (
                  <div className="commentMesg">
                    <div className="profile-img"><img src={`${BASE_URL}/uploads/admins-users-img/${comm.user.user_img}`} alt="" /></div>
                    <div className="comment-data">
                      <h3>@{comm.user.username}</h3>
                      <p>{comm.content}</p>
                    </div>
                  </div>

                ))
            ) : (
              ''
            )
            }
          </div>

          <div className="comment-box">
            <textarea rows="1" onChange={(e) => setComm(e.target.value)} value={comm}></textarea>
            <button type="button" onClick={() => postComment()}>Post</button>
          </div>
        </div>
      )}
      
      <ToastContainer position="top-center" autoClose={1000} />
    </div>
  );
};

export default CommentBox;
