import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getServicesMainCats, changeFeaturedMainCat, deleteServicesMainCat } from '../../../../services/Apis';
import { BASE_URL } from '../../../../services/helper';
import Dropdown from "react-bootstrap/Dropdown";
import Badge from "react-bootstrap/Badge";
import { NavLink } from 'react-router-dom';
import AccessChecker from '../../../components/OtherFunctions/AccessChecker';
import '../services.css';

const ViewServicesMainCat = () => {

  const [mainCats, setMainCats] = useState([]);

  const fetchMainCats = async () => {
    try {
      const res = await getServicesMainCats();
      if (res.status === 200) {
        setMainCats(res.data);
      } else {
        console.log('Error in Fetching Users.');
      }
    } catch (error) {
      console.error('Error fetching Users:', error);
    }
  };

  useEffect(() => {
    fetchMainCats();
  }, []);

  const featuredChange = async (id, data) => {
    const response = await changeFeaturedMainCat(id, data);
    if (response.status === 200) {
      fetchMainCats();
    }
    else {
      alert("Cannot Main Category Featured");
    }
  }

  const handleDeleteMainCat = async (id) => {
    const response = await deleteServicesMainCat(id);
    if (response.status === 200) {
      fetchMainCats();
    }
    else {
      alert("Cannot Delete Main Categoruy");
    }
  }

  return (
    <>
      <AccessChecker accessKey="view_users" />
      <div className='form-card expand-card' id="view-services-cats">
        <div className="row">
          <div className="col-md-12 form-head d-flex justify-content-between">
            <h1>View Main Categories</h1>
            <div><Link to="/admin/add-services-cats"><button className='btn btn-primary'>Add Categories</button></Link></div>
          </div>
        </div>
        <div>
          <hr />
          <table class="table table-bordered" style={{ width: '90%', margin: 'auto', marginTop: '30px' }}>
            <thead>
              <th className='text-left'>Sl No.</th>
              <th>Banner <br/>(Click to View)</th>
              <th>Category Title</th>
              <th>Category</th>
              <th>Caption</th>
              <th>Featured</th>
              <th>Action</th>
            </thead>

            <tbody>
              {mainCats.map((cats, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td><a href={`${BASE_URL}/uploads/services-cat-img/${cats.banner_img}`} target='_blank'><img src={`${BASE_URL}/uploads/services-cat-img/${cats.banner_img}`} /></a></td>
                  <td>{cats.main_cat_title}</td>
                  <td>{cats.main_cat}</td>
                  <td>{cats.main_cat_caption}</td>
                  <td>
                    <Dropdown className="text-center">
                      <Dropdown.Toggle className="dropdown_btn" id="dropdown-basic">
                        <Badge bg={cats.featured ? "primary" : "danger"}>
                          {cats.featured ? "Yes" : "No"} <i className="fa-solid fa-angle-down"></i>
                        </Badge>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => featuredChange(cats._id, true)}>Yes</Dropdown.Item>
                        <Dropdown.Item onClick={() => featuredChange(cats._id, false)}>No</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td>
                    <Dropdown className="text-center">
                      <Dropdown.Toggle variant="light" className="action" id="dropdown-basic">
                        <i className="fa-solid fa-ellipsis-vertical"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <NavLink to={`/admin/edit-services-main-cat/${cats._id}`} className="text-decoration-none">
                            <i className="fa-solid fa-pen-to-square" style={{ color: "blue" }}></i> <span>Edit</span>
                          </NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div onClick={() => handleDeleteMainCat(cats._id)}><i className="fa-solid fa-trash" style={{ color: "red" }}></i> <span>Delete</span></div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>

          </table>
        </div>

      </div>
    </>
  )
}

export default ViewServicesMainCat;