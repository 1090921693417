import React, { useState, useEffect, useContext } from "react";
import "./lhome.css";
import { Alert } from "react-bootstrap";
import {
  loginAlert,
  headerMenu,
  postSearchKey,
  postFeaturedKey,
  postTagsKey,
} from "../../../components/context/ContextProvider";
import {
  getAllPostsWithUser,
  getUserLikedPosts,
  postLikeUnlike,
  addComment,
  increasePostShareViews,
} from "../../../../services/Apis";
import { BASE_URL } from "../../../../services/helper";
import WordLimit from "../../../components/wordLimit/WordLimit";
import { isTokenValid } from "../../../components/functions/OtherFunctions";
import { useNavigate } from "react-router-dom";
import SharePostModal from "./SharePostModal";
// import Profile from "../../../../../public/assets/images/profile.jpg";
import { ToastContainer, toast } from "react-toastify";
const WEBSITE_URL = import.meta.env.VITE_WEBSITE_URL;

const LContent = () => {
  const navigate = useNavigate();
  const { login, setLogin } = useContext(loginAlert);

  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        setIsValid(valid);
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();

    if (login) {
      const timer = setTimeout(() => {
        setLogin("");
      }, 3000);

      // Cleanup the timer when the component unmounts
      return () => clearTimeout(timer);
    }
  }, []);

  const username = sessionStorage.getItem("user_username") || "";
  const { searchKey, setSearchKey } = useContext(postSearchKey);
  const { featuredKey, setFeaturedKey } = useContext(postFeaturedKey);
  const { tagsKey, setTagsKey } = useContext(postTagsKey);

  const postClick = async (post_id, post_by) => {
    try {
      const response = await increasePostShareViews(post_id, {
        action: "viewed",
      });
      if (response.status === 200) {
        navigate(`view-post/${post_by}/${post_id}`);
      } else {
        toast.error("Cannot View Post");
      }
    } catch (error) {
      console.error("Error increasing post views:", error);
      toast.error("Cannot View Post");
    }


  };

  const [postLoading, setPostLoading] = useState(true);
  const [allPost, setAllPost] = useState([]);
  const fetchPosts = async () => {
    try {
      const res = await getAllPostsWithUser(
        searchKey,
        featuredKey,
        username,
        tagsKey
      );
      if (res.status === 200) {
        setAllPost(res.data);
      } else {
        console.log("Error in Fetching Posts.");
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setTimeout(() => {
        setPostLoading(false);
      }, 500);
    }
  };

  const [postLiked, setPostLiked] = useState([]);
  const getLikedPost = async () => {
    try {
      const res = await getUserLikedPosts(username);
      if (res.status === 200) {
        setPostLiked(res.data.likedPosts);
      } else {
        console.log("Error in Fetching Liked Posts.");
      }
    } catch (error) {
      console.error("Error fetching liked posts:", error);
    }
  };

  useEffect(() => {
    fetchPosts();
    if (username) {
      getLikedPost();
    }
  }, [searchKey, featuredKey, username, tagsKey]);

  const handleLike = async (post_id, posted_by) => {
    if (isValid) {
      const data = { post_id, liked_by: username, posted_by };
      try {
        const res = await postLikeUnlike(data);
        if (res.status === 200) {
          getLikedPost();
          fetchPosts();
        }
      } catch (error) {
        console.error("Error:", error.response.data);
      }
    } else {
      toast.error("Please Login First");
    }
  };

  const [commBox, setCommBox] = useState({
    post_id: "",
    show: false,
  });
  const showCommBox = (post_id) => {
    if (isValid) {
      setCommBox((prevState) => ({
        post_id: post_id,
        show: !prevState.show,
      }));
    } else {
      toast.error("Please Login First");
    }
  };

  const [comm, setComm] = useState("");
  const postComment = async (post_id, posted_by) => {
    if (isValid) {
      const commentData = {
        post_id,
        content: comm,
        commented_by: username,
        posted_by,
      };
      if (comm.trim() === "") {
        toast.error("Comment cannot be Empty");
      } else {
        try {
          const res = await addComment(commentData);
          if (res.status === 200) {
            setCommBox((prevState) => ({ post_id: "", show: !prevState.show }));
            setComm("");
            fetchPosts();
            toast.success("Comment Successfully");
          } else {
            toast.error("Comment Cannot be Added Now");
          }
        } catch (error) {
          console.error("Error:", error.response.data);
          toast.error("Comment Cannot be Added Now");
        }
      }
    } else {
      toast.error("Please Login First");
    }
  };

  const { showMenu, setShowMenu } = useContext(headerMenu);

  const [modalShow, setModalShow] = useState(false);
  const [postLink, setPostLink] = useState();
  const [postShareId, setPostShareId] = useState("");

  const handlePostModal = (post_id, username) => {
    setModalShow(true);
    setPostLink(`${WEBSITE_URL}/view-post/${username}/${post_id}`);
    setPostShareId(post_id);
  };

  return (
    <>
      <div
        className="content-box"
        id="lhome-wrap"
        style={showMenu ? { filter: "blur(3px)" } : {}}
      >
        <div className="feed box-content">
          {login && (
            <Alert variant="success" onClose={() => setLogin("")} dismissible>
              Welcome {login}
            </Alert>
          )}
          {postLoading ? (
            <div id="post-loader" className="row">
              {Array.from({ length: 8 }, (_, index) => (
                <div key={index} className="wrap-div col-3">
                  <div className="img-div"></div>
                  <div className="details-div">
                    <div className="left-div">
                      <div className="post-title">
                        <div className="title"></div>
                      </div>
                      <div className="user-div">
                        <div className="user-img"></div>
                        <div className="user-name"></div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : allPost.length > 0 ? (
            <div className="post-row">

              {allPost.map((post, index) => (
                <div className="card col-t-card" key={index}>
                  <div className=""
                    onClick={() => postClick(post._id, post.user.username)}

                  >
                    <img
                      src={`${BASE_URL}/uploads/posts-img/${post.post_img}`}
                      className="post_img"
                      alt={post.post_img}
                    />
                  </div>
                  <div className="card-body"
                  >
                    <div className="hover">
                      <div className="row card-row" style={{ paddingBottom: "10px" }}>
                        <div className="col-8 p-0">
                          <div className="img-title">
                            <WordLimit text={post.title} limit={20} />
                          </div>
                        </div>
                      </div>
                      <div className="row card-row">
                        <div className="col-8 d-flex justify-content-start align-items-center p-0" style={{ gap: "7px", marginTop: "9px" }}>
                          <div className="author-img">
                            {post.user.user_img ? (
                              <img
                                src={`${BASE_URL}/uploads/admins-users-img/${post.user.user_img}`}
                                alt={post.title}
                                width="28px"
                                height="28px"
                                className="rounded-circle"
                              />
                            ) : null}
                          </div>
                          <div className="author-title">
                            {post.user.username}
                          </div>
                        </div>

                        <div className="col-4 p-0 d-flex justify-content-end">
                          <div className="like_comment_btn">
                            <button className="d-block" type="button" onClick={() => handleLike(post._id, post.user.username)}>
                              {postLiked.length > 0 ? (
                                postLiked.some(
                                  (liked) => liked.post_id === post._id
                                ) ? (
                                  <svg width="19" height="20" viewBox="0 0 19 20" fill="red" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.54239 17.6182L9.54239 17.6182L9.53947 17.6198C9.52583 17.6276 9.51236 17.6307 9.5 17.6307C9.48764 17.6307 9.47417 17.6276 9.46053 17.6198L9.45761 17.6182C9.31071 17.5363 7.33979 16.4057 5.41272 14.6292C3.46399 12.8328 1.67838 10.484 1.67826 7.96902C1.67956 6.80701 2.1189 5.69771 2.89258 4.88331C3.66544 4.06977 4.70777 3.61712 5.78935 3.61576C7.16815 3.61584 8.35899 4.23687 9.10047 5.27639L9.5 5.83651L9.89953 5.27639C10.641 4.23687 11.8319 3.61584 13.2107 3.61576C14.2922 3.61712 15.3346 4.06977 16.1074 4.88331C16.8812 5.69777 17.3205 6.80717 17.3217 7.96928C17.3215 10.4842 15.5359 12.8328 13.5873 14.6292C11.6602 16.4057 9.68929 17.5363 9.54239 17.6182Z" stroke="red" stroke-width="0.98151" />
                                  </svg>
                                ) : (
                                  <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.54239 17.6182L9.54239 17.6182L9.53947 17.6198C9.52583 17.6276 9.51236 17.6307 9.5 17.6307C9.48764 17.6307 9.47417 17.6276 9.46053 17.6198L9.45761 17.6182C9.31071 17.5363 7.33979 16.4057 5.41272 14.6292C3.46399 12.8328 1.67838 10.484 1.67826 7.96902C1.67956 6.80701 2.1189 5.69771 2.89258 4.88331C3.66544 4.06977 4.70777 3.61712 5.78935 3.61576C7.16815 3.61584 8.35899 4.23687 9.10047 5.27639L9.5 5.83651L9.89953 5.27639C10.641 4.23687 11.8319 3.61584 13.2107 3.61576C14.2922 3.61712 15.3346 4.06977 16.1074 4.88331C16.8812 5.69777 17.3205 6.80717 17.3217 7.96928C17.3215 10.4842 15.5359 12.8328 13.5873 14.6292C11.6602 16.4057 9.68929 17.5363 9.54239 17.6182Z" stroke="#ffff" stroke-width="0.98151" />
                                  </svg>
                                )
                              ) : (
                                <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M9.54239 17.6182L9.54239 17.6182L9.53947 17.6198C9.52583 17.6276 9.51236 17.6307 9.5 17.6307C9.48764 17.6307 9.47417 17.6276 9.46053 17.6198L9.45761 17.6182C9.31071 17.5363 7.33979 16.4057 5.41272 14.6292C3.46399 12.8328 1.67838 10.484 1.67826 7.96902C1.67956 6.80701 2.1189 5.69771 2.89258 4.88331C3.66544 4.06977 4.70777 3.61712 5.78935 3.61576C7.16815 3.61584 8.35899 4.23687 9.10047 5.27639L9.5 5.83651L9.89953 5.27639C10.641 4.23687 11.8319 3.61584 13.2107 3.61576C14.2922 3.61712 15.3346 4.06977 16.1074 4.88331C16.8812 5.69777 17.3205 6.80717 17.3217 7.96928C17.3215 10.4842 15.5359 12.8328 13.5873 14.6292C11.6602 16.4057 9.68929 17.5363 9.54239 17.6182Z" stroke="#ffff" stroke-width="0.98151" />
                                </svg>
                              )}
                            </button>

                            <button
                              className="d-block"
                              onClick={() => setCommBox({ post_id: post._id, show: !commBox.show })}
                            >
                              <svg
                                width="18"
                                height="17"
                                viewBox="0 0 18 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M15.9374 8.49999C15.9385 9.36024 15.7599 10.2122 15.4118 11.0072C15.0637 11.8021 14.5528 12.5244 13.9086 13.1325C13.2641 13.7414 12.4988 14.2243 11.6565 14.5537C10.8142 14.883 9.91149 15.0524 8.99987 15.0521C8.01871 15.0541 7.04838 14.8585 6.15362 14.4783L3.25337 14.8835C3.10937 14.9057 2.9618 14.8944 2.82349 14.8505C2.68518 14.8066 2.56031 14.7315 2.45972 14.6317C2.35913 14.5318 2.28586 14.4103 2.24629 14.2777C2.20671 14.1451 2.20202 14.0053 2.23262 13.8706L2.63537 11.0918C2.25226 10.2759 2.05695 9.39249 2.06237 8.49999C2.0612 7.63974 2.2398 6.78773 2.58794 5.9928C2.93607 5.19786 3.44689 4.47563 4.09112 3.86749C4.73566 3.25862 5.50094 2.77569 6.34322 2.44632C7.18549 2.11694 8.08824 1.94758 8.99987 1.94791C10.8408 1.94802 12.6064 2.63846 13.9086 3.86749C14.5525 4.47586 15.0631 5.19814 15.4112 5.99302C15.7593 6.7879 15.9381 7.6398 15.9374 8.49999Z"
                                  stroke="#ffff"
                                  strokeWidth="0.891534"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                      {commBox.post_id === post._id && commBox.show && (
                        <div className="row comment-row" style={{ zIndex: "100" }}>
                          <form onSubmit={(e) => {
                            e.preventDefault();
                            postComment(post._id, post.user.username);
                          }}>
                            <div className="col-9 p-0">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={comm}
                                  onChange={(e) => setComm(e.target.value)}
                                  placeholder="Enter comment"
                                />
                              </div>
                            </div>
                            <div className="col-3 p-0 text-center">
                              <button
                                className="post-btn"
                                type="submit"
                              >
                                Post
                              </button>
                            </div>
                          </form>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

              ))}
            </div>

          ) : (
            <div>No Posts to Show</div>
          )}

          <div className="d-flex"></div>
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={1000} />
      <SharePostModal
        show={modalShow}
        postLink={postLink}
        onHide={() => setModalShow(false)}
        post_id={postShareId}
      />
    </>
  );
};

export default LContent;
