import React, { useState, useEffect, useContext } from 'react';
import '../jobs.css';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../../../services/helper';
import { mainTheme } from '../../../components/context/ContextProvider';
import { getFilteredJobs } from '../../../../services/Apis';
import TimeAgoConverter from '../../../components/functions/TimeAgoConverter';
import SmallFooter from '../../../components/footer/SmallFooter';
import { isTokenValid } from '../../../components/functions/OtherFunctions';
import { ToastContainer, toast } from 'react-toastify';
import SmallHeader from '../../../components/header/small-screen/SmallHeader';

const SJobs = () => {
  const { pageTheme, setPageTheme } = useContext(mainTheme);

  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        setIsValid(valid);
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();
  }, []);

  const [allJobs, setAllJobs] = useState([]);
  const [search, setSearch] = useState("");
  const [employmentType, setEmploymentType] = useState([]);
  const [designerType, setDesignerType] = useState([]);
  const [location, setLocation] = useState("");
  const [workplaceType, setWorkplaceType] = useState("");

  const [activeJobId, setActiveJobId] = useState("");
  const [jobData, setJobData] = useState("");

  const fetchJobs = async () => {
    try {
      const res = await getFilteredJobs(search, designerType, workplaceType, location, employmentType);
      if (res.status === 200) {
        setAllJobs(res.data);
        setActiveJobId(res.data[0]._id)
      } else {
        console.log("Error in Fetching Jobs.");
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, [search, employmentType, designerType, workplaceType, location]);

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    fetchJobs();
    toast.success("Filter Applied");
  };

  const viewJobClick = (id) => {
    navigate(`/view-job/${id}`);
  };

  return (
    <>
      <SmallHeader />
      <div className="container-fluid" id="jobs-page" class="small-jobs-page">
        <div className="job-main row">
          <div class="col-lg-12" className="banner-wrapper">
            <div class="job-head text-left">
              <div className="container_search">
                <form action="" onSubmit={handleFilterSubmit}>
                  <div className="search-bar">
                    <div className="input_section">
                      <svg width="20" height="20" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.438 14.4654H15.3991L15.0309 14.1103C16.3643 12.564 17.0971 10.5896 17.0955 8.54774C17.0955 6.85716 16.5942 5.20454 15.6549 3.79887C14.7157 2.39321 13.3807 1.29762 11.8188 0.650662C10.2569 0.0037036 8.53826 -0.16557 6.88016 0.164246C5.22206 0.494063 3.699 1.30816 2.50358 2.50358C1.30816 3.699 0.494063 5.22206 0.164246 6.88016C-0.16557 8.53826 0.0037036 10.2569 0.650662 11.8188C1.29762 13.3807 2.39321 14.7157 3.79887 15.6549C5.20454 16.5942 6.85716 17.0955 8.54774 17.0955C10.665 17.0955 12.6112 16.3196 14.1103 15.0309L14.4654 15.3991V16.438L21.0406 23L23 21.0406L16.438 14.4654ZM8.54774 14.4654C5.2733 14.4654 2.63008 11.8222 2.63008 8.54774C2.63008 5.2733 5.2733 2.63008 8.54774 2.63008C11.8222 2.63008 14.4654 5.2733 14.4654 8.54774C14.4654 11.8222 11.8222 14.4654 8.54774 14.4654Z" fill="#272D3B" />
                      </svg>
                      <input type="text" placeholder="Search by Company" onChange={(e) => setSearch(e.target.value)} />
                    </div>
                    <div className=" input_section">
                      <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 17.04L7.631 16.329C8.347 15.5097 8.99133 14.73 9.564 13.99L10.037 13.366C12.0123 10.7053 13 8.59433 13 7.033C13 3.701 10.314 1 7 1C3.686 1 1 3.701 1 7.033C1 8.59433 1.98767 10.7057 3.963 13.367L4.436 13.991C5.25355 15.0383 6.10877 16.0546 7 17.04Z" stroke="#272D3B" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M7 9.5C8.38071 9.5 9.5 8.38071 9.5 7C9.5 5.61929 8.38071 4.5 7 4.5C5.61929 4.5 4.5 5.61929 4.5 7C4.5 8.38071 5.61929 9.5 7 9.5Z" stroke="#272D3B" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                      <input type="text" placeholder="Location" onChange={(e) => setLocation(e.target.value)} />
                    </div>

                    <button className="find-job" type='submit' onSubmit={handleFilterSubmit}>Find Job</button>
                  </div>
                  <div className="filters">
                    <div className="filter">
                      <select>
                        <option value="date-post">Date Post</option>
                        <option value="date-post">24 hours ago</option>
                        <option value="date-post">1 week ago</option>
                        <option value="date-post">1 month ago</option>
                      </select>
                    </div>
                    <div className="filter">
                      <select onChange={(e) => setEmploymentType(e.target.value)} class="form-control">
                        <option value="">Job type</option>
                        <option value="fulltime">Full Time</option>
                        <option value="freelance">Freelance</option>
                      </select>
                    </div>
                    <div className="filter">
                      <select name="workplace_type" onChange={(e) => setWorkplaceType(e.target.value)} class="form-control">
                        <option value="">Location Type</option>
                        <option value="remote">Remote</option>
                        <option value="on-site">On-site</option>
                      </select>
                    </div>
                    <div className="filter">
                      <select name="designer_type" onChange={(e) => setDesignerType(e.target.value)} class=" form-control">
                        <option value="">Job Postion</option>
                        <option value="animation">Animation</option>
                        <option value="graphic_designer">Brand / Graphic Design</option>
                        <option value="illustration">Illustration</option>
                        <option value="leadership">Leadership</option>
                        <option value="mobile_design">Mobile Design</option>
                        <option value="visual_design">Visual Design</option>
                        <option value="ux_design">UX Design</option>
                        <option value="product_design">Product Design</option>
                        <option value="web_design">Web Design</option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </div>

          </div>

          <div class="col-lg-12">
            <div class="view-job-main row" style={{ "margin": "40px 0 0 7%" }}>
              <div class="col-lg-4 col-md-12 col-sm-12">
              </div>
              <div class="col-lg-8 col-md-12 col-sm-12">
                <div class="view-jobs">
                  <div className='recent-jobs' style={{ "margin": "15px 0" }}>
                    <p>Recent Jobs</p>
                  </div>
                  <div className='job_tabs_container'>
                    <div className="job_tabs">

                      {allJobs.map((job, index) => (
                        <button type="button" onClick={() => viewJobClick(job._id)} >
                          <div className="job_tab_left_content">
                            <div className="text_title">
                              <div className="d-flex gap-2 align-items-center">
                                <img src={`${BASE_URL}/uploads/jobs-img/${job.company_logo}`} alt="" width="30px" height="30px" style={{ "borderRadius": "50%" }} />
                                <h2>
                                  {job.company_name}
                                  <span>
                                    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                      <path d="M11.1622 17.1489L18.0242 21.2905L16.2032 13.4847L22.2658 8.23269L14.2823 7.55537L11.1622 0.19368L8.04208 7.55537L0.0585938 8.23269L6.12116 13.4847L4.30017 21.2905L11.1622 17.1489Z" fill="black" />
                                    </svg>
                                  </span>
                                </h2>
                              </div>

                              {/* <div>
                              <button>
                                <svg width="4" height="14" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                  <path d="M3.5 12C3.5 12.3978 3.34196 12.7794 3.06066 13.0607C2.77936 13.342 2.39782 13.5 2 13.5C1.60218 13.5 1.22064 13.342 0.93934 13.0607C0.658035 12.7794 0.5 12.3978 0.5 12C0.5 11.6022 0.658035 11.2206 0.93934 10.9393C1.22064 10.658 1.60218 10.5 2 10.5C2.39782 10.5 2.77936 10.658 3.06066 10.9393C3.34196 11.2206 3.5 11.6022 3.5 12ZM3.5 7C3.5 7.39782 3.34196 7.77936 3.06066 8.06066C2.77936 8.34196 2.39782 8.5 2 8.5C1.60218 8.5 1.22064 8.34196 0.93934 8.06066C0.658035 7.77936 0.5 7.39782 0.5 7C0.5 6.60218 0.658035 6.22064 0.93934 5.93934C1.22064 5.65804 1.60218 5.5 2 5.5C2.39782 5.5 2.77936 5.65804 3.06066 5.93934C3.34196 6.22064 3.5 6.60218 3.5 7ZM3.5 2C3.5 2.39782 3.34196 2.77936 3.06066 3.06066C2.77936 3.34196 2.39782 3.5 2 3.5C1.60218 3.5 1.22064 3.34196 0.93934 3.06066C0.658035 2.77936 0.5 2.39782 0.5 2C0.5 1.60218 0.658035 1.22064 0.93934 0.93934C1.22064 0.658035 1.60218 0.5 2 0.5C2.39782 0.5 2.77936 0.658035 3.06066 0.93934C3.34196 1.22064 3.5 1.60218 3.5 2Z" fill="black" />
                                </svg>
                              </button>
                            </div> */}

                            </div>
                            <div className="job_details">
                              <h2>
                                {job.title.substring(0, 13) + "..."}
                                <span>
                                  {job.workplace_type === "remote" ? "Remotely" :
                                    job.workplace_type === "on_site" ? "On-Site" :
                                      job.workplace_type === "hybrid" ? "Hybrid" :
                                        "Not Specified"}
                                </span>
                              </h2>
                              <p className="loction">
                                {" "}
                                <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 12.9993L5.97325 12.4674C6.51025 11.8544 6.9935 11.2712 7.423 10.7176L7.77775 10.2508C9.25925 8.26039 10 6.68119 10 5.51318C10 3.02057 7.9855 1 5.5 1C3.0145 1 1 3.02057 1 5.51318C1 6.68119 1.74075 8.26064 3.22225 10.2515L3.577 10.7183C4.19016 11.5018 4.83158 12.2621 5.5 12.9993Z" stroke="#545454" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M5.5 7.375C6.53553 7.375 7.375 6.53553 7.375 5.5C7.375 4.46447 6.53553 3.625 5.5 3.625C4.46447 3.625 3.625 4.46447 3.625 5.5C3.625 6.53553 4.46447 7.375 5.5 7.375Z" stroke="#545454" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                {job.location}
                              </p>

                              <div className="s_jType gap-2">
                                {job.pay ? (
                                  <div className="salary">
                                    <span>{job.pay}</span>
                                  </div>
                                ) : ('')}
                                <div className="job_type">
                                  <span>{job.employment_type == "fulltime" ? "Full Time" : "Freelance / Contract Hire"}</span>
                                </div>
                              </div>
                            </div>
                            <span className="post_Job_time">Posted <TimeAgoConverter dateTime={job.offer_date} /> ago</span>
                          </div>
                        </button>
                      ))}

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
      <ToastContainer position="top-center" autoClose={1000} />
      <SmallFooter />
    </>
  )
}

export default SJobs