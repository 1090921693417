import React, { useState, useEffect, useContext } from "react";
import HeaderTop from "../../../components/header/large-screen/HeaderTop";
import HomeSidebar from "../../home/large-view/HomeSidebar";
import { commonSidebar } from "../../../components/context/ContextProvider";
import TabService from "../TabService";
import "./Category.css";
import "../service.css";
import { useParams } from "react-router-dom";
import SmallHeader from "../../../components/header/small-screen/SmallHeader";
import SmallFooter from "../../../components/footer/SmallFooter";
import { useNavigate } from "react-router-dom";
import "./Category.css";
import { getMainAndSubCatsByMainCat, getRecommendedServices } from "../../../../services/Apis";
import { BASE_URL } from "../../../../services/helper";

const ServicesSubCategory = () => {
  const { showMenu, setShowMenu } = useContext(commonSidebar);
  const checkAndNavigate = (navigateTo) => {
    if (isValid) {
      navigate(navigateTo);
    } else {
      // alert("Please Login First");
      // navigate("/login");
      toast.error("Please Login First", {
        onClose: () => navigate("/login")
      });
    }
  };
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 700);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 700);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();

  const { main_cat, sub_cat } = useParams();

  const [mainCatTitle, setMainCatTitle] = useState(main_cat);
  const [subCatTitle, setSubCatTitle] = useState(sub_cat);

  const fetchMainSubCats = async () => {
    try {
      const res = await getMainAndSubCatsByMainCat(main_cat);
      if (res.status === 200) {
        console.log(res.data)
        setMainCatTitle(res.data.mainCategory.main_cat_title)
        // Find the sub-category title by matching sub_cat
        const matchingSubCat = res.data.subCategories.find(
          (subCategory) => subCategory.sub_cat === sub_cat
        );
        setSubCatTitle(matchingSubCat.sub_cat_title);
      } else {
        console.log('Error in fetching Categories.');
      }
    } catch (error) {
      console.error('Error fetching Categories:', error);
    }
  };

  const [servicesData, setServicesData] = useState([]);
  const fetchRecommendedServices = async () => {
    try {
      const res = await getRecommendedServices(`main_cat=${main_cat}&sub_cat=${sub_cat}`);
      if (res.status === 200) {
        setServicesData(res.data);
      } else {
        console.log('Error in fetching Services.');
      }
    } catch (error) {
      console.error('Error fetching Services:', error);
    }
  };

  useEffect(() => {
    fetchMainSubCats();
    fetchRecommendedServices();
  }, [main_cat, sub_cat]);

  // Navigate to a new page on button click
  const handleButtonClick = (service_by, service_id) => {
    navigate(`/view-service/${service_by}/${service_id}`);
  };

  return (
    <div id="single_category">
      {isSmallScreen ? <SmallHeader /> : <HeaderTop />}
      <HomeSidebar showMenu={showMenu} checkAndNavigate={checkAndNavigate} />

      <TabService />
      <div
        className="container_sec"
        style={showMenu ? { filter: "blur(2px)" } : {}}
      >
        <div className="head_text">
          <h2>{mainCatTitle}</h2>
          <p>{subCatTitle}</p>
        </div>

        <div className="row  single_cate_row">
        {servicesData.map((data) => (
          <div className="col-lg-3 col-sm-1">
            <div className="category_card" id="recommanded_service" onClick={()=>handleButtonClick(data.userInfo.username ,data._id)}>
              <div className="top_rate_slider">
                <img src={`${BASE_URL}/uploads/services-img/${data.service_img}`} alt="" className="service_img" />
                <div className="user_contact">
                  <img src={`${BASE_URL}/uploads/admins-users-img/${data.userInfo.user_img}`} alt="" />
                  <h2> {data.userInfo.fname+" "+data.userInfo.lname}</h2>
                </div>
                <p>{data.title}</p>
                <div className="price_like">
                  <h2>From ₹{data.service_price}</h2>
                  <div>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="10" cy="10" r="10" fill="#F9F9F9" />
                      <path d="M12.8934 12.7871C11.8224 13.8102 10.729 14.5024 10.4417 14.6767C10.1544 14.5024 9.06099 13.8102 7.99007 12.7871C6.76849 11.62 5.68423 10.1248 5.68411 8.54785C5.68492 7.82452 5.94906 7.13872 6.40703 6.63912C6.86369 6.14095 7.47323 5.86975 8.09901 5.8689C8.90537 5.869 9.60116 6.24374 10.0375 6.87764L10.4417 7.465L10.846 6.87764C11.2823 6.24374 11.978 5.869 12.7844 5.8689C13.4102 5.86975 14.0197 6.14095 14.4764 6.63912C14.9344 7.13878 15.1986 7.82468 15.1993 8.54811C15.1991 10.1249 14.1149 11.6201 12.8934 12.7871Z" stroke="#272D3B" stroke-width="0.98151" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        </div>
      </div>

      {isSmallScreen ? <SmallFooter /> : ""}
    </div>
  );
}

export default ServicesSubCategory