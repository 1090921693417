import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getSingleServiceById } from '../../../../services/Apis';
import AccessChecker from '../../../components/OtherFunctions/AccessChecker';
import 'react-quill/dist/quill.snow.css';
import { BASE_URL } from '../../../../services/helper';

const ViewSingleService = () => {
  const { id } = useParams();

  const [serviceData, setServiceData] = useState({
    title: "",
    main_cat_title: "",
    sub_cat_title: "",
    service_price: "",
    description: "",
    service_img: "",
    featured: "",
    status: ""
  });

  const fetchSingleService = async () => {
    try {
      const res = await getSingleServiceById(id);
      if (res.status === 200) {
        setServiceData(res.data);
      } else {
        console.log('Error in Fetching Service.');
      }
    } catch (error) {
      console.error('Error fetching Services:', error);
    }
  };

  useEffect(() => {
    fetchSingleService()
  }, []);

  return (
    <>
      <AccessChecker accessKey="add_post" />
      <div className='form-card add-service' id="add-post">
        <div className="row">
          <div className="col-md-12 form-head d-flex justify-content-between">
            <h1>View Service</h1>
            <div><Link to={`/admin/edit-service/${id}`} ><button className='btn btn-primary'>Edit Service</button></Link></div>
          </div>
        </div>
        <hr />
        <form className='form-div' encType="multipart/form-data">
          <div className="form-group">
            <div className="row">
              <div className="col-lg-6">
                <div className="img-upload-wrap">
                  <div className='upld-img-icon' style={{ "height": "320px" }}>
                    <label htmlFor="imageInput" style={{ cursor: 'pointer' }}><img src={`${BASE_URL}/uploads/services-img/${serviceData.service_img}`} alt="Post Image" /></label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row d-grid h-100">
                  <div className="col-lg-12">
                    <label>Main Category</label>
                    <input type="text" className="form-control" value={serviceData.main_cat_title} readOnly />
                  </div>
                  <div className="col-lg-12">
                    <label>Sub Category</label>
                    <input type="text" className="form-control" value={serviceData.sub_cat_title} readOnly />
                  </div>
                  <div className="col-lg-12">
                    <label>Service Title</label>
                    <input type="text" className="form-control" value={serviceData.title} readOnly />
                  </div>
                  <div className="col-lg-12">
                    <label>Service Charge (Starts From)</label>
                    <input type="text" className="form-control" value={serviceData.service_price} readOnly />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-lg-12">
                <label>Description</label>
                <div dangerouslySetInnerHTML={{ __html: serviceData.description }} />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-lg-4">
                <label>Featured</label>
                <input type="text" className="form-control" value={serviceData.featured} readOnly />
              </div>
              <div className="col-lg-4">
                <label>Status</label>
                <input type="text" className="form-control" value={serviceData.status} readOnly />
              </div>
              <div className="col-lg-4 d-grid justify-content-end">
                <div className='d-flex justify-content-end pt-2'>
                  <button type="submit" className="btn btn-primary">Update Service</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default ViewSingleService;