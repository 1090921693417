import React, { useState } from "react";
import NotInterstd from "../../../../../public/assets/images/sad.svg";
import FReport from "../../../../../public/assets/images/f-report.svg";
import Block from "../../../../../public/assets/images/block.svg";
import ReportPost from "../../../../../public/assets/images/report.svg";

const ReportContent = () => {
  // State to manage popup visibility
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  // Function to toggle popup visibility
  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  return (
    <div>
      {/* SVG icon with onClick event to toggle popup */}
      <div className="report_opt_content d-flex" onClick={togglePopup}>
      <img src={ReportPost} alt="" /> <p>Report Post</p>
      </div>

      {/* Conditionally render the report popup */}
      {isPopupVisible && (
        <div className="report-popup content">
            <h3>What type of issue are you reporting?</h3>
          {/* Your popup content goes here */}
          <button className="report_cause">
            {" "}
            <div>
            <h4>Hate</h4>
            <p> Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
            </div>
        <div>
            <input type="radio" />
        </div>
          </button>
          <button className="report_cause">
            {" "}
            <div>
            <h4>Abuse and Harassment</h4>
            <p> Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
            </div>
        <div>
            <input type="radio" />
        </div>
          </button>
       
          <button className="report_cause">
            {" "}
            <div>
            <h4>Violent Speech</h4>
            <p> Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
            </div>
        <div>
            <input type="radio" />
        </div>
          </button>
          <button className="closeBtn" onClick={togglePopup}>
            Submit
          </button>
        </div>
      )}
    </div>
  );
};

export default ReportContent;
