import React, { useState, useEffect } from 'react';
import { editServicesSubCat, getServicesMainCats, getServicesSubCats, getSingleServicesSubCat } from '../../../../services/Apis';
import AccessChecker from '../../../components/OtherFunctions/AccessChecker';
import { ToastContainer, toast } from 'react-toastify';
import '../services.css';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../../../services/helper';

const EditServicesSubCat = () => { 

  const [catData, setCatData] = useState({
    main_cat_id:"",
    sub_cat_title:"",
    sub_cat:"",
    featured:""
  });

  const [subCatImg, setSubCatImg] = useState("")
  const [showCatImg, setShowCatImg] = useState("");

  const {id} = useParams();

  const fetchSingleSubCat = async () => {
    const response = await getSingleServicesSubCat(id);
    if (response.status === 200) {
        setCatData(response.data.singleSubCat);
        setSubCatImg(response.data.singleSubCat.sub_cat_img);
    } else {
      console.log("Error in Fetching Sub Cat Details.");
    }
  };

  const [mainCats, setMainCats] = useState([]);

  const fetchMainCats = async () => {
    try {
      const res = await getServicesMainCats();
      if (res.status === 200) {
        setMainCats(res.data);
      } else {
        console.log('Error in Fetching Users.');
      }
    } catch (error) {
      console.error('Error fetching Users:', error);
    }
  };

  useEffect(() => {
    fetchSingleSubCat();
    fetchMainCats();
  }, []);

  //<------------------- Update Services Main Category ------------------->

  const selectSubCatImg = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (allowedFileTypes.includes(selectedFile.type)) {
        setShowCatImg(URL.createObjectURL(selectedFile));
        setSubCatImg(selectedFile);
      } else {
        alert("Only png, jpg, jpeg Images are Allowed");
      }
    }
  }

  const setInputValue = (e) => {
    const { name, value } = e.target;
    setCatData({ ...catData, [name]: value });
  }

  const submitCategory = async (e) => {
    e.preventDefault();
    const {_id, main_cat_id, sub_cat_title, sub_cat, featured } = catData;
    const formData = new FormData();
    formData.append("main_cat_id", main_cat_id);
    formData.append("sub_cat_title", sub_cat_title);
    formData.append("sub_cat", sub_cat);
    formData.append("featured", featured);
    formData.append("sub_cat_img", subCatImg);
    const config = { "Content-Type": "multipart/form-data" }
    const res = await editServicesSubCat(_id, formData, config);
    if (res.status === 200) {
      fetchSingleSubCat();
      alert("Sub Category Updated Successfully");
    }
    else {
      console.log(res.response.data.message);
      if (res.response.data.message) {
        alert(res.response.data.message)
      }
      else {
        alert("Some Unknown Error Occured")
      }
    }
  };

  return (
    <>
      <AccessChecker accessKey="change_post_settings" />
      <div className='form-card' id="services-cats">
        <div className="row">
          <div className="col-md-12 form-head">
            <h1>Edit Services Main Category</h1>
          </div>
        </div>
        <hr />
        <form className='form-div' onSubmit={submitCategory} encType="multipart/form-data">
          <div class="form-group pt-3">
            <div className="row">
              <div className="col-lg-6">
                <label>Select Main Category</label>
                <select name="main_cat_id" onChange={setInputValue} className="form-control" value={catData.main_cat_id} required>
                  <option value="0">Select your Post Category</option>
                  {mainCats.map((cat, index) => (
                    <option key={index} value={cat._id}>{cat.main_cat_title}</option>
                  ))}
                </select>
              </div>
              <div className="col-lg-6">
                <label>Category Title</label>
                <input type="text" class="form-control mt-2" placeholder="Enter Category Title" value={catData.sub_cat_title} name="sub_cat_title" onChange={setInputValue} required />
              </div>
              <div className="col-lg-6">
                <label>Category</label>
                <input type="text" class="form-control mt-2" placeholder="Enter Service Category" value={catData.sub_cat} name="sub_cat" onChange={setInputValue} required />
              </div>
              <div className="col-lg-6">
                <label>Sub Category Image</label>
                <input type='file' class="form-control" onChange={selectSubCatImg}/>
              </div>
                <div className="col-lg-12">
                <img src={!showCatImg ? `${BASE_URL}/uploads/services-cat-img/${subCatImg}` : showCatImg} style={{"width":"100%","height":"auto","maxHeight":"300px"}}/>
              </div>
              <div className="col-lg-6">
                <label>Featured</label>
                <select className='form-control' value={catData.featured} name="featured" onChange={setInputValue}>
                  <option>--------- Select Option ---------</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
              <div className="col-lg-6 d-grid justify-content-end align-items-end">
                <button type="submit" class="btn btn-primary">Update Category</button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer position="top-center" autoClose={1000} />
    </>
  )
}

export default EditServicesSubCat;