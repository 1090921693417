import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../../../services/helper';

import ProfileImg from "../../../../../public/assets/images/default-user.png"
import { getSingleUser } from "../../../../services/Apis";

const RightSideBar = () => {
  const navigate = useNavigate();
  
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  // Function to toggle popup visibility
  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };
  // useEffect(() => {
  //   if (isValid) {
  //     const fetchData = async () => {
  //       await fetchMsgNotif();
  //       await fetchAllNotifs();
  //     };
  //     fetchData(); // Call initially

  //     const interval = setInterval(fetchData, 1000); // Call every second
  //     return () => clearInterval(interval); // Cleanup
  //   }
  // }, [username]);

  const username = sessionStorage.getItem("user_username") || "";
  const [userData, setUserData] = useState({});
  const fetchUserData = async()=>{
    try {
      const res = await getSingleUser(username);
      if (res.status === 200) {
        setUserData(res.data);
      } else {
        console.log("Error in Fetching User Details.");
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  }

  useEffect(()=>{
    if(username){
      fetchUserData();
    }
  },[username])

  const userLogout = () => {
    sessionStorage.clear();
    setIsPopupVisible(false);
    navigate("/");
    window.location.reload();
}
  return (
    <div>
      {/* SVG icon with onClick event to toggle popup */}
      <div className="" onClick={togglePopup}>
        <svg
          width="4"
          height="12"
          viewBox="0 0 4 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.3125 10.375C3.3125 10.7231 3.17422 11.0569 2.92808 11.3031C2.68194 11.5492 2.3481 11.6875 2 11.6875C1.6519 11.6875 1.31806 11.5492 1.07192 11.3031C0.825781 11.0569 0.6875 10.7231 0.6875 10.375C0.6875 10.0269 0.825781 9.69306 1.07192 9.44692C1.31806 9.20078 1.6519 9.0625 2 9.0625C2.3481 9.0625 2.68194 9.20078 2.92808 9.44692C3.17422 9.69306 3.3125 10.0269 3.3125 10.375ZM3.3125 6C3.3125 6.3481 3.17422 6.68194 2.92808 6.92808C2.68194 7.17422 2.3481 7.3125 2 7.3125C1.6519 7.3125 1.31806 7.17422 1.07192 6.92808C0.825781 6.68194 0.6875 6.3481 0.6875 6C0.6875 5.6519 0.825781 5.31806 1.07192 5.07192C1.31806 4.82578 1.6519 4.6875 2 4.6875C2.3481 4.6875 2.68194 4.82578 2.92808 5.07192C3.17422 5.31806 3.3125 5.6519 3.3125 6ZM3.3125 1.625C3.3125 1.9731 3.17422 2.30694 2.92808 2.55308C2.68194 2.79922 2.3481 2.9375 2 2.9375C1.6519 2.9375 1.31806 2.79922 1.07192 2.55308C0.825781 2.30694 0.6875 1.9731 0.6875 1.625C0.6875 1.2769 0.825781 0.943064 1.07192 0.696922C1.31806 0.450781 1.6519 0.3125 2 0.3125C2.3481 0.3125 2.68194 0.450781 2.92808 0.696922C3.17422 0.943064 3.3125 1.2769 3.3125 1.625Z"
            fill="black"
          />
        </svg>
      </div>

      {/* Conditionally render the report popup */}
      {isPopupVisible && (
        <div className="Right_side_bar">
          <button className="closeBtn" onClick={togglePopup}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 1L1 13M1 1L13 13" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

          </button>
          {/* Your popup content goes here */}
          <div className="ProfileSetUp">
            <img src={`${BASE_URL}/uploads/admins-users-img/${userData.user_img}`} alt="" style={{"borderRadius":"50%"}}/>
            <h2>{userData.fname+" "+userData.lname}</h2>
          </div>
          <hr />
          <div className="">
            <div className='w-100'>
              <ul>
                {/* <li><NavLink><i className="bi bi-person-square"></i> &nbsp; Your Channel</NavLink></li> */}
                <li><NavLink to="/login"><i className="bi bi-toggles2"></i> &nbsp; Switch Accounts</NavLink></li>
                <li><NavLink to="/edit-profile"><i className="bi bi-gear"></i> &nbsp; Settings</NavLink></li>
                <li><NavLink to="/contact-us"><i className="bi bi-envelope"></i> &nbsp; Contact Us</NavLink></li>
              </ul>
              <hr style={{ "margin": "0" }} />
              <ul>
                <li onClick={() => userLogout()}><NavLink><i className="bi bi-box-arrow-right"></i> &nbsp; Sign Out</NavLink></li>
                {/* <li><NavLink><i className="bi bi-question-circle"></i> &nbsp; Help</NavLink></li> */}
                {/* <li><NavLink><i className="bi bi-chat-right-quote"></i> &nbsp; Send Feedback</NavLink></li> */}
              </ul>
              <span><NavLink>Privacy Policy</NavLink>&nbsp;.&nbsp;<NavLink>Terms of Service</NavLink></span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RightSideBar;
