import React, { useState, useEffect } from 'react';
import { addServiceMainCat, addServiceSubCat, getServicesMainCats } from '../../../../services/Apis';
// import AccessChecker from '../../../components/OtherFunctions/AccessChecker';
import AccessChecker from '../../../components/OtherFunctions/AccessChecker';
// import './post.css';
import { ToastContainer, toast } from 'react-toastify';
import banner_img from './main-cat-banner.png';
import '../services.css';

const AddServicesCats = () => {
  
  //<------------------- Fetch Main Category ------------------->
  const [servicesCat, setServicesCat] = useState([]);

  const fetchAllMainCategory = async () => {
    const response = await getServicesMainCats();
    if (response.status === 200) {
      setServicesCat(response.data);
    } else {
      console.log("Error in Fetching Admin Details.");
    }
  };

  useEffect(() => {
    fetchAllMainCategory();
  }, []);

  //<------------------- Add Services Main Category ------------------->
  const [mainCatTitle, setMainCatTitle] = useState("");
  const [mainCat, setMainCat] = useState("");
  const [mainCatCaption, setMainCatCap] = useState("");
  const [mainfeatured, setMainFeatured] = useState("");
  const [bannerImg, setBannerImg] = useState("");
  const [showBannerImg, setShowBannerImg] = useState("");

  const selectBannerImg = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (allowedFileTypes.includes(selectedFile.type)) {
        setShowBannerImg(URL.createObjectURL(selectedFile));
        setBannerImg(selectedFile);
      } else {
        alert("Only png, jpg, jpeg Images are Allowed");
      }
    }
  }

  const submitCategory = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("main_cat_title", mainCatTitle);
    formData.append("main_cat", mainCat);
    formData.append("main_cat_caption", mainCatCaption);
    formData.append("featured", mainfeatured);
    formData.append("banner_img", bannerImg);
    const config = { "Content-Type": "multipart/form-data" }
    const res = await addServiceMainCat(formData, config);
    if (res.status === 200) {
      setMainCatTitle("");
      setMainCat("");
      setMainFeatured("");
      setBannerImg("");
      setShowBannerImg("");
      setBannerImg("");
      setMainCatCap("");
      alert("Category Added Successfully");
      fetchAllMainCategory();
    }
    else {
      console.log(res.response.data.message);
      if (res.response.data.message) {
        alert(res.response.data.message)
      }
      else {
        alert("Some Unknown Error Occured")
      }
    }
  };

  //<------------------- Add Services Sub Category ------------------->
  const [mainCatId, setMaincatId] = useState("");
  const [subCatTitle, setSubCatTitle] = useState("");
  const [subCat, setSubCat] = useState("");
  const [subFeatured, setSubFeatured] = useState("");
  const [subCatImg, setSubCatImg] = useState("");
  const [showSubCatImg, setShowSubCatImg] = useState("");

  const selectSubCatImg = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (allowedFileTypes.includes(selectedFile.type)) {
        setShowSubCatImg(URL.createObjectURL(selectedFile));
        setSubCatImg(selectedFile);
      } else {
        alert("Only png, jpg, jpeg Images are Allowed");
      }
    }
  }

  const submitSubCategory = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("main_cat_id", mainCatId);
    formData.append("sub_cat_title", subCatTitle);
    formData.append("sub_cat", subCat);
    formData.append("featured", subFeatured);
    formData.append("sub_cat_img", subCatImg);
    const config = { "Content-Type": "multipart/form-data" }
    const res = await addServiceSubCat(formData, config);
    if (res.status === 200) {
      setMaincatId("");
      setSubCatTitle("");
      setSubCat("");
      setSubFeatured("");
      setSubCatImg("");
      setShowSubCatImg("");
      alert("Sub Category Added Successfully");
    }
    else {
      console.log(res.response.data.message);
      if (res.response.data.message) {
        alert(res.response.data.message)
      }
      else {
        alert("Some Unknown Error Occured")
      }
    }
  };

  return (
    <>
      <AccessChecker accessKey="change_post_settings" />
      <div className='form-card' id="services-cats">
        <div className="row">
          <div className="col-md-12 form-head">
            <h1>Services Categories</h1>
          </div>
        </div>
        <hr />
        <form className='form-div' onSubmit={submitCategory} encType="multipart/form-data">
          <div>
            <h5>Main Category</h5>
            <hr />
          </div>
          <div class="form-group pt-3">
            <div className="row">
              <div className="col-lg-6">
                <label>Category Title</label>
                <input type="text" class="form-control mt-2" placeholder="Enter Category Title" value={mainCatTitle} onChange={(e) => setMainCatTitle(e.target.value)} required />
              </div>
              <div className="col-lg-6">
                <label>Category</label>
                <input type="text" class="form-control mt-2" placeholder="Enter Service Category" value={mainCat} onChange={(e) => setMainCat(e.target.value)} required />
              </div>
              <div className="col-lg-6">
                <label>Featured</label>
                <select className='form-control' value={mainfeatured} onChange={(e) => setMainFeatured(e.target.value)}>
                  <option>--------- Select Option ---------</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
              <div className="col-lg-6">
                <label>Category Caption</label>
                <input type="text" class="form-control mt-2" placeholder="Enter Category Caption" value={mainCatCaption} onChange={(e) => setMainCatCap(e.target.value)} required />
              </div>
              {showBannerImg?(
                <div className="col-lg-12">
                <img src={showBannerImg} style={{"width":"100%","height":"auto","maxHeight":"300px"}}/>
              </div>
              ):('') }
              <div className="col-lg-6">
                <label>Category Banner</label>
                <input type='file' class="form-control" onChange={selectBannerImg}/>
              </div>
              <div className="col-lg-6 d-grid justify-content-end align-items-end">
                <button type="submit" class="btn btn-primary">Add Category</button>
              </div>
            </div>
          </div>
        </form>

        <hr />

        <form className='form-div' onSubmit={submitSubCategory} encType="multipart/form-data">
          <div>
            <h5>Sub Category</h5>
            <hr />
          </div>
          <div class="form-group pt-3">
            <div className="row">
              <div className="col-lg-6">
                <label>Select Main Category</label>
                <select onChange={(e) => setMaincatId(e.target.value)} className="form-control" required>
                  <option value="0">Select your Post Category</option>
                  {servicesCat.map((selectCat, index) => (
                    <option key={index} value={selectCat._id}>{selectCat.main_cat_title}</option>
                  ))}
                </select>
              </div>
              <div className="col-lg-6">
                <label>Sub Category Title</label>
                <input type="text" class="form-control mt-2" placeholder="Enter Sub Category Title" value={subCatTitle} onChange={(e) => setSubCatTitle(e.target.value)} required />
              </div>
              <div className="col-lg-6">
                <label>Sub Category</label>
                <input type="text" class="form-control mt-2" placeholder="Enter Sub Category" value={subCat} onChange={(e) => setSubCat(e.target.value)} required />
              </div>
              <div className="col-lg-6">
                <label>Sub Category Banner</label>
                <input type='file' class="form-control" onChange={selectSubCatImg}/>
              </div>
              {showSubCatImg?(
              <div className="col-lg-12">
                <img src={showSubCatImg} style={{"width":"100%","height":"auto","maxHeight":"300px"}}/>
              </div>
              ):('')}
              <div className="col-lg-6">
                <label>Featured</label>
                <select className='form-control' value={subFeatured} onChange={(e) => setSubFeatured(e.target.value)}>
                  <option>--------- Select Option ---------</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
              <div className="col-lg-6 d-flex justify-content-end align-items-end">
                <button type="submit" class="btn btn-primary">Add Sub Category</button>
              </div>
            </div>
          </div>
        </form>

      </div>
      <ToastContainer position="top-center" autoClose={1000} />
    </>
  )
}

export default AddServicesCats;