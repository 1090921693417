import React, { useEffect, useState, useContext } from "react";
import "./SHome.css";
import { loginAlert, postFeaturedKey } from "../../../components/context/ContextProvider";
import {getAllPostsWithUser, getUserLikedPosts, postLikeUnlike, addComment, increasePostShareViews, createChat, getUserFollowings, userFollowUnfollow, getAllCommentsWithUser, featuredTags, getUserCollections, addCollection } from "../../../../services/Apis";
import { BASE_URL } from "../../../../services/helper";
import TimeAgoConverter from "../../../components/functions/TimeAgoConverter";
import { isTokenValid } from "../../../components/functions/OtherFunctions";
import { useNavigate, NavLink } from "react-router-dom";
import SharePostModal from "../large-view/SharePostModal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReportComponent from "./report";
import { headerMenu } from "../../../components/context/ContextProvider";
import CommentBox from "./CommentBox";
const ForYou = () => {
  const navigate = useNavigate();

  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        setIsValid(valid);
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();
  }, []);

  //<----------------------------------------------- Filter Topbar ----------------------------------------------->
  const settings = {
    className: "slider variable-width",
    variableWidth: true,
    initialSlide: -1,
    swipeToSlide: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: true, // Enables variable width to handle the last slide better
        },
      },
    ],
  };

  const [featureTags, setFeatureTags] = useState([]);
  const [loadingTags, setLoadingTags] = useState(true);

  const fetchFeaturedTags = async () => {
    try {
      const res = await featuredTags();
      if (res.status === 200) {
        setFeatureTags(res.data);
      } else {
        console.log("Error in Fetching Featured Tags");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingTags(false);
    }
  };

  useEffect(() => {
    fetchFeaturedTags();
  }, []);

  const [tagsKey, setTagsKey] = useState("");

  const handleTagClick = (tag) => {
    if (tagsKey === tag) {
      setTagsKey("");
    } else {
      setTagsKey(tag);
    }
  };

  const username = sessionStorage.getItem("user_username") || "";
  const { login, setLogin } = useContext(loginAlert);
  const [featuredKey, setFeaturedKey] = useState("");
  const user_id = sessionStorage.getItem("user_id") || "";

  //<----------------------------------------------- Handle Modal Open and Set Data ----------------------------------------------->
  const postClick = async (post_id, post_by) => {
    const response = await increasePostShareViews(post_id, {
      action: "viewed",
    });
    if (response.status === 200) {
      navigate(`view-post/${post_by}/${post_id}`);
    } else {
      // alert("Cannot View Post");
      toast.error("Cannot View Post");
    }
  };

  //<----------------------------------------------- Fetch All Posts ----------------------------------------------->
  const [allPost, setAllPost] = useState([]);
  const [loadingPost, setLoadingPost] = useState(true);

  const fetchPosts = async () => {
    try {
      const res = await getAllPostsWithUser("", featuredKey, username, tagsKey);
      if (res.status === 200) {
        setAllPost(res.data);
      } else {
        console.log("Error in Fetching Posts.");
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setTimeout(() => {
        setLoadingPost(false);
      }, 500);
    }
  };

  //<----------------------------------------------- Fetch Posts Liked by user ----------------------------------------------->
  const [postLiked, setPostLiked] = useState([]);
  const getLikedPost = async () => {
    try {
      const res = await getUserLikedPosts(username);
      if (res.status === 200) {
        setPostLiked(res.data.likedPosts);
      } else {
        console.log("Error in Fetching Liked Posts.");
      }
    } catch (error) {
      console.error("Error fetching liked posts:", error);
    }
  };

  useEffect(() => {
    fetchPosts();
    if (username) {
      getLikedPost();
    }
  }, [username, tagsKey, featuredKey]);

  //<----------------------------------------------- Handle Like Unlike ----------------------------------------------->
  const handleLike = async (post_id, posted_by) => {
    if (isValid) {
      const data = { post_id, liked_by: username, posted_by };
      try {
        const res = await postLikeUnlike(data);
        if (res.status === 200) {
          getLikedPost();
          fetchPosts();
        }
      } catch (error) {
        console.error("Error:", error.response.data);
      }
    } else {
      toast.error("Please Login");
    }
  };

  //<----------------------------------------------- Handle Comment ----------------------------------------------->
  const [commBox, setCommBox] = useState({
    post_id: "",
    show: false,
  });
  const showCommBox = (post_id) => {
    if (isValid) {
      setCommBox((prevState) => ({
        post_id: post_id,
        show: !prevState.show,
      }));
    } else {
      // alert("Please Login First")
      toast.error("Please Login");
    }
  };

  const [comm, setComm] = useState("");
  const postComment = async (post_id, posted_by) => {
    const commentData = {
      post_id,
      content: comm,
      commented_by: username,
      posted_by,
    };
    if (comm.trim() === "") {
      toast.error("Comment cannot be Empty");
    } else {
      try {
        const res = await addComment(commentData);
        if (res.status === 200) {
          setCommBox((prevState) => ({ post_id: "", show: !prevState.show }));
          setComm("");
          fetchPosts();
          toast.success("Comment Successfully");
        } else {
          toast.error("Comment Cannot be Added Now");
        }
      } catch (error) {
        console.error("Error:", error.response.data);
        toast.error("Comment Cannot be Added Now");
      }
    }
  };

  //<----------------------------------------------- Message Field ----------------------------------------------->
  const handleOpenChat = async (post_by) => {
    if (isValid === true) {
      if (username == post_by) {
        navigate("/message");
      } else {
        const data = { sender: username, receiver: post_by };
        try {
          const config = { "Content-Type": "application/json" };
          const res = await createChat(data, config);
          if (res.status === 200) {
            navigate("/message");
          }
        } catch (error) {
          console.error("Error:", error.response.data);
        }
      }
    } else {
      toast.error("Please Login");
    }
  };
  //<----------------------------------------------- Follow Unfollow Field ----------------------------------------------->
  const [followings, setFollowings] = useState([]);
  const fetchFollowings = async () => {
    try {
      const res = await getUserFollowings(username);
      if (res.status === 200) {
        setFollowings(res.data.followings);
        console.log(res.data.followings);
      } else {
        console.log("Error in Fetching Collections.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFollow = async (post_user_id) => {
    if (isValid) {
      const data = { user_id: post_user_id, follower_id: user_id };
      try {
        const config = { "Content-Type": "application/json" };
        const res = await userFollowUnfollow(data, config);
        if (res.status === 200) {
          fetchFollowings();
        }
      } catch (error) {
        console.error("Error:", error.response.data);
      }
    } else {
      // alert("Please Login First");
      toast.error("Please Login First", {
        onClose: () => navigate("/login"),
      });
    }
  };
  
  //<----------------------------------------------- Post Share Modal ----------------------------------------------->

  const [modalShow, setModalShow] = useState(false);
  const [postLink, setPostLink] = useState();
  const [postShareId, setPostShareId] = useState("");
  const handlePostModal = (post_id) => {
    setModalShow(true);
    setPostLink(`https://www.yoeavnu/view-post/${post_id}`);
    setPostShareId(post_id);
  };

  const clearTagsFilter = () => {
    setTagsKey("");
  };

  //<----------------------------------------------- Fetch Collections ----------------------------------------------->
  const [collections, setCollections] = useState([]);
  const fetchCollections = async () => {
    try {
      const res = await getUserCollections(username);
      if (res.status === 200) {
        setCollections(res.data);
      } else {
        console.log("Error in Fetching Collections.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //<----------------------------------------------- Handle Collections ----------------------------------------------->
  const handleCollection = async (post_id, post_img, post_title, posted_by) => {
    if (username) {
      const data = {
        post_id,
        post_img,
        post_title: post_title,
        posted_by,
        user_id,
      };
      try {
        const config = { "Content-Type": "application/json" };
        const res = await addCollection(data, config);
        if (res.status === 200) {
          fetchCollections(username);
        }
      } catch (error) {
        console.error("Error:", error.response.data);
      }
    } else {
      toast.error("Please Login First");
    }
  };

  useEffect(() => {
    if (username) {
      fetchFollowings();
      fetchCollections();
    }
  }, []);
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    if (isValid) {
      if (isToggled) {
        setIsToggled(false);
        setFeaturedKey("all");
      }
      else {
        setIsToggled(true);
        setFeaturedKey("following");
      }
    } else {
      toast.error("Please Login First");
    }
  };

  const { showMenu, setShowMenu } = useContext(headerMenu);

  return (
    <>
      <div className={showMenu ? "overlay-main" : "d-none"}></div>

      <div className="container-fluid d-flex" id="small-topbar-filter">
        <button className="toogle_follow" onClick={handleToggle}>
          {isToggled ? (
            // Second icon (toggled state)
            <svg width="35" height="35" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="14.5" cy="14.5" r="14.5" fill="#ECECEC" />
              <path d="M22.6879 10.3899C22.6193 10.3192 22.5374 10.2627 22.447 10.2237C22.3565 10.1846 22.2593 10.1638 22.1608 10.1623C22.0623 10.1609 21.9645 10.1789 21.8729 10.2152C21.7813 10.2516 21.6979 10.3056 21.6272 10.3742L19.5704 12.371L19.0376 11.3779C18.9921 11.2893 18.9294 11.2107 18.8531 11.1466C18.7769 11.0826 18.6886 11.0343 18.5935 11.0048C18.4984 10.9752 18.3984 10.9648 18.2993 10.9743C18.2001 10.9838 18.1039 11.0129 18.0161 11.06C17.9284 11.107 17.8509 11.1711 17.7881 11.2485C17.7254 11.3258 17.6787 11.4149 17.6508 11.5105C17.6228 11.6061 17.6142 11.7063 17.6254 11.8052C17.6366 11.9042 17.6674 11.9999 17.716 12.0868L18.7188 13.9562C18.8134 14.1332 18.974 14.2631 19.1628 14.3201L19.2645 14.3429C19.3792 14.3607 19.4964 14.3517 19.607 14.3165C19.7176 14.2812 19.8186 14.2208 19.9019 14.14L22.6718 11.4505C22.7425 11.3819 22.799 11.3001 22.8381 11.2096C22.8772 11.1192 22.898 11.022 22.8995 10.9235C22.901 10.825 22.8831 10.7272 22.8468 10.6357C22.8105 10.5441 22.7565 10.4606 22.6879 10.3899Z" fill="#272D3B" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8889 10.1111C10.8889 9.28599 11.2167 8.49467 11.8001 7.91122C12.3836 7.32778 13.1749 7 14 7C14.8251 7 15.6164 7.32778 16.1999 7.91122C16.7833 8.49467 17.1111 9.28599 17.1111 10.1111C17.1111 10.9362 16.7833 11.7276 16.1999 12.311C15.6164 12.8944 14.8251 13.2222 14 13.2222C13.1749 13.2222 12.3836 12.8944 11.8001 12.311C11.2167 11.7276 10.8889 10.9362 10.8889 10.1111ZM10.8889 14.7778C9.85749 14.7778 8.86834 15.1875 8.13903 15.9168C7.40972 16.6461 7 17.6353 7 18.6667C7 19.2855 7.24583 19.879 7.68342 20.3166C8.121 20.7542 8.7145 21 9.33333 21H18.6667C19.2855 21 19.879 20.7542 20.3166 20.3166C20.7542 19.879 21 19.2855 21 18.6667C21 17.6353 20.5903 16.6461 19.861 15.9168C19.1317 15.1875 18.1425 14.7778 17.1111 14.7778H10.8889Z" fill="#272D3B" />
            </svg>

          ) : (
            // First icon (default state)
            <svg width="35" height="35" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="14.5" cy="14.5" r="14.5" fill="#ECECEC" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8889 10.1111C11.8889 9.28599 12.2167 8.49467 12.8001 7.91122C13.3836 7.32778 14.1749 7 15 7C15.8251 7 16.6164 7.32778 17.1999 7.91122C17.7833 8.49467 18.1111 9.28599 18.1111 10.1111C18.1111 10.9362 17.7833 11.7276 17.1999 12.311C16.6164 12.8944 15.8251 13.2222 15 13.2222C14.1749 13.2222 13.3836 12.8944 12.8001 12.311C12.2167 11.7276 11.8889 10.9362 11.8889 10.1111ZM11.8889 14.7778C10.8575 14.7778 9.86834 15.1875 9.13903 15.9168C8.40972 16.6461 8 17.6353 8 18.6667C8 19.2855 8.24583 19.879 8.68342 20.3166C9.121 20.7542 9.7145 21 10.3333 21H19.6667C20.2855 21 20.879 20.7542 21.3166 20.3166C21.7542 19.879 22 19.2855 22 18.6667C22 17.6353 21.5903 16.6461 20.861 15.9168C20.1317 15.1875 19.1425 14.7778 18.1111 14.7778H11.8889Z" fill="#272D3B" />
            </svg>

          )}
        </button>
        <div className="slider-wrap">
          <Slider {...settings}>
            <div className={`link-btn ${tagsKey === "" ? "active-link-btn" : ""}`} onClick={clearTagsFilter} >
              <a>All</a>
            </div>
            {loadingTags ? (
              <div>Loading Tags.....</div>
            ) : featureTags.length > 0 ? (
              featureTags.map((tag, index) => (
                <div className={`link-btn ${tagsKey === tag.tag ? "active-link-btn" : ""}`} key={index} onClick={() => handleTagClick(tag.tag)}>
                  <a>{tag.tag}</a>
                </div>
              ))
            ) : (
              <div>--- No Tags to Show ---</div>
            )}
          </Slider>
        </div>
      </div>
      <div className="container-fluid" id="s-home">
        <div className="row">
          {/* <--------------------------------- Post Start --------------------------------- >*/}
          {loadingPost ? (
            <div id="small-post-loader">
              <div className="wrap-div col-12">
                <div className="row">
                  <div className="col-12 profile-wrap">
                    <div className="profile-pic"></div>
                    <div className="username-div"></div>
                  </div>
                  <div className="col-12">
                    <div className="post-div"></div>
                  </div>
                  <div className="col-12">
                    <div className="comments-div"></div>
                    <div className="comments-div"></div>
                  </div>
                </div>
              </div>
            </div>
          ) : allPost.length > 0 ? (
            allPost.map((post, index) => {
              if (post.status !== "active" || !post.user) {
                return null;
              }
              return (
                <>
                  <div className="post" key={index + 1}>
                    <div className="col-1">
                      <div className="profile-img">
                        <NavLink to={`/${post.user.username}`}>
                          <img
                            src={`${BASE_URL}/uploads/admins-users-img/${post.user.user_img}`}
                            alt="profile"
                          />
                        </NavLink>
                        {followings.length > 0 ? "" : ""}
                      </div>
                    </div>
                    <div
                      className="col-11"
                      style={{ padding: "0px 0px 0px 20px" }}
                    >
                      <div className="post-header">
                        <div className="post-info">
                          <div className="user-name">
                            {post.user.fname + " " + post.user.lname}{" "}
                            <span className="user_name_span">
                              @{post.user.username}
                            </span>{" "}
                            <span>
                              <TimeAgoConverter dateTime={post.post_date} />
                            </span>
                          </div>
                          <div className="options">
                            <ReportComponent post_id={post._id} post_username={post.user.username} isValid={isValid}/>
                          </div>
                        </div>
                        <div className="post-title">
                          {" "}
                          {post.title.split(" ").slice(0, 30).join(" ") +
                            (post.title.split(" ").length > 30 ? "..." : "")}
                        </div>
                      </div>
                      <div className="post-img">
                        <img
                          src={`${BASE_URL}/uploads/posts-img/${post.post_img}`}
                          alt={post.post_img}
                          onClick={() =>
                            postClick(post._id, post.user.username)
                          }
                        />
                      </div>
                      <div className="post-options">
                        <div className="left-options">
                          <span onClick={() => handleLike(post._id, post.user.username)}>
                            {postLiked.length > 0 ? (
                              postLiked.some(
                                (liked) => liked.post_id === post._id
                              ) ? (
                                <svg width="19" height="18" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M12.5954 12.218C10.6967 14.0441 8.75596 15.228 8.5 15.3796C8.24405 15.228 6.30328 14.0441 4.40463 12.218C2.41914 10.3082 0.612139 7.82248 0.611987 5.16822C0.613315 3.94274 1.05766 2.77807 1.83224 1.92736C2.60505 1.07858 3.63989 0.613417 4.70574 0.611987C6.06999 0.612105 7.25321 1.25192 7.99479 2.33668L8.5 3.0757L9.00521 2.33668C9.74679 1.25192 10.93 0.612105 12.2943 0.611987C13.3601 0.613418 14.395 1.07858 15.1678 1.92736C15.9423 2.77799 16.3866 3.94253 16.388 5.16789C16.388 7.82227 14.5809 10.3081 12.5954 12.218Z" stroke="#0000" stroke-width="1.22397" fill="#FF0000">
                                  </path>
                                </svg>
                              ) : (
                                <svg
                                  width="19"
                                  height="18"
                                  viewBox="0 0 19 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9.4275 16.994L9.42491 16.9955L9.42231 16.994C9.2641 16.9026 7.10673 15.6202 4.99749 13.6051C2.86444 11.5672 0.915366 8.9079 0.91521 6.06297C0.916639 4.74837 1.39645 3.49761 2.23498 2.58285C3.07186 1.66989 4.19455 1.1676 5.3531 1.16606C6.83377 1.16618 8.11742 1.85638 8.92078 3.02362L9.42491 3.75607L9.92903 3.02362C10.7324 1.85638 12.016 1.16618 13.4967 1.16606C14.6553 1.1676 15.778 1.66989 16.6148 2.58285C17.4533 3.49753 17.9331 4.74815 17.9346 6.06263C17.9346 8.90768 15.9855 11.5672 13.8523 13.6051C11.7431 15.6202 9.58571 16.9026 9.4275 16.994ZM9.73358 17.524C9.6387 17.5796 9.53264 17.6088 9.42491 17.6088C9.31717 17.6088 9.21111 17.5796 9.11623 17.524H9.73358Z"
                                    stroke="#272D3B"
                                    stroke-width="1.22397"
                                  />
                                </svg>
                              )
                            ) : (
                              <svg
                                width="19"
                                height="18"
                                viewBox="0 0 19 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.4275 16.994L9.42491 16.9955L9.42231 16.994C9.2641 16.9026 7.10673 15.6202 4.99749 13.6051C2.86444 11.5672 0.915366 8.9079 0.91521 6.06297C0.916639 4.74837 1.39645 3.49761 2.23498 2.58285C3.07186 1.66989 4.19455 1.1676 5.3531 1.16606C6.83377 1.16618 8.11742 1.85638 8.92078 3.02362L9.42491 3.75607L9.92903 3.02362C10.7324 1.85638 12.016 1.16618 13.4967 1.16606C14.6553 1.1676 15.778 1.66989 16.6148 2.58285C17.4533 3.49753 17.9331 4.74815 17.9346 6.06263C17.9346 8.90768 15.9855 11.5672 13.8523 13.6051C11.7431 15.6202 9.58571 16.9026 9.4275 16.994ZM9.73358 17.524C9.6387 17.5796 9.53264 17.6088 9.42491 17.6088C9.31717 17.6088 9.21111 17.5796 9.11623 17.524H9.73358Z"
                                  stroke="#272D3B"
                                  stroke-width="1.22397"
                                />
                              </svg>
                            )}
                          </span>

                          <CommentBox post_id={post._id} post_username={post.user.username} isValid={isValid}/>

                          <span onClick={()=>handlePostModal(post._id)}>
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.5564 6.44358L7.2226 8.77712M14.448 1.02276C14.5219 0.997294 14.6014 0.993102 14.6774 1.01067C14.7535 1.02823 14.8231 1.06685 14.8783 1.1221C14.9335 1.17736 14.972 1.24701 14.9895 1.32312C15.0069 1.39923 15.0026 1.47871 14.977 1.55248L10.3686 14.7214C10.341 14.8001 10.2904 14.8688 10.2233 14.9184C10.1563 14.968 10.0758 14.9963 9.9925 14.9997C9.90915 15.003 9.82671 14.9813 9.75588 14.9372C9.68506 14.8931 9.62911 14.8288 9.59529 14.7525L7.09113 9.11937C7.04961 9.02527 6.97442 8.95009 6.88031 8.90857L1.24652 6.40391C1.17051 6.36998 1.10642 6.31403 1.06254 6.24331C1.01865 6.17259 0.996987 6.09033 1.00034 6.00716C1.00369 5.924 1.0319 5.84375 1.08133 5.77679C1.13076 5.70982 1.19914 5.65922 1.27763 5.63151L14.448 1.02276Z"
                                stroke="black"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                          {/* <span><i class="fa-regular fa-share-from-square" onClick={() => handlePostModal(post._id)}></i></span> */}
                          {/* <span><i class="fa-regular fa-paper-plane" onClick={() => handleOpenChat(post.user.username)}></i></span> */}
                        </div>
                        <div className="right-options">
                          <span
                            onClick={() =>
                              handleCollection(
                                post._id,
                                post.post_img,
                                post.title,
                                post.user.username
                              )
                            }
                          >
                            {collections.length > 0 ? (
                              collections.some(
                                (coll) => coll.post_id === post._id
                              ) ? (
                                <svg
                                  width="12"
                                  height="16"
                                  viewBox="0 0 12 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0 15.25V1.69444C0 1.22847 0.166055 0.829713 0.498167 0.498167C0.830278 0.16662 1.22904 0.000564815 1.69444 0H10.1667C10.6326 0 11.0317 0.166056 11.3638 0.498167C11.6959 0.830278 11.8617 1.22904 11.8611 1.69444V15.25L5.93056 12.7083L0 15.25Z"
                                    fill="black"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  width="12"
                                  height="16"
                                  viewBox="0 0 12 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0 15.5V1.616C0 1.15533 0.154333 0.771 0.463 0.463C0.771667 0.155 1.156 0.000666667 1.616 0H10.385C10.845 0 11.2293 0.154333 11.538 0.463C11.8467 0.771667 12.0007 1.156 12 1.616V15.5L6 12.923L0 15.5ZM1 13.95L6 11.8L11 13.95V1.616C11 1.462 10.936 1.32067 10.808 1.192C10.68 1.06333 10.5387 0.999333 10.384 1H1.616C1.462 1 1.32067 1.064 1.192 1.192C1.06333 1.32 0.999333 1.46133 1 1.616V13.95Z"
                                    fill="black"
                                  />
                                </svg>
                              )
                            ) : (
                              <svg
                                width="12"
                                height="16"
                                viewBox="0 0 12 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0 15.5V1.616C0 1.15533 0.154333 0.771 0.463 0.463C0.771667 0.155 1.156 0.000666667 1.616 0H10.385C10.845 0 11.2293 0.154333 11.538 0.463C11.8467 0.771667 12.0007 1.156 12 1.616V15.5L6 12.923L0 15.5ZM1 13.95L6 11.8L11 13.95V1.616C11 1.462 10.936 1.32067 10.808 1.192C10.68 1.06333 10.5387 0.999333 10.384 1H1.616C1.462 1 1.32067 1.064 1.192 1.192C1.06333 1.32 0.999333 1.46133 1 1.616V13.95Z"
                                  fill="black"
                                />
                              </svg>
                            )}
                          </span>
                        </div>
                      </div>
                      {/* {commBox.post_id === post._id && commBox.show === true ? (
                        <>
                          <div className="comment-box">
                            <textarea
                              rows="1"
                              onChange={(e) => setComm(e.target.value)}
                            ></textarea>
                            <button
                              type="button"
                              onClick={() =>
                                postComment(post._id, post.user.username)
                              }
                            >
                              Post
                            </button>
                          </div>
                        </>
                      ) : (
                        ""
                      )} */}

                      <div className="post-footer">
                        <span>{post.likes} likes</span>
                        {" . "}
                        <span>{post.comments} comments</span>
                      </div>
                    </div>
                  </div>
                  {/* <div className="post-separate"></div> */}
                  {/* <div
                    style={{ margin: "0" }}
                    className="post-separate-line"
                  ></div>
                  <div className="post-separate"></div> */}
                </>
              );
            })
          ) : (
            <div>No Posts Uploaded</div>
          )}

          {/* <--------------------------------- Post End --------------------------------- >*/}
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={1000} />
      <SharePostModal
        show={modalShow}
        postLink={postLink}
        onHide={() => setModalShow(false)}
        post_id={postShareId}
      />
    </>
  );
};

export default ForYou;
