import React, { useState, useEffect } from "react";
import { getUserPosts } from "../../../../services/Apis";
import { NavLink } from "react-router-dom";
import { BASE_URL } from "../../../../services/helper";

const ProfileWorks = ({ username }) => {
  const [userData, setUserData] = useState([]);

  const [loadingPost, setLoadingPost] = useState(true);
  const userPostsGet = async (username) => {
    try {
      const res = await getUserPosts(username);
      if (res.status === 200) {
        setUserData(res.data.userPosts);
      } else {
        console.log("Error in Fetching User Details.");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setTimeout(() => {
        setLoadingPost(false);
      }, 200);
    }
  };

  useEffect(() => {
    userPostsGet(username);
  }, [username]);

  return (
    <>
      <div className="col-12">
        <div id="profile-works">
          <div className="row d-flex justify-content-center">
            {loadingPost ? (
              <div>Loading Works ...</div>
            ) : userData.length > 0 ? (
              userData.map((element, index) => {
                return (
                  <>
                    <div
                      className="col-lg-3 col-sm-6 content"
                      style={{ padding: "0", marginBottom: "9px" }}
                    >
                      {/* <div class=""> */}
                      <NavLink
                        to={`/view-post/${element.username}/${element._id}`}
                      >
                        <div class="content-overlay"></div>
                        <img
                          class="content-image"
                          src={`${BASE_URL}/uploads/posts-img/${element.post_img}`}
                          alt=""
                        />
                        <div class="content-details fadeIn-bottom">
                          <div>
                          <h3 class="content-title">
                          {element.title.length > 29 ? element.title.substring(0, 29) + "..." : element.title}

                          </h3>
                          <p class="content-text">
                         
                            <span className="img-icon" style={{paddingLeft:"0px"}}>
                            <svg
                              width="19"
                              height="20"
                              viewBox="0 0 19 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.54239 17.6182L9.54239 17.6182L9.53947 17.6198C9.52583 17.6276 9.51236 17.6307 9.5 17.6307C9.48764 17.6307 9.47417 17.6276 9.46053 17.6198L9.45761 17.6182C9.31071 17.5363 7.33979 16.4057 5.41272 14.6292C3.46399 12.8328 1.67838 10.484 1.67826 7.96902C1.67956 6.80701 2.1189 5.69771 2.89258 4.88331C3.66544 4.06977 4.70777 3.61712 5.78935 3.61576C7.16815 3.61584 8.35899 4.23687 9.10047 5.27639L9.5 5.83651L9.89953 5.27639C10.641 4.23687 11.8319 3.61584 13.2107 3.61576C14.2922 3.61712 15.3346 4.06977 16.1074 4.88331C16.8812 5.69777 17.3205 6.80717 17.3217 7.96928C17.3215 10.4842 15.5359 12.8328 13.5873 14.6292C11.6602 16.4057 9.68929 17.5363 9.54239 17.6182Z"
                                stroke="#ffff"
                                strokeWidth="0.98151"
                              />
                            </svg>
                              <span className="count">250</span>
                            </span>
                            <span className="img-icon">
                            <svg
                         width="18"
                         height="17"
                         viewBox="0 0 18 17"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                       >
                         <path
                           d="M15.9374 8.49999C15.9385 9.36024 15.7599 10.2122 15.4118 11.0072C15.0637 11.8021 14.5528 12.5244 13.9086 13.1325C13.2641 13.7414 12.4988 14.2243 11.6565 14.5537C10.8142 14.883 9.91149 15.0524 8.99987 15.0521C8.01871 15.0541 7.04838 14.8585 6.15362 14.4783L3.25337 14.8835C3.10937 14.9057 2.9618 14.8944 2.82349 14.8505C2.68518 14.8066 2.56031 14.7315 2.45972 14.6317C2.35913 14.5318 2.28586 14.4103 2.24629 14.2777C2.20671 14.1451 2.20202 14.0053 2.23262 13.8706L2.63537 11.0918C2.25226 10.2759 2.05695 9.39249 2.06237 8.49999C2.0612 7.63974 2.2398 6.78773 2.58794 5.9928C2.93607 5.19786 3.44689 4.47563 4.09112 3.86749C4.73566 3.25862 5.50094 2.77569 6.34322 2.44632C7.18549 2.11694 8.08824 1.94758 8.99987 1.94791C10.8408 1.94802 12.6064 2.63846 13.9086 3.86749C14.5525 4.47586 15.0631 5.19814 15.4112 5.99302C15.7593 6.7879 15.9381 7.6398 15.9374 8.49999Z"
                           stroke="#ffff"
                           strokeWidth="0.891534"
                           strokeLinecap="round"
                           strokeLinejoin="round"
                         />
                       </svg>
                              <span className="count">250</span>
                            </span>
                            <span className="img-icon">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.7787 7.22123L8.11154 9.88813M16.3692 1.02602C16.4536 0.996907 16.5444 0.992117 16.6314 1.01219C16.7183 1.03227 16.7979 1.0764 16.8609 1.13955C16.924 1.20269 16.968 1.2823 16.988 1.36928C17.0079 1.45626 17.003 1.54709 16.9738 1.6314L11.7069 16.6816C11.6754 16.7716 11.6176 16.85 11.5409 16.9067C11.4643 16.9634 11.3724 16.9958 11.2771 16.9996C11.1819 17.0034 11.0877 16.9786 11.0067 16.9282C10.9258 16.8778 10.8618 16.8043 10.8232 16.7172L7.96129 10.2793C7.91384 10.1717 7.82791 10.0858 7.72035 10.0384L1.28173 7.17589C1.19487 7.13712 1.12163 7.07318 1.07147 6.99235C1.02132 6.91153 0.996557 6.81752 1.00039 6.72247C1.00421 6.62743 1.03646 6.53572 1.09295 6.45919C1.14944 6.38265 1.22759 6.32482 1.3173 6.29315L16.3692 1.02602Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                              <span className="count">250</span>
                            </span>
                          </p>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                    {/* </div> */}
                  </>
                );
              })
            ) : (
              <div className="col-12 d-flex justify-content-center">
                <div>
                  <img src="assets/images/no-data.png" width="300px" />
                  <h4 className="text-center mt-2 text">No Works Uploaded</h4>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileWorks;
