import { useState } from 'react';
import ForYou from './ForYou';
import './SHome.css' ;
import SmallFooter from '../../../components/footer/SmallFooter';
import SmallHeader from '../../../components/header/small-screen/SmallHeader';

const SHomeContent = () => {
  return (
    <>
    <SmallHeader />

    
    <div className="container-fluid" id="s-topbar">
      <div className="row">
        <div className="col-12">
            {/* <ul>
              <li onClick={()=>switchTabs("for_you")} className={tabs === "for_you"?"active":""}>For You</li>
              <li onClick={()=>switchTabs("following")} className={tabs === "following"?"active":""}>Following</li>
            </ul> */}
        </div>
      </div>
    </div>
    <button></button>
    <ForYou />
    <SmallFooter />
    </>
  )
}

export default SHomeContent