import React, { useEffect, useState } from 'react';

const TimeAgoConverter = ({ dateTime }) => {
  const [timeAgo, setTimeAgo] = useState('');

  useEffect(() => {
    const convertDateToTimeAgo = () => {
      const currentDate = new Date();
      const givenDate = new Date(dateTime);
      var timeDifference;
      if (currentDate > givenDate) {
        timeDifference = (currentDate - givenDate) / 1000;
      }
      else{
        timeDifference = (givenDate - currentDate) / 1000;
      }

      const seconds = Math.floor(timeDifference);
      const minutes = Math.floor(timeDifference / 60);
      const hours = Math.floor(timeDifference / 3600);
      const days = Math.floor(timeDifference / (3600 * 24));
      const weeks = Math.floor(timeDifference / (3600 * 24 * 7));
      const months = Math.floor((currentDate.getFullYear() - givenDate.getFullYear()) * 12 + currentDate.getMonth() - givenDate.getMonth());
      const years = Math.floor(months / 12);

      if (seconds < 60) {
        setTimeAgo(`${seconds}s `);
      } else if (minutes < 60) {
        setTimeAgo(`${minutes}min `);
      } else if (hours < 24) {
        setTimeAgo(`${hours}h `);
      } else if (days < 7) {
        setTimeAgo(`${days}d `);
      } else if (weeks < 4) {
        setTimeAgo(`${weeks}w `);
      } else if (months < 12) {
        setTimeAgo(`${months}mon `);
      } else {
        setTimeAgo(`${years}years `);
      }
    };

    convertDateToTimeAgo();
  }, [dateTime]);

  // return <span>. {timeAgo}</span>;
  return <span>{timeAgo} ago</span>;
};

export default TimeAgoConverter;
