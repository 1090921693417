import React, { useEffect, useState } from "react";
import "./SmallHeader.css";
import light_logo from "../../../assets/images/yoeavnu_logo.png";
import { Link } from "react-router-dom";
import { getSingleUserUnseenNotifs, getUnseenMsgs,} from "../../../../services/Apis";
import { isTokenValid } from "../../functions/OtherFunctions";
import RightSideBar from "./RightSideBar";

const SmallHeader = () => {
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const valid = await isTokenValid();
        setIsValid(valid);
      } catch (error) {
        console.error("Error checking token validity:", error);
      }
    };
    checkTokenValidity();
  }, []);

  const username = sessionStorage.getItem("user_username");

  const [msgNotif, setMsgNotif] = useState([]);
  const fetchMsgNotif = async () => {
    try {
      const res = await getUnseenMsgs(username);
      if (res.status === 200) {
        // console.log(res.data);
        setMsgNotif(res.data);
      } else {
        console.log("Error in Fetching User Details.");
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const [notifs, setNotifs] = useState([]);
  const fetchAllNotifs = async () => {
    try {
      const res = await getSingleUserUnseenNotifs(username);
      if (res.status === 200) {
        setNotifs(res.data);
      } else {
        console.log("Error in Fetching User Details.");
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchMsgNotif();
      await fetchAllNotifs();
    };
    fetchData(); // Call initially

    const interval = setInterval(fetchData, 1000); // Call every second
    return () => clearInterval(interval); // Cleanup
  }, [username]);

  return (
    <>
      <div className="container-fluid" id="small-header">
        <div className="row">
          <div className="col-7">
            <div className="logo-wrapper">
              <Link to="/">
                  <img src={light_logo} alt="Company Logo" />
              </Link>
            </div>
          </div>
          <div className="col-5">
            <div className="icon-wrapper">
              {isValid ? (
                <>  <Link to="/search" className="nav-icon">
                  <svg width="5" height="5" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.60213 6.73869H7.14738L6.98621 6.58327C7.56987 5.90636 7.89064 5.04213 7.88995 4.14834C7.88995 3.40832 7.67051 2.68492 7.25937 2.06962C6.84824 1.45432 6.26388 0.974746 5.58019 0.691553C4.89651 0.408359 4.14419 0.334263 3.41839 0.478634C2.69259 0.623004 2.0259 0.979358 1.50263 1.50263C0.979358 2.0259 0.623004 2.69259 0.478634 3.41839C0.334263 4.14419 0.408359 4.89651 0.691553 5.58019C0.974746 6.26388 1.45432 6.84824 2.06962 7.25937C2.68492 7.67051 3.40832 7.88995 4.14834 7.88995C5.07511 7.88995 5.92705 7.55033 6.58327 6.98621L6.73869 7.14738V7.60213L9.61684 10.4745L10.4745 9.61684L7.60213 6.73869ZM4.14834 6.73869C2.71502 6.73869 1.558 5.58167 1.558 4.14834C1.558 2.71502 2.71502 1.558 4.14834 1.558C5.58167 1.558 6.73869 2.71502 6.73869 4.14834C6.73869 5.58167 5.58167 6.73869 4.14834 6.73869Z" fill="black" />
                  </svg>
                </Link>
                  <Link to="/notification" className="nav-icon">
                    <svg width="26" height="29" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.5 22V23.5C8.5 24.6935 8.97411 25.8381 9.81802 26.682C10.6619 27.5259 11.8065 28 13 28C14.1935 28 15.3381 27.5259 16.182 26.682C17.0259 25.8381 17.5 24.6935 17.5 23.5V22M10 4C10 3.20435 10.3161 2.44129 10.8787 1.87868C11.4413 1.31607 12.2044 1 13 1C13.7956 1 14.5587 1.31607 15.1213 1.87868C15.6839 2.44129 16 3.20435 16 4C17.7226 4.81454 19.1911 6.08249 20.2481 7.66795C21.305 9.25341 21.9107 11.0966 22 13V17.5C22.1129 18.4326 22.4432 19.3256 22.9642 20.1072C23.4853 20.8888 24.1826 21.5371 25 22H1C1.81741 21.5371 2.51471 20.8888 3.03578 20.1072C3.55685 19.3256 3.88712 18.4326 4 17.5V13C4.08934 11.0966 4.69495 9.25341 5.75192 7.66795C6.8089 6.08249 8.27739 4.81454 10 4Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    {notifs.length > 0 && (
                      <span className="msg-count-symbol bg-danger"></span>
                    )}
                  </Link>
                  <Link to="/message" className="nav-icon">
                    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.25 16L9.74375 13.0062C10.54 12.0525 10.9375 11.575 11.4162 11.575C11.895 11.575 12.2937 12.0525 13.0887 13.0062L13.9112 13.9938C14.7062 14.9475 15.1037 15.425 15.5837 15.425C16.0625 15.425 16.46 14.9475 17.2562 13.9938L19.75 11" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M13.5 26C20.4037 26 26 20.4037 26 13.5C26 6.59625 20.4037 1 13.5 1C6.59625 1 1 6.59625 1 13.5C1 15.2237 1.35 16.8662 1.98 18.36C2.32875 19.185 2.5025 19.5975 2.525 19.91C2.54625 20.2225 2.45375 20.565 2.27 21.2512L1 26L5.74875 24.73C6.435 24.5462 6.7775 24.455 7.09 24.475C7.4025 24.4975 7.815 24.6712 8.64 25.02C10.1783 25.6679 11.8308 26.0011 13.5 26Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </Link>
                </>
              ) : (
                ""
              )}
              {isValid ? (<RightSideBar />) : ('')}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmallHeader;
