import React, { useState } from 'react';
import ContactUsImage from "./contactUs.png";
import axios from 'axios';
import "./ContactUs.css"
import { contactUsApi } from '../../../services/Apis';
import { ToastContainer, toast } from 'react-toastify';
import Logo from '../../../../public/assets/images/logos.png'

const ContactUs = () => {
    const [name, setName] = useState('');
    const [contact, setContact] = useState();
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleContactUsSubmit = async (e) => {
        e.preventDefault();
        const config = { "Content-Type": "application/json" };
        const sendData = { name, contact, email, message };
        const res = await contactUsApi(sendData, config);
        if (res.status === 200) {
            toast.success("Message send Successfully");
            console.log(res);
        } else {
            if (res.response.data.message) {
                // alert(res.response.data.message);
                console.log(res.response.data.message);
                toast.error(res.response.data.message);
            } else {
                // alert("Some Unknown Error Occurred");
                toast.error("Some Unknown Error Occurred");
            }
        }
    }

    return (
        <div className='whole-contact-us-container'>
            <div className="contact-container">
                <div className="contact-form">
                <img src={Logo} className='' />

                    <h1>Contact Us</h1>
                    <form onSubmit={handleContactUsSubmit} >
                        <label htmlFor="name">Your Name</label>
                        <input type="text" id="name" name="name" required onChange={(e) => setName(e.target.value)} />

                        <label htmlFor="contactNumber">Contact Number</label>
                        <input type="tel" id="contactNumber" name="contactNumber"  required onChange={(e) => setContact(e.target.value)} />

                        <label htmlFor="email">E-mail</label>
                        <input type="email" id="email" name="email"  required onChange={(e) => setEmail(e.target.value)} />

                        <label htmlFor="message">Your Message</label>
                        <textarea id="message" name="message"  required onChange={(e) => setMessage(e.target.value)} ></textarea>

                        <button type="submit">Submit</button>
                    </form>
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={1000} />
        </div>
    );
};

export default ContactUs;